import Modal from '../Modal';
import Button from '../Button';
import FrameSizing, { FrameSizingProps } from '../FrameSizing/FrameSizing';
import { useTranslation } from '@utils/index';

const getTranslatedTexts = translator => {
	return {
		fitGuide: translator('fit-guide'),
	};
};

const FrameSizingButton = ({ frameSizings, primaryImage, name }: FrameSizingProps) => {
	const { translator } = useTranslation();
	const translations = getTranslatedTexts(translator);

	return (
		<Modal>
			<Modal.Trigger asChild>
				<Button label={translations.fitGuide} linkStyle size='small' data-open-fit-guide={name} />
			</Modal.Trigger>
			<Modal.Content closeButtonSticky center removePadding>
				<FrameSizing frameSizings={frameSizings} primaryImage={primaryImage} name={name} />
			</Modal.Content>
		</Modal>
	);
};

export default FrameSizingButton;
