import cn from 'classnames';
import { Flex, RadioGroup } from '@components/common';
import { Checkmark, Close, PairMembership } from '@components/icons';
import { Caption, Heading } from '@components/typography';
import { useMembership, useTranslation } from '@utils/index';
import styles from './MembershipOptions.module.scss';

type MembershipOptionsProps = {
	subtotal?: number;
	subtotalWithDiscount?: number;
};
type OptionProps = {
	type: string;
	title: string;
	isActive: boolean;
	onClick: (value: string) => void;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	benefits: any[];
	savings: string;
};
const Option = ({ type, title, isActive, onClick, benefits, savings }: OptionProps) => (
	<RadioGroup.Option
		small
		className={cn(styles['membershipOption--copy'], {
			[styles['membershipOption--copy--active']]: isActive,
		})}
		option={type}
		callback={() => onClick(type)}
		oneClick
	>
		<Caption small className={styles.title}>
			{title}
		</Caption>
		{benefits.map((benefit, index) => {
			if (typeof benefit === 'boolean') {
				return benefit ? (
					<Checkmark key={index} height={18} color='black' />
				) : (
					<Close
						noHover
						key={index}
						label='close'
						height={16}
						width={16}
						color='black'
						style={{ width: '1.8rem', height: '1.8rem' }}
					/>
				);
			}
			return (
				<Caption small key={index}>
					{benefit}
				</Caption>
			);
		})}
		<Caption small className={styles.save}>
			{savings}
		</Caption>
	</RadioGroup.Option>
);

const MembershipOptions = ({ subtotal = 0, subtotalWithDiscount = 0 }: MembershipOptionsProps) => {
	const { translator: t } = useTranslation();
	const { formattedSubtotal, formattedSubtotalWithDiscount, option, handleOnClick } = useMembership(
		subtotalWithDiscount,
		subtotal
	);
	const dataTags = option === 'vip' ? { 'data-cart-add-pair-plus': true } : { 'data-cart-remove-pair-plus': true };
	return (
		<Flex column className={styles.container} {...dataTags}>
			<Flex>
				<Flex column className={styles.copy}>
					<PairMembership />
					<Caption small style={{ fontWeight: 'bold' }}>
						{t('member-discount')}
					</Caption>
					<Caption small>{t('monthly-store-credit')}</Caption>
					<Caption small>{t('member-only-tops')}</Caption>
					<Caption small>{t('free-shipping')}</Caption>
					<Heading tag='h5'>{t('you-pay')}</Heading>
				</Flex>
				<RadioGroup className={styles.membershipOption} value={option}>
					<Option
						type='vip'
						title={t('vip')}
						isActive={option === 'vip'}
						onClick={handleOnClick}
						benefits={['15%', '$25', true, true]}
						savings={formattedSubtotalWithDiscount}
					/>
					<Option
						type='oneTime'
						title={t('one-time')}
						isActive={option === 'oneTime'}
						onClick={handleOnClick}
						benefits={[false, '-', false, false]}
						savings={formattedSubtotal}
					/>
				</RadioGroup>
			</Flex>
			{option === 'vip' && <Caption small>{t('membership-options-disclaimer')}</Caption>}
		</Flex>
	);
};

export default MembershipOptions;
