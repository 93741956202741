import React, { useEffect, useState } from 'react';
import * as CheckboxPrimitive from '@radix-ui/react-checkbox';
import cn from 'classnames';
import { Checkmark } from '@components';
import { ComponentProps } from '@ts/index';
import Label from '../Label';
import Loading from '../Loading';
import Img from '../Img';
import styles from './Checkbox.module.scss';
import { useTranslation } from '@utils/index';

type CheckboxProps = ComponentProps<HTMLDivElement> &
	CheckboxPrimitive.CheckboxProps & {
		option: string;
		image?: string;
		handler: (option: string) => void;
		isFetching?: boolean;
		hoverable?: boolean;
		hoverColor?: 'blue' | 'gray';
		setChecked?: (option: boolean) => void;
	};

const getTranslatedTexts = translator => {
	return {
		blueSwatch: translator('alt-text-blue-swatch'),
	};
};

const Checkbox = ({
	className,
	option,
	image,
	handler,
	checked: initiallyChecked,
	children,
	dataTags = {},
	disabled,
	style,
	hoverable = true,
	hoverColor = 'blue',
	setChecked: setCheckedProp = null,
}: CheckboxProps) => {
	const [checked, setChecked] = useState(initiallyChecked);
	const [loading, setLoading] = useState(false);
	const { translator } = useTranslation();
	const translations = getTranslatedTexts(translator);

	const classes = cn(styles.container, className, {
		[styles.containerHoverable]: hoverable,
		[styles[`containerHoverable--${hoverColor}`]]: hoverable && hoverColor,
	});

	useEffect(() => {
		(!disabled || !hoverable) && setLoading(false);
	}, [disabled, hoverable]);

	useEffect(() => {
		setChecked(initiallyChecked);
	}, [initiallyChecked]);

	return (
		<div
			aria-disabled={disabled}
			{...(disabled && { style: { pointerEvents: 'none' } })}
			className={classes}
			onClick={async () => {
				if (disabled) {
					return;
				}

				if (setCheckedProp) {
					setCheckedProp(!checked);
				} else {
					setChecked(!checked);
				}
				setLoading(true);
				await handler(option);
			}}
			{...dataTags}
			style={style}
		>
			{loading && disabled ? (
				<Loading removeDefaultStyling className={styles.loading} small />
			) : (
				<CheckboxPrimitive.Root
					disabled={disabled}
					checked={checked}
					className={styles.checkbox}
					id={`cb-${option}`}
					data-text-animate
				>
					<CheckboxPrimitive.Indicator asChild forceMount>
						<Checkmark color='currentColor' />
					</CheckboxPrimitive.Indicator>
				</CheckboxPrimitive.Root>
			)}
			{children ? (
				children
			) : (
				<Label input={`cb-${option}`}>
					{image && <Img src='/swatch-blue-clear.svg' noSrcset alt={translations.blueSwatch} />}
					{option}
				</Label>
			)}
		</div>
	);
};

export default Checkbox;
