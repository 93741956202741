/* eslint-disable max-lines */
import { cloneElement, Dispatch, isValidElement, ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import cn from 'classnames';
import { Navigation, Pagination, Zoom } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { PaginationOptions } from 'swiper/types';
import { NormalizedProduct, NormalizedVariant } from '@ts/product';
import { getDefaultSwiper, LOCALE_CODES, MEDIUM_WIDTH, PRODUCT_TYPES } from '@constants';
import { Button, ConditionalLink, Flex, Img, Modal, SaveTag, VideoIcon } from '@components';
import { useFeatureInLocale, useIsMobile, useTranslation } from '@utils/hooks';
import { BaseFrameStateDispatch } from '@utils/hooks/useBaseFrame';
import styles from './RichMedia.module.scss';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/zoom';

type RichMediaProps = {
	containerType: 'vertical' | 'horizontal' | 'line-item' | 'build-flow' | 'large';
	href?: string;
	src: string;
	title: string;
	aspectRatio: string;
	variant?: NormalizedVariant;
	ImgClickHandler?: () => void;
	pageType?: string;
	isModalPresent?: boolean;
	hoverSrc?: string;
	className?: string;
	hoverable?: boolean;
	isMinicart?: boolean;
	forceDefault?: boolean;
	secondaryAction?: 'none' | 'favorite' | 'zoom' | 'vto' | 'tooltip' | 'custom';
	dispatch?: Dispatch<BaseFrameStateDispatch>;
	product?: NormalizedProduct;
	customHover?: ReactNode;
	hoverCallback?: (value: boolean) => void;
	bulletVariant?: 'default' | 'gray';
};

type imageProps = {
	src: string;
	style?: React.CSSProperties;
	alt?: string;
};

const getTranslatedTexts = translator => {
	return {
		imageNonHoveredTitle: (imageTitle: string) => translator('image-non-hovered', { imageTitle }),
		imageHoveredTitle: (imageTitle: string) => translator('image-hovered', { imageTitle }),
		virtualTryOn: translator('virtual-try-on'),
	};
};

const RichMedia = ({
	containerType,
	href,
	src,
	hoverSrc,
	aspectRatio,
	title,
	variant,
	ImgClickHandler,
	pageType,
	isModalPresent,
	className,
	isMinicart = false,
	forceDefault = true,
	secondaryAction,
	dispatch,
	product,
	customHover,
	hoverCallback,
	bulletVariant,
}: RichMediaProps) => {
	const [isHovered, setIsHovered] = useState(false);
	const isMobile = useIsMobile({ maxWidth: MEDIUM_WIDTH });
	const navigationPrevRef = useRef(null);
	const navigationNextRef = useRef(null);
	const [swiperKey, setSwiperKey] = useState(0);
	const isCartUsability = useFeatureInLocale('is-cart-usability', LOCALE_CODES.US);
	const { translator, locale } = useTranslation();
	const translations = getTranslatedTexts(translator);

	const DEFAULT_SWIPER = getDefaultSwiper(locale);
	const DEFAULT_SWIPER_PROPS = {
		...DEFAULT_SWIPER,
		pagination: {
			clickable: true,
			type: 'bullets',
			bulletElement: 'button',
			el: '.swiper-pagination',
			bulletClass: styles['swiper-pagination-bullet'],
			bulletActiveClass: styles['swiper-pagination-bullet-active'],
			lockClass: styles['swiper-pagination-lock'],
		} as PaginationOptions,
	};

	const navigationRefs = {
		prevEl: navigationPrevRef.current,
		nextEl: navigationNextRef.current,
	};

	useEffect(() => {
		if (hoverSrc) {
			const img = new Image();
			img.src = hoverSrc;
		}
	}, [hoverSrc]);

	useEffect(() => {
		const handleVisibilityChange = () => {
			if (document.visibilityState === 'visible') {
				setSwiperKey(prevKey => prevKey + 1);
			}
		};

		document.addEventListener('visibilitychange', handleVisibilityChange);

		return () => {
			document.removeEventListener('visibilitychange', handleVisibilityChange);
		};
	}, []);

	const handleMouseEnter = () => {
		if ((!hoverSrc && !customHover) || isMobile) return;
		setIsHovered(true);
		if (hoverCallback) hoverCallback(isHovered);
	};

	const handleMouseLeave = () => {
		if ((!hoverSrc && !customHover) || isMobile) return;
		setIsHovered(false);
		if (hoverCallback) hoverCallback(isHovered);
	};

	const isVerticalCard = containerType === 'vertical';

	const containerClasses = cn(styles.richMedia, className, {
		[styles.richMediaLineItem]: containerType === 'line-item',
		[styles.richMediaHorizontal]: containerType === 'horizontal',
		[styles.richMediaVertical]: isVerticalCard,
		[styles.richMediaBuildFlow]: containerType === 'build-flow',
		[styles.richMediaWithBigImage]: containerType === 'line-item' && isCartUsability && !forceDefault,
		[styles.richMediaInDrawer]: containerType === 'line-item' && isCartUsability && isMinicart,
		[styles.richMediaLarge]: containerType === 'large',
	});

	const imgClasses = cn(styles.richMediaImg, {
		[styles.richMediaImgTop]: variant?.product?.type?.includes(PRODUCT_TYPES.TOP_FRAME),
		[styles.richMediaImgAccessory]: variant?.product?.type?.includes(PRODUCT_TYPES.ACCESSORY) && pageType != 'search',
		[styles.richMediaImgSearchAccessory]: variant?.product?.type?.includes(PRODUCT_TYPES.ACCESSORY) && pageType === 'search',
		[styles.richMediaImgGiftCard]: variant?.product?.type?.includes(PRODUCT_TYPES.GIFT_CARD),
		[styles.richMediaImgLineItem]: containerType === 'line-item',
	});

	const image = ({ src, alt, style = {} }: imageProps) => (
		<Img
			className={imgClasses}
			onClick={ImgClickHandler}
			src={src}
			alt={alt}
			sizes='(min-width: 76.8em) 24em, 10em'
			aspectRatio={aspectRatio}
			needsPlaceholder
			style={style}
		/>
	);

	const imagesWrapper = !customHover ? (
		<div className={styles.imgWrapper} style={{ aspectRatio }}>
			{image({ src, alt: translations.imageNonHoveredTitle(title), style: { opacity: isHovered ? 0 : 1 } })}
			{hoverSrc &&
				image({
					src: hoverSrc,
					alt: translations.imageHoveredTitle(title),
					style: { opacity: isHovered ? 1 : 0, objectFit: 'cover' },
				})}
		</div>
	) : (
		<div className={styles.imgWrapper} style={{ aspectRatio }}>
			{image({ src, alt: `${title} - non-hovered`, style: { opacity: isHovered ? 0 : 1 } })}
			{customHover &&
				isValidElement(customHover) &&
				cloneElement(customHover, { ...customHover.props, style: { opacity: isHovered ? 1 : 0, objectFit: 'cover' } })}
		</div>
	);
	const ImgContainer = useCallback(
		({ children }) => (isModalPresent ? <Modal.Trigger asChild>{children}</Modal.Trigger> : children),
		[isModalPresent]
	);

	return (
		<>
			{dispatch && secondaryAction === 'vto' && (
				<ImgContainer>
					<Flex className={styles.vtoButtonContainer}>
						<Modal.Trigger asChild>
							<Button
								color='white'
								extraClasses={styles.vtoButton}
								data-image-vto-tryon={title}
								onClick={() => {
									dispatch({
										type: 'product',
										option: {
											product,
											variantIdentifier: variant?.option,
										},
									});
								}}
							>
								<VideoIcon width={16} height={16} />
								{translations.virtualTryOn}
							</Button>
						</Modal.Trigger>
					</Flex>
				</ImgContainer>
			)}
			<ConditionalLink
				href={href}
				title={`Go to ${title} product page`}
				className={containerClasses}
				style={{ aspectRatio }}
				data-image-link={title}
				condition={!!href}
				onMouseEnter={handleMouseEnter}
				onMouseLeave={handleMouseLeave}
				onClick={() => hoverCallback?.(true)}
			>
				<ImgContainer>
					{isMobile && (hoverSrc || customHover) ? (
						<Swiper
							key={swiperKey}
							autoplay={false}
							className={styles['swiper-container']}
							modules={[Navigation, Pagination, Zoom]}
							navigation={navigationRefs}
							{...DEFAULT_SWIPER_PROPS}
							pagination={{
								...DEFAULT_SWIPER_PROPS.pagination,
								bulletClass: cn(styles['swiper-pagination-bullet'], {
									[styles['swiper-pagination-bullet-gray']]: bulletVariant === 'gray',
								}),
							}}
							onBeforeSlideChangeStart={() => hoverCallback?.(true)}
						>
							<SwiperSlide className={styles['slide-container']}>{image({ src })}</SwiperSlide>
							<SwiperSlide>
								{customHover ? customHover : image({ src: hoverSrc, style: { objectFit: 'cover' } })}
							</SwiperSlide>
							<div
								className={cn('swiper-pagination', styles['swiper-pagination'], {
									[styles['swiper-pagination-gray']]: bulletVariant === 'gray',
								})}
							/>
						</Swiper>
					) : (
						imagesWrapper
					)}
				</ImgContainer>
				{variant?.metafields?.discountTag && isVerticalCard && (
					<div className={styles.saveTag}>
						<SaveTag label={'save-tag'} detail={variant.metafields.discountTag} />
					</div>
				)}
			</ConditionalLink>
		</>
	);
};

export default RichMedia;
