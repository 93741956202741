import Link from 'next/link';
import { useEffect, useState } from 'react';
import { HeartIcon, LoginPrompt, Modal, PopupMessage } from '@components';
import { useFavorites } from '@services/poms';
import { slideUp } from '@utils/motions';
import { getShopifyIdFromGID, useTranslation } from '@utils/index';
import styles from './FavoriteButton.module.scss';

const getTranslatedTexts = translator => {
	return {
		logInForFavorites: translator('log-in-for-favorites'),
		addedToWishlist: translator('added-to-wishlist'),
		favorites: translator('favorites-capitalized'),
	};
};

const FavoriteButton = ({ customer }) => {
	const customerId = getShopifyIdFromGID(customer?.id);
	const { data: favorites } = useFavorites(customerId);
	const [isOpen, setIsOpen] = useState(false);
	const [number, setNumber] = useState(null);
	const { translator } = useTranslation();
	const translations = getTranslatedTexts(translator);

	useEffect(() => {
		let id;
		if (!favorites) return;
		if (number && favorites.length > number) {
			setIsOpen(true);
			id = setTimeout(() => {
				setIsOpen(false);
			}, 3000);
		}
		setNumber(favorites.length);

		return () => clearTimeout(id);
	}, [favorites]);

	return (
		<>
			{!customer ? (
				<Modal>
					<Modal.Trigger
						data-prompt-favorite
						data-nav-icon='favorites'
						key='dialog-trigger'
						id={`dialog-trigger-${styles['FavoriteIconNav']}`}
						className={styles['nav-icon']}
					>
						<HeartIcon label={translations.logInForFavorites} pathColor='black' />
					</Modal.Trigger>
					<Modal.Content center small customAnimation={slideUp} key='dialog-content' style={{ overflowY: 'hidden' }}>
						<LoginPrompt />
					</Modal.Content>
				</Modal>
			) : (
				<PopupMessage open={isOpen} customer={customer} message={translations.addedToWishlist}>
					<Link
						href={'/account/favorites'}
						id={`link-${styles['FavoriteIconNav']}`}
						data-nav-icon='favorites'
						className={styles['nav-icon']}
					>
						<HeartIcon label={translations.favorites} active={isOpen} pathColor='black' />
					</Link>
				</PopupMessage>
			)}
		</>
	);
};

export default FavoriteButton;
