/* eslint-disable max-len */
/* eslint-disable prettier/prettier */
import { useCallback, useMemo } from 'react';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import cn from 'classnames';
import { Flex, Img, ProductImage, VideoPlayIcon } from '@components';
import { PRODUCT_TYPES } from '@constants';
import { ThumbnailsProps } from '@ts/index';
import styles from './Carousel.module.scss';
import { useTranslation } from '@utils/index';

const getTranslatedTexts = translator => {
  return {
	videoThumbnail5: (productType: string, productName: string) =>
		translator('video-thumbnail-5', { productType, productName }),
	videoThumbnail: (productType: string, productName: string, thumbNailIndex: number) =>
		translator('video-thumbnail', { productType, productName, thumbNailIndex }),
  };
};

const Thumbnails = ({
	hideThumbnail,
	secondary,
	selectedImgIndex,
	setSelectedImgIndex,
	isThumbsClicked,
	setIsThumbsClicked,
	images,
	type,
	withThreeFourthImage,
	video,
	copyTopFrameVariant,
	imageLoadingHandler,
	name,
}: ThumbnailsProps) => {
	let productType;
	const isImageGallery = useFeatureIsOn('is-image-gallery');
	const { translator } = useTranslation();
	const translations = getTranslatedTexts(translator);

	const handleThumbnailChange = useCallback(
		index => {
			setSelectedImgIndex(index);
			!isThumbsClicked && setIsThumbsClicked(!isThumbsClicked);
		},
		[isThumbsClicked, setIsThumbsClicked, setSelectedImgIndex]
	);

	const isBaseFrame = type.includes(PRODUCT_TYPES.BASE_FRAME);

	if (type.includes(PRODUCT_TYPES.TOP_FRAME)) {
		productType = 'Top Frame';
	} else if (isBaseFrame) {
		productType = 'Base Frame';
	} else if (type === PRODUCT_TYPES.GIFT_CARD) {
		productType = 'Gift Card';
	} else {
		productType = type;
	}

	const isVideoSelectedIndex = isBaseFrame ? selectedImgIndex === images.length : selectedImgIndex === copyTopFrameVariant.length;

	const videoThumbnail = useMemo(
		() =>
			video && (
				<Flex
					align='center'
					className={cn(styles['thumbnail-image'], {
						[styles['active-thumbnail']]: isVideoSelectedIndex,
						[styles['video-thumbnail']]: !!video?.sources?.length,
						[styles['inactive-thumbnail']]: !isVideoSelectedIndex && isImageGallery,
					})}
					onClick={() => handleThumbnailChange(isBaseFrame ? images.length : copyTopFrameVariant.length)}
					key={`thumbnail-${isBaseFrame ? images.length : copyTopFrameVariant.length}`}
					thumbnail-index={`${withThreeFourthImage ? 4 : 3}-3/4-image=${withThreeFourthImage}`}
					data-is-thumbnail-click
				>
					<div data-product-type={type}>
						<div className={styles['play-icon']}>
							<VideoPlayIcon />
						</div>
						<Img
							src={video?.previewImage?.url}
							alt={translations.videoThumbnail5(productType, name)}
							sizes='(max-width: 55em) 24w, (min-width: 551px) 64w, (min-width: 76.8em) 1000w'
							loading='eager'
						/>
					</div>
				</Flex>
			),
		[
			images.length,
			copyTopFrameVariant.length,
			handleThumbnailChange,
			selectedImgIndex,
			type,
			video,
			withThreeFourthImage,
			name,
			productType,
		]
	);

	const renderThumbnails = useCallback(() => {
		const thumbnailImgs =
			images.length > 0
				? images.map(({ url, altText }, index) => (
					<Flex
						align='center'
						className={cn(styles['thumbnail-image'], {
							[styles['active-thumbnail']]: selectedImgIndex === index,
							[styles['inactive-thumbnail']]: selectedImgIndex !== index && isImageGallery,
						})}
						onClick={() => handleThumbnailChange(index)}
						key={`thumbnail-${index}`}
						thumbnail-index={index}
						data-is-thumbnail-click
					>
						<div data-product-type={altText?.includes('lifestyle') ? 'LIFESTYLE' : type}>
							<Img
								src={url}
								alt={translations.videoThumbnail(
									productType,
									name,
									index + 1)
								}
								sizes='(max-width: 55em) 24w, (min-width: 551px) 64w, (min-width: 76.8em) 1000w'
								loading='eager'
							/>
						</div>
					</Flex>
				))
				: [];

		return (
			<Flex column className={styles['thumbnail-container']}>
				{thumbnailImgs.concat(videoThumbnail)}
			</Flex>
		);
	}, [handleThumbnailChange, images, selectedImgIndex, type, videoThumbnail, name, productType]);

	const renderTopFrameThumbnails = useCallback(() => {
		const topFrameThumbnailImgs =
			copyTopFrameVariant.length > 0
				? copyTopFrameVariant.map(({ primary, secondary }, index) => (
					<Flex
						align='center'
						className={cn(styles['thumbnail-image'], {
							[styles['active-thumbnail']]: selectedImgIndex === index,
							[styles['inactive-thumbnail']]: selectedImgIndex !== index && isImageGallery,
						})}
						onClick={() => handleThumbnailChange(index)}
						key={`thumbnail-${index}`}
						thumbnail-index={`${index}-3/4-image=${withThreeFourthImage}`}
						data-is-thumbnail-click
					>
						<ProductImage
							primary={primary}
							secondary={secondary}
							name={name}
							type={secondary?.includes('template-modal') ? 'LIFESTYLE' : type}
							imageLoadingHandler={imageLoadingHandler}
							thumbnails={true}
							sizes='200px'
						/>
					</Flex>
				))
				: [];

		return (
			<Flex column className={styles['thumbnail-container-tops']}>
				{topFrameThumbnailImgs.concat(videoThumbnail)}
			</Flex>
		);
	}, [
		copyTopFrameVariant,
		handleThumbnailChange,
		imageLoadingHandler,
		name,
		selectedImgIndex,
		type,
		videoThumbnail,
		withThreeFourthImage,
	]);

	return !hideThumbnail && (secondary ? renderTopFrameThumbnails() : renderThumbnails());
};

export default Thumbnails;
