import cn from 'classnames';
import { useEffect } from 'react';
import { InformationIcon, LabelText, Paragraph, StyledWordEmphasizer, Tooltip } from '@components';
import variables from '@styles/export.module.scss';
import { useBfroDiscount, useCustomer } from '@services/shopify';
import { useIsMobile, useTranslation } from '@utils/index';
import { sendHeapEventFlag } from '@services/analytics/heap';
import styles from './DiscountBanner.module.scss';

const { blue3 } = variables;

type DiscountBannerProps = {
	margin?: 'mobile' | 'bottom' | 'extra';
};

const getTranslatedTexts = translator => {
	return {
		oneTimeOfferCondition: translator('one-time-offer-condition'),
		nextPairDiscountOffer: (codeName: string) => translator('next-pair-discount-offer', { codeName }),
		codeNameDiscountPair: translator('code-name-discount-pair'),
	};
};

function DiscountBanner({ margin = null }: DiscountBannerProps) {
	const { data } = useCustomer();
	const { data: isBfroDisount } = useBfroDiscount();
	const isMobile = useIsMobile();
	const showBanner = !!data && isBfroDisount;
	const { translator } = useTranslation();
	const translations = getTranslatedTexts(translator);

	useEffect(() => {
		if (showBanner) {
			sendHeapEventFlag({ experimentId: 'is-bfro-discount', variationId: 1 });
		}
	}, [showBanner]);

	return showBanner ? (
		<div
			className={cn(
				styles.container,
				{ [styles.mobileMargin]: margin === 'mobile' },
				{ [styles.bottomMargin]: margin === 'bottom' },
				{ [styles.extraMargin]: margin === 'extra' }
			)}
		>
			<Tooltip
				message={translations.oneTimeOfferCondition}
				{...(isMobile && { dataTags: { 'data-bfro-banner-mobile-click': true } })}
			>
				<InformationIcon width={20} height={20} color={blue3} className={styles.info} />
			</Tooltip>
			<Paragraph className={styles.paragraph}>
				<StyledWordEmphasizer
					text={translations.nextPairDiscountOffer(translations.codeNameDiscountPair)}
					emphasizedWords={translations.codeNameDiscountPair}
					styledTexts={<LabelText>{translations.codeNameDiscountPair}</LabelText>}
				/>
			</Paragraph>
		</div>
	) : null;
}

export default DiscountBanner;
