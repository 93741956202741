import { Image } from '@ts/shopify-storefront-api';
import { TopFrameImage } from '@ts/index';
import {
	ASSET_EXT_REGEX,
	FRAME_COLORS,
	DEFAULT_CATEGORIES,
	DEFAULT_DEMOS,
	BASE_FRAME_NAMES,
	LENS_COLORS,
	PRODUCT_TYPES,
	FRAME_COLOR_HANDLES,
} from './constants';
import { handelize, getBaseName } from './strings';

/**
 * Generates a src attribute for an image HTML element based off Shopify's CDN URL
 * and a custom size.
 *
 * Returns a string of concatenated new urls with the sies added on.
 *
 * @param {string} url
 * @param {number} size
 * @returns {string}
 */
export const generateImgSrc = (url, size) => {
	const ext = url?.match(ASSET_EXT_REGEX);

	return url?.replace(ASSET_EXT_REGEX, `_${size}x${ext[0]}`);
};

/**
 * Generates an srcset attribute for image HTML elements based off Shopify's default URL
 * provided on the product object and a custom "sizes" array.
 *
 * Returns a string of concatenated new urls with the sies added on.
 *
 * @param {string} url
 * @param {array} sizes
 * @returns {string}
 */
export const generateSrcSet = (url: string) => {
	const sizes = [24, 48, 64, 128, 200, 375, 551, 769, 992, 1024, 1200, 1440, 2000];
	const ext = url?.match(ASSET_EXT_REGEX);

	return sizes.reduce((accumulator, size, index) => {
		const newUrl = url?.replace(ASSET_EXT_REGEX, `_${size}x${ext[0]}`);

		return accumulator + (index === 0 ? `${newUrl} ${size}w` : `, ${newUrl} ${size}w`);
	}, '');
};

/**
 * Chunkify Array
 *
 * @desc Splits array into chunks depending on the 'perChunk' argument. Default to perChunk = 3.
 * @param {object} {inputArray, perChunk}
 * @returns [chunkedArray]
 */
export const chunkifyArray = ({ inputArray, perChunk = 3 }) => {
	return inputArray.reduce((resultArray, item, index) => {
		const chunkIndex = Math.floor(index / perChunk);

		if (!resultArray[chunkIndex]) {
			resultArray[chunkIndex] = [];
		}

		resultArray[chunkIndex].push(item);

		return resultArray;
	}, []);
};

export const generateSunglassImages = ({
	baseColor,
	lensColor,
	length,
	name,
}: {
	baseColor: FRAME_COLORS;
	lensColor: LENS_COLORS;
	length: number;
	name: string;
}) => {
	const bc = handelize(baseColor);
	const lc = handelize(lensColor);
	const bn = getBaseName(name);
	const addendum = `${bc}-${lc}_PDP_${bn}.png`;

	return [...Array(length)].map(
		(__, i) =>
			({
				url: `${process.env.NEXT_PUBLIC_SHOPIFY_CDN_PATH}/carousel-image-${i + 1}-sun-${addendum}`,
				width: 1200,
				height: 600,
			}) as Image
	);
};

const getModifiedUrl = (baseUrl: string, frameColor): string => {
	const frameColorHandle = Object.entries(FRAME_COLORS).find(([, value]) => value === frameColor)?.[0];
	const handleValue = FRAME_COLOR_HANDLES[frameColorHandle];
	return baseUrl.replace('.png', `-${handleValue}.png`);
};

export const generateLifestyleImages = async ({
	category,
	demo,
	name,
	count = 1,
	frameColor = FRAME_COLORS.BLACK,
}: {
	category: (typeof DEFAULT_CATEGORIES)[number];
	demo: (typeof DEFAULT_DEMOS)[number];
	name: `The ${(typeof BASE_FRAME_NAMES)[number]}`;
	count?: number;
	frameColor?: FRAME_COLORS;
}) => {
	let targetDemo = DEFAULT_DEMOS.includes(demo) ? demo : 'all';
	const baseName = getBaseName(name);

	// For special cases where the demo is included in the name and we do not want to default to 'all' (ex: hello-suntops-men -> men)
	if (demo.includes(DEFAULT_DEMOS[0])) targetDemo = 'women';
	if (demo.includes(DEFAULT_DEMOS[1]) && demo !== 'women') targetDemo = 'men';
	if (demo.includes(DEFAULT_DEMOS[2])) targetDemo = 'kids';

	const carouselImages = await Promise.all(
		Array.from({ length: count }).map(async (_, i) => {
			const url = `${process.env.NEXT_PUBLIC_SHOPIFY_CDN_PATH}/carousel-image-${4 + i}_${category}_PDP_${baseName}_${
				targetDemo !== 'all' ? targetDemo : 'women'
			}.png`;
			const modifiedUrl = getModifiedUrl(url, frameColor);
			try {
				const responseModiedUrl = await fetch(modifiedUrl, { method: 'HEAD' });
				if (responseModiedUrl.ok) {
					return {
						url: modifiedUrl,
						height: 1280,
						width: 1984,
						altText: `${targetDemo} lifestyle ${i + 1}`,
					} as Image;
				}
				const response = await fetch(url, { method: 'HEAD' });
				if (response.ok) {
					return {
						url,
						height: 1280,
						width: 1984,
						altText: `${targetDemo} lifestyle ${i + 1}`,
					} as Image;
				}

				return false;
			} catch {
				return false;
			}
		})
	);

	const sidebarImage = {
		url: `${process.env.NEXT_PUBLIC_SHOPIFY_CDN_PATH}/alt-image_${category}_PDP_${baseName}_${
			targetDemo !== 'all' ? targetDemo : 'women'
		}.png`,
		height: 200,
		width: 310,
		altText: `${targetDemo} lifestyle sidebar`,
	} as Image;

	return {
		sidebar: [sidebarImage],
		carousel: carouselImages.filter(Boolean) as Image[],
	};
};

export const generateTopFrameImages = (
	secondary: Image[],
	isImageLoading: boolean,
	primaryImages: Image[],
	mode: string,
	type: string
) => {
	let topFrameVariant: TopFrameImage[] = [];
	let copyTopFrameVariant: TopFrameImage[] = [];
	if (secondary) {
		if (!isImageLoading) {
			topFrameVariant = primaryImages.map(({ url }, i) => {
				const secondaryVariant = secondary[i]?.url;
				return {
					primary: url,
					secondary: secondaryVariant,
				};
			});

			switch (mode) {
				case 'pdp':
					copyTopFrameVariant =
						topFrameVariant.length > 0 ? [...topFrameVariant, topFrameVariant[0], topFrameVariant[0]] : [];
					if (topFrameVariant.length > 1) {
						copyTopFrameVariant.splice(3, 0, topFrameVariant[1]);
					}
					if (type.includes(PRODUCT_TYPES.TOP_FRAME) && secondary.length >= 4) {
						copyTopFrameVariant.push({ secondary: secondary[secondary.length - 1].url });
					}
					break;
				default:
					copyTopFrameVariant = [];
					break;
			}
		} else copyTopFrameVariant = [];
	}
	return { topFrameVariant, copyTopFrameVariant };
};
