import { useQueryClient } from '@tanstack/react-query';
import { useRouter } from 'next/router';
import { useLocalStorage } from '@utils/hooks';
import { getCustomerToken, setCustomerToken } from '../customer-token';
import fetchStorefrontApi from '../fetch-storefront-api';
import customerAccessTokenDeleteMutation from '../mutations/customer-access-token-delete';

const useLogout = () => {
	const router = useRouter();
	const queryClient = useQueryClient();
	const [_, setHasSetDefaultFrame] = useLocalStorage<boolean>('hasSetDefaultFrame', false);
	return async () => {
		await fetchStorefrontApi(customerAccessTokenDeleteMutation, {
			variables: { customerAccessToken: getCustomerToken() },
		});
		setCustomerToken(null);
		setHasSetDefaultFrame(false);
		queryClient.resetQueries({ queryKey: ['favorites', 'orders', 'order-details', 'customer', 'rewardDetail'] });
		router.push('/');
		router.reload();
	};
};

export { useLogout };
