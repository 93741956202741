import { useQuery } from '@tanstack/react-query';
import { useGrowthBook } from '@growthbook/growthbook-react';
import { getCustomer } from '@services/shopify';
import { getSubscriber } from '@services/prive';
import { normalizeCustomer, normalizeOrderHistory } from '@utils/normalizers';
import { getOrders } from '@services/poms';
import { getShopifyIdFromGID } from '@utils/shopify';
import { BASE_FRAME_NAMES, PRODUCT_TYPES } from '@constants';
import { useActiveFrame, useLocalStorage } from '@utils/hooks';
import { getCustomerToken } from '../customer-token';

const useCustomer = () => {
	const customerAccessToken = getCustomerToken();
	const [_, setActiveFrame] = useActiveFrame();
	const [__, setHasSetDefaultFrame] = useLocalStorage<boolean>('hasSetDefaultFrame', false);
	const growthbook = useGrowthBook();

	return useQuery(
		['customer', customerAccessToken],
		async () => {
			let customer = await getCustomer(customerAccessToken);

			// we need to make sure that "customer" is available before running any tracking
			if (!customer) {
				return null;
			}

			customer = normalizeCustomer(customer);
			const orders = await getOrders({ id: getShopifyIdFromGID(customer.id), range: 180 });
			if (orders?.orders && orders.orders?.length) {
				const findBaseFrame = item => item.product_type.includes(PRODUCT_TYPES.BASE_FRAME);
				const { orders: normalizedOrders } = normalizeOrderHistory(orders);
				let defaultBaseFrame;
				for (const order of normalizedOrders) {
					const baseFrame = order.line_items.find(findBaseFrame);
					if (baseFrame) {
						defaultBaseFrame = baseFrame;
						break;
					}
				}

				if (defaultBaseFrame) {
					setActiveFrame(defaultBaseFrame.product_title.split('The ')[1] as (typeof BASE_FRAME_NAMES)[number]);
					setHasSetDefaultFrame(true);
				}
			}

			growthbook.setAttributes({
				...growthbook.getAttributes(),
				email: customer.email,
				loggedin: true,
			});

			const subscriberInfo = await getSubscriber(customer.id);
			customer.subscriberId = subscriberInfo?.id;
			// As soon as customer data is made available to the app, sync info with heap
			if (typeof window !== 'undefined' && window.heap) {
				window.heap.identify(customer.email);
			}
			return customer;
		},
		{
			staleTime: Infinity,
			refetchOnWindowFocus: false,
			onSuccess: data => {
				if (!data) return;
			},
		}
	);
};

export default useCustomer;
