import { useRouter } from 'next/router';
import { Flex, Spacer } from '@components';
import { Paragraph, Title, TypographyButton } from '@components/typography';
import { NormalizedVariant } from '@ts/index';
import { formatCurrency } from '@utils/shopify';
import { LOCALE_CODES } from '@constants';
import { useTranslation } from '@utils/hooks';
import PairCareTooltip from '../PairCareTooltip/PairCareTooltip';
import styles from './PairCareBody.module.scss';

type PairCareBodyProps = {
	variant: NormalizedVariant;
};

const getTranslatedTexts = translator => {
	return {
		pair: translator('pair-care-word-pair'),
		care: translator('pair-care-word-care'),
		warrantyOneYear: translator('warranty-1-year'),
	};
};

const PairCareBody = ({ variant }: PairCareBodyProps) => {
	const { locale } = useRouter();
	const showCurr = locale === LOCALE_CODES.AU || locale === LOCALE_CODES.CA;
	const { translator } = useTranslation();
	const translations = getTranslatedTexts(translator);

	return (
		<Flex column fullWidth>
			<Flex fullWidth justify='between'>
				<div>
					<Title className={styles.title}>
						<span>{translations.pair}</span>
						<span>{translations.care}</span>
					</Title>
				</div>
				<Flex style={{ height: 'fit-content', flexShrink: '0' }} align='center' gap={3}>
					<PairCareTooltip />
					<TypographyButton small className={styles.price}>
						{variant?.price &&
							formatCurrency(
								{ amount: variant?.price?.amount, currencyCode: variant?.price?.currencyCode, locale },
								showCurr
							)}
					</TypographyButton>
				</Flex>
			</Flex>
			<Spacer size={'0.6rem'} />
			<Paragraph>{translations.warrantyOneYear}</Paragraph>
		</Flex>
	);
};

export default PairCareBody;
