import { useLocalStorage } from '@utils/hooks/index';
import { BASE_FRAME_NAMES, DEFAULT_BASE_FRAME_SHAPE } from '@constants';

function useActiveFrame(defaultFrame: (typeof BASE_FRAME_NAMES)[number] | 'Default Title' = DEFAULT_BASE_FRAME_SHAPE) {
	const newFrame = defaultFrame === 'Default Title' || !defaultFrame ? DEFAULT_BASE_FRAME_SHAPE : defaultFrame;

	return useLocalStorage<(typeof BASE_FRAME_NAMES)[number]>('activeFrame', newFrame);
}

export default useActiveFrame;
