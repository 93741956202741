import { useQuery } from '@tanstack/react-query';
import { productRecommendationsQuery } from '@services/shopify/queries';
import { cleanGraphqlResponse, fetchStorefrontApi, GetProductOptions } from '@services/shopify';
import { normalizeCollectionProduct, normalizeProduct } from '@utils/normalizers/normalize-product';
import { CleanedCollectionProduct, CleanedProduct, Dict, NormalizedProduct, NormalizedVariant } from 'ts';
import { DEFAULT_BASE_FRAME_SHAPE } from '@constants';

export type GetProductsRecommendationsOptions = {
	country?: string;
	language?: string;
	types?: string[];
	basesToRecommendFor?: string[];
	maxRecommendations?: number;
};

export const useProductsRecommendationsQuery = (
	ids: string[],
	options: GetProductsRecommendationsOptions = {},
	{ queryRefreshVars = [], initialData = null, enabled = true }: any = {}
) => {
	return useQuery(
		[...queryRefreshVars, ...ids],
		async (): Promise<NormalizedProduct[]> => {
			if (!ids.length) return [];
			const { country, language, types, basesToRecommendFor } = options;
			const data = await getProductsRecommendations(ids, {
				country,
				language,
			});

			const productsByFrame: Record<string, NormalizedProduct[]> = { others: [] };

			data.forEach((product: NormalizedProduct) => {
				if (!types?.some(type => product.type.includes(type))) return;

				basesToRecommendFor?.forEach(base => {
					if (!base) return;
					const variant = product.variants.find(variant => variant.option === base);
					if (!productsByFrame[base]) productsByFrame[base] = [];
					if (variant)
						productsByFrame[base].push({
							...product,
							variants: [variant],
						});
					if (!variant && product.variants.length > 0)
						productsByFrame.others.push({
							...product,
							variants: [product.variants[0]],
						});
				});
			});
			const values = Object.values(productsByFrame).flat();
			return values;
		},
		{
			retry: 3,
			staleTime: Infinity,
			cacheTime: 5 * 60 * 1000,
			notifyOnChangeProps: ['data', 'error', 'status'],
			refetchOnWindowFocus: false,
			enabled,
		}
	);
};

export async function getProductRecommendations(
	id: string,
	getProductOptions: GetProductOptions = {}
): Promise<NormalizedProduct | NormalizedVariant> {
	const {
		includeDescription = true,
		skipCollections = true,
		skipImages = false,
		skipVariants = false,
		includeSpecificFrameVariant = false,
		country,
		selectedOptions = [{ name: 'Frame', value: DEFAULT_BASE_FRAME_SHAPE }],
		maxVariants = 25,
	} = getProductOptions;
	const { productRecommendations } = await fetchStorefrontApi(
		productRecommendationsQuery,
		{
			variables: {
				skipCollections,
				skipImages,
				skipVariants,
				includeDescription,
				includeSpecificFrameVariant,
				selectedOptions,
				country,
				maxVariants,
				productId: id,
			},
		},
		{ redirect: 'follow' }
	);
	if (!productRecommendations) {
		return null;
	}
	const cleaned = productRecommendations.map((product: Dict) => cleanGraphqlResponse<CleanedProduct>(product));

	if (includeSpecificFrameVariant)
		return cleaned.map(item =>
			item.variantBySelectedOptions
				? (normalizeCollectionProduct(item as CleanedCollectionProduct) as NormalizedVariant)
				: (normalizeProduct(item as CleanedProduct) as NormalizedProduct)
		);

	return cleaned.map(item => normalizeProduct(item as CleanedProduct) as NormalizedProduct);
}
/**
 * General fetching utility for multiple products
 *
 * @param {string[]} handles
 * @return {object[]} Normalized product info from Shopify's GraphQL Storefront API
 */
export async function getProductsRecommendations(
	ids: string[],
	getProductOptions: GetProductOptions = {}
): Promise<(NormalizedProduct | NormalizedVariant)[]> {
	const response = await Promise.all(ids.map(async id => getProductRecommendations(id, getProductOptions)));
	const products = response.flat();
	return products;
}
