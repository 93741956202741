import { DEFAULT_CATEGORIES, LENSES_PACKAGES } from '@constants';
import { PrescriptionOption, RxOption } from '@ts/index';
import { BASE_FRAME_LENS_OPTIONS, RX_TYPE_HANDLE_MAP, RX_TYPE as RX_TYPE_OPTION } from './constants/base-skus';

export const parseLensOptionToLensPackage = (lensOption: BASE_FRAME_LENS_OPTIONS): LENSES_PACKAGES => {
	switch (lensOption) {
		case BASE_FRAME_LENS_OPTIONS.CR39:
			return LENSES_PACKAGES.BASIC;
		case BASE_FRAME_LENS_OPTIONS.PREMIUM_PLUS:
			return LENSES_PACKAGES.PREMIUM;
		default:
			return LENSES_PACKAGES.STANDARD;
	}
};

export const findLensPackageInLensOptions = (lensOptions: BASE_FRAME_LENS_OPTIONS[]): LENSES_PACKAGES => {
	return parseLensOptionToLensPackage(
		lensOptions.find(lens => lens === BASE_FRAME_LENS_OPTIONS.CR39 || lens === BASE_FRAME_LENS_OPTIONS.PREMIUM_PLUS)
	);
};

/**
 * Normalizes build flow data from Contentful.
 * Handles visibilty logic for different flows / demos.
 */
export const createRxOptionsForJourney = (
	prescriptions: Array<PrescriptionOption>,
	journey: (typeof DEFAULT_CATEGORIES)[number]
) => {
	let rxOptions = prescriptions;

	if (journey !== 'eyeglasses') {
		// move nonRX option to first position
		const indexOfNonRx = rxOptions.findIndex(r => r.type === RX_TYPE_OPTION.NON_RX);
		if (indexOfNonRx > 0) {
			rxOptions.unshift(rxOptions.splice(indexOfNonRx, 1)[0]);
		}

		// remove all lenses
		rxOptions = rxOptions.map(option => ({
			...option,
			lenses: [],
		}));
	}

	return rxOptions.map<RxOption>(option => {
		return {
			id: RX_TYPE_HANDLE_MAP[option.type],
			copy: option.copy.title,
			description: option.copy.description,
			lenses: option.upgrades.map(lens => ({
				name: lens.copy.title,
				description: lens.copy.description,
				optionValue: lens.type,
				handle: lens.handle,
				tag: lens.tag,
				...(lens.altCopy && { altCopy: lens.altCopy }),
			})),
			optionValue: option.type,
			...(option.altCopy && { altCopy: option.altCopy }),
		};
	});
};
