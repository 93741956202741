import { productFieldsFragment, productMetafieldsFragment } from '../fragments/product';

const productQuery = /* GraphQL */ `
	query getProduct(
		$handle: String
		$id: ID
		$includeDescription: Boolean = true
		$includeSpecificFrameVariant: Boolean = false
		$skipCollections: Boolean = false
		$skipImages: Boolean = false
		$skipVariants: Boolean = false
		$selectedOptions: [SelectedOptionInput!]!
		$country: CountryCode = US
		$maxVariants: Int = 25
		$language: LanguageCode = EN
	) @inContext(country: $country, language: $language) {
		product(handle: $handle, id: $id) {
			...productFields
			...productMetafields
		}
	}

	${productFieldsFragment}
	${productMetafieldsFragment}
`;

export default productQuery;
