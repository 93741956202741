import { Customer } from '@ts/shopify-storefront-api';

export function normalizeCustomer(customerData): Customer {
	const isMembershipVip = customerData.tags.includes('EXM: Plus: Active');
	const membershipInfo = customerData.membershipInfo && JSON.parse(customerData.membershipInfo.value);
	const membershipHash = customerData.membershipHash && getMembershipHash(customerData.membershipHash.value);

	return {
		...customerData,
		isMembershipVip,
		membershipInfo,
		membershipHash,
	};
}

function getMembershipHash(value: string) {
	try {
		return JSON.parse(value);
	} catch (e) {
		return value;
	}
}
