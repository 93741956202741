import { Fragment, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { LOCALE_CODES, LOCALE_DICT, MEDIUM_WIDTH } from '@constants';
import { useHasMounted, useIsMobile, useLockedBody } from '@utils/hooks';
import { NavMenuProps } from '@ts/contentful';
import { Select, SelectValues } from '@components';
import { useLocaleContext } from '@context';
import MenuGroup from '../MenuGroup';
import styles from './NavMenu.module.scss';

const NavMenu = ({ header, isMobileNavOpen, setMobileNavOpen }: NavMenuProps) => {
	const router = useRouter();
	const [activeItem, setActiveItem] = useState(null);
	const [hoverDebounceTimer, setHoverDebounceTimer] = useState(null);
	const isMobile = useIsMobile({ maxWidth: MEDIUM_WIDTH });
	const isMounted = useHasMounted();
	const { handleLocaleChange } = useLocaleContext();
	const isV2Subscription = useFeatureIsOn('is-v2-subscription-test');
	const isMembership = useFeatureIsOn('is-membership');
	const isUs = router.locale === LOCALE_CODES.US;
	const isCa = router.locale === LOCALE_CODES.CA;

	const showSubscription = isV2Subscription && isUs;
	const showMembership = isMembership && isUs;
	const showReferAFriend = isUs || isCa;
	const countrySelectorIndex = isMobile ? 1 : showReferAFriend ? 3 : 2;
	const countryOptions = Object.values(LOCALE_DICT).map(value => value.countryCode) as SelectValues;
	const countryCode = LOCALE_DICT[router.locale]?.countryCode;

	const toggleDesktopSubNav = (shouldOpen, item) => {
		if (isMobile) {
			return;
		}
		setActiveItem(shouldOpen ? item.handle : null);
	};

	const toggleMobileSubNav = (shouldOpen, item) => {
		if (!isMobile) {
			return;
		}

		setActiveItem(shouldOpen ? item.handle : null);
	};

	const debounceHover = callback => {
		clearTimeout(hoverDebounceTimer);

		setHoverDebounceTimer(
			setTimeout(() => {
				callback();
			}, 200)
		);
	};

	const menuGroups = header
		? header?.menuGroups.map((menuGroup, index) => {
				const isReferAFriend = menuGroup.text === 'Refer a Friend';
				const isMembershipMenu = menuGroup.handle === 'membership';
				const isPairPlusMenu = menuGroup.handle === 'pair-plus';
				return (
					<Fragment key={`menuGroup-${index}`}>
						{((showReferAFriend && isReferAFriend) ||
							(!isReferAFriend && !isMembershipMenu && !isPairPlusMenu) ||
							(isMembershipMenu && showSubscription) ||
							(isPairPlusMenu && showMembership)) && (
							<MenuGroup
								key={`menu-group-${menuGroup.handle + index}`}
								header={header}
								menuGroup={menuGroup}
								debounceHover={debounceHover}
								toggleDesktopSubNav={toggleDesktopSubNav}
								toggleMobileSubNav={toggleMobileSubNav}
								activeItem={activeItem}
								index={index}
							/>
						)}
						{index === header.menuGroups.length - countrySelectorIndex && isMounted && (
							<li className={styles['country-selector']}>
								<Select
									key={`country-selector-${countryCode}-${isMobile ? 'mobile' : 'desktop'}`}
									buttonProps={{
										size: isMobile ? 'medium' : 'small',
										fullWidth: isMobile,
										spaceBetween: true,
										color: 'white',
									}}
									handler={handleLocaleChange}
									values={countryOptions}
									placeholder={countryCode}
									prevSelection={countryCode}
									data-testid={'country-selector'}
									withIcon
									{...(isMobile && { label: 'Country' })}
								/>
							</li>
						)}
					</Fragment>
				);
			})
		: null;

	useEffect(() => {
		const handleRouteChange = () => {
			setActiveItem(null);
			setMobileNavOpen(false);
		};
		router.events.on('routeChangeComplete', handleRouteChange);
		return () => {
			router.events.off('routeChangeComplete', handleRouteChange);
		};
	}, [router]);

	useLockedBody(isMobileNavOpen);

	return (
		<nav
			className={styles.nav}
			data-is-open={isMobileNavOpen}
			data-has-open-sub-nav={!!activeItem}
			onMouseLeave={() => {
				!isMobile && debounceHover(() => setActiveItem(null));
			}}
		>
			<ul id='navlist' className={styles['nav-list']} aria-labelledby='navbutton'>
				{menuGroups}
			</ul>
		</nav>
	);
};

export default NavMenu;
