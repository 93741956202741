import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { NormalizedCollection, NormalizedProduct } from '@ts/product';
import { LOCALE_DICT, PRODUCT_TYPES } from '@constants';
import { getMultipleCollections } from '@services/shopify/operations/get-multiple-collections';
import { getAllBaseFrames } from '@services/contentful';
import { useRecommendsProps } from '@ts/shopify';
import { useProductsRecommendationsQuery } from '../operations/get-product-recommendations';

const DEFAULT_RECOMMENDATION = 'classic-designs';

type RecommendsState = {
	recommendations: NormalizedProduct[];
	index: number;
	type?: (typeof PRODUCT_TYPES)[keyof typeof PRODUCT_TYPES];
	isLoading: boolean;
};

const useRecommends = ({
	objectIds,
	types = null,
	maxRecommendations = 0,
	basesToRecommendFor = null,
	collectionHandle = null,
}: useRecommendsProps) => {
	const { locale } = useRouter();
	const { countryCode: country, languageCode: language } = LOCALE_DICT[locale];

	const { data, isFetching } = useProductsRecommendationsQuery(objectIds, {
		types,
		basesToRecommendFor,
		country,
		maxRecommendations,
		language,
	});

	const [recommendState, setRecommendState] = useState<RecommendsState>({
		recommendations: null,
		index: 0,
		type: null,
		isLoading: true,
	});

	useEffect(() => {
		setRecommendState(prev => ({ ...prev, isLoading: isFetching }));
	}, [isFetching]);

	useEffect(() => {
		(async () => {
			let recommendationHandle = DEFAULT_RECOMMENDATION;
			const { countryCode: country, languageCode: language } = LOCALE_DICT[locale];

			if (types.includes(PRODUCT_TYPES.ACCESSORY)) {
				recommendationHandle = 'accessories';
			}

			if (types.includes(PRODUCT_TYPES.BASE_FRAME)) {
				const baseCollection = (await getAllBaseFrames(true)) as NormalizedCollection;

				return setRecommendState(
					prev =>
						({
							...prev,
							recommendations: data?.length ? data : baseCollection?.products,
						}) as RecommendsState
				);
			}

			const defaultRec = await getMultipleCollections(collectionHandle || recommendationHandle, {
				country,
				language,
			});

			setRecommendState(
				prev =>
					({
						...prev,
						recommendations: data?.length ? data : defaultRec?.products,
					}) as RecommendsState
			);
		})();
	}, [locale, !isFetching]);

	return recommendState;
};

export default useRecommends;
