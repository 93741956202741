import { memo, forwardRef, useMemo, useState } from 'react';
import cn from 'classnames';
import { useRouter } from 'next/router';
import { useQueryClient } from '@tanstack/react-query';
import {
	EMPTY_PRODUCT,
	LOCALE_CODES,
	MEDIUM_LARGE_WIDTH,
	MEDIUM_WIDTH,
	PRODUCT_TYPES,
	MODEL_LIFESTYLE_IMAGE,
	TOP_FRAMES_PATH,
} from '@constants';
import { Button, Card, ProductImage } from '@components';
import { formatCurrency } from '@utils/shopify';
import useIsMobile from '@utils/hooks/useIsMobile';
import { NormalizedProduct, NormalizedVariant } from '@ts/product';
import { useBFContext } from '@context';
import { trackAddToCart, trackRemoveFromCart } from '@services/analytics/trackers';
import { useDiscountedPrice, useLozengeData } from '@utils/hooks';
import { useProductImageQuery } from '@services/shopify';
import styles from './BuildFlowCard.module.scss';

type BuildFlowCardProps = {
	top: NormalizedVariant;
	onClick?: (top: NormalizedVariant) => void;
	tops?: { [key: string]: NormalizedVariant };
	type?: 'build-flow' | 'bundle';
	disabled?: boolean;
	hoverable?: boolean;
};

const returnTopProduct = (top: NormalizedVariant): NormalizedProduct => {
	return {
		...EMPTY_PRODUCT,
		id: top?.product?.id,
		type: top?.product?.type,
		handle: top?.product?.handle,
		name: top?.product?.name,
		tags: top?.product?.tags,
		variants: [
			{
				...top,
				metafields: {
					shipmentInfo: 'ships in 2-3 weeks',
				},
			},
		],
	};
};

const BuildFlowCard = forwardRef<HTMLDivElement, BuildFlowCardProps>(
	({ tops, top, onClick, type = 'build-flow', disabled = false, hoverable = false }: BuildFlowCardProps, ref) => {
		const { tops: topsBF, handleTop, isSubscriptionActivated, frame } = useBFContext() || {};
		const isKirby = frame === 'Kirby';
		const [enabledQuery, setEnabledQuery] = useState(false);
		const queryKey = 'choose-tops-' + top.handle;
		const { data: kirbyURL, isLoading } = useProductImageQuery(top.product.handle, undefined, {
			queryKey,
			enabled: enabledQuery && !isKirby && hoverable,
		});
		const queryClient = useQueryClient();
		const isMobile = useIsMobile({ maxWidth: MEDIUM_WIDTH });
		const isTablet = useIsMobile({ maxWidth: MEDIUM_LARGE_WIDTH });
		const { locale } = useRouter();
		const showCurr = locale === LOCALE_CODES.AU || locale === LOCALE_CODES.CA;
		const product = useMemo(() => returnTopProduct(top), [top]);
		const lozengeData = useLozengeData(product);

		const [newPrice, newCompareAtPrice] = useDiscountedPrice({
			price: top.price.amount,
			compareAtPrice: top.compareAtPrice?.amount,
			type: type == 'bundle' ? PRODUCT_TYPES.BUNDLE : product.type,
			mandatoryDiscount: isSubscriptionActivated,
		});

		if (!top.availableForSale) return null;
		const currentTops = type === 'bundle' ? tops : topsBF;

		const hasTop = currentTops[top.handle];

		const buttonClasses = cn(styles.button, {
			[styles.buttonClicked]: hasTop,
		});

		const priceClasses = cn(styles.price, {
			[styles.priceClicked]: hasTop,
			[styles.priceCompare]: top.compareAtPrice?.amount && !hasTop,
		});

		const deleteDataTag = type === 'bundle' ? { 'data-delete-top-byob': top.name } : { 'data-delete-top': top.name };
		const addDataTag =
			type === 'bundle' ? { 'data-add-top-byob': top.name } : { 'data-add-top': top.name, 'data-testid': 'add-top' };

		const customButton = (
			<Button
				disabled={!hasTop && disabled}
				color={hasTop ? 'blue' : 'white'}
				extraClasses={buttonClasses}
				size='small'
				fullWidth
				removeEffects
				onClick={() => {
					if (onClick) {
						onClick(top);
					} else {
						handleTop({ ...top, collection: `${TOP_FRAMES_PATH}/${lozengeData?.lozengeHandle}` });
						hasTop
							? trackRemoveFromCart({ variant: top, path: `${TOP_FRAMES_PATH}/${lozengeData?.lozengeHandle}/` })
							: trackAddToCart({ variants: [top], path: `${TOP_FRAMES_PATH}/${lozengeData?.lozengeHandle}/` });
					}
				}}
				{...(hasTop ? deleteDataTag : addDataTag)}
			>
				<span data-add-to-cart-text data-remove={hasTop || null} />
				<div className={styles.priceContainer}>
					<span data-pricing={newPrice} data-testid={'top-price'} className={priceClasses}>
						{formatCurrency(
							{
								amount: newPrice,
								currencyCode: top.price.currencyCode,
								locale,
							},
							showCurr
						)}
					</span>
					{newCompareAtPrice && !hasTop && (
						<span className={styles.comparePrice} data-pricing={newCompareAtPrice}>
							{formatCurrency({ ...top.price, amount: newCompareAtPrice, locale: locale }, showCurr)}
						</span>
					)}
				</div>
			</Button>
		);
		const isBundleSet = top.type?.includes('BUNDLE');
		return (
			<Card
				key={`${top.handle}-top-card`}
				ref={ref}
				bulletVariant={hoverable ? 'gray' : undefined}
				className={cn(styles.container, { [styles['container--bundle']]: type === 'bundle' })}
				product={returnTopProduct(top)}
				variant={top}
				aspectRatio='2/1'
				containerType='build-flow'
				contentType='vertical'
				buttonGroupType='side-by-side'
				showCarouselModal={(type === 'build-flow' && isTablet) || hoverable}
				primaryAction='custom'
				primaryActionData={{ customComponent: customButton }}
				secondaryAction='none'
				isMobile={isMobile}
				showTags={type !== 'bundle'}
				hoverable
				compact
				ImgClickHandler={() => null}
				enableLifeStyleGallery={hoverable}
				dataTags={{
					button: {},
					zoom: {
						'data-tops-zoom': 'buildflow',
					},
					favorite: {},
				}}
				hoverCallback={() => {
					if (isBundleSet || !hoverable) return;
					if (isKirby && !!queryClient && !!top?.image?.url && hoverable) {
						queryClient.setQueryData([queryKey], top.image.url);
						return;
					}
					setEnabledQuery(true);
				}}
				customHoverSrc={isLoading && hoverable && !isKirby && !isBundleSet && MODEL_LIFESTYLE_IMAGE}
				customImgHover={
					(!isLoading || isKirby) &&
					hoverable &&
					!isBundleSet && (
						<ProductImage
							primary={isKirby ? top.image.url : kirbyURL}
							secondary={MODEL_LIFESTYLE_IMAGE}
							name={top.product.name}
							type={top.product.type}
							className={cn(styles.hoverImg, { [styles['hoverImg--is-mobile']]: isMobile })}
							isMobile={isMobile && hoverable}
						/>
					)
				}
			/>
		);
	}
);

BuildFlowCard.displayName = 'BuildFlowCard';

export default memo(BuildFlowCard);
