import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { useRouter } from 'next/router';
import { useIsMobile } from '@utils/hooks';
import { LARGE_WIDTH } from '@constants';

function GlobalStyleInjector() {
	const isMobile = useIsMobile({ maxWidth: LARGE_WIDTH });
	const isQuickAdd = useFeatureIsOn('is-quick-add') && !isMobile;
	const isStickySidebar = useFeatureIsOn('is-sticky-right-rail');
	const isImageGallery = useFeatureIsOn('is-image-gallery');
	const isSingleColImage = useFeatureIsOn('is-single-col-image');
	const isDoubleColImage = useFeatureIsOn('is-double-col-image');
	const isPDPImage = isImageGallery || isSingleColImage || isDoubleColImage;

	const { pathname } = useRouter();
	const showWiderRightRail = (isQuickAdd || isStickySidebar || isPDPImage) && pathname.includes('/eyeglasses');

	return (
		<style jsx global>
			{`
				:root {
					--pdp-left-rail: ${showWiderRightRail ? '60%' : '66.7%'};
					--pdp-right-rail: ${showWiderRightRail ? '40%' : '33.3%'};
				}
			`}
		</style>
	);
}

export default GlobalStyleInjector;
