import { useQuery, useQueryClient, UseQueryResult } from '@tanstack/react-query';
import { useRouter } from 'next/router';
import Cookies from 'js-cookie';
import { LOCALE_DICT, SHOPIFY_CART_ID_COOKIE, SUBSCRIPTION_COOKIE } from '@constants';
import { throwCartErrors } from '@services/shopify/throw-cart-errors';
import { Mutation } from '@ts/shopify-storefront-api';
import { cartCreateMutation, fetchStorefrontApi, setCartId } from '@services/shopify';

const useCartId = (): UseQueryResult<string> => {
	const queryClient = useQueryClient();
	const { locale } = useRouter();

	return useQuery<string>(
		['cart', 'id'],
		async () => {
			const cartIdCookie = Cookies.get(SHOPIFY_CART_ID_COOKIE);
			const { countryCode: country, languageCode: language } = LOCALE_DICT[locale];
			if (cartIdCookie) {
				queryClient.setQueryData(['cart', 'id'], cartIdCookie);
				return cartIdCookie;
			}
			Cookies.remove(SUBSCRIPTION_COOKIE);

			const { cartCreate }: Mutation = await fetchStorefrontApi(cartCreateMutation, {
				variables: { country, language },
			});
			const { userErrors, cart } = cartCreate;

			throwCartErrors(userErrors);
			queryClient.setQueryData(['cart', 'id'], cart.id);
			setCartId(cart.id);
			console.warn(`Cart ID cookie not found, new cookie: ${cart.id}`);
			return cart.id;
		},
		{
			placeholderData: null,
			retry: false,
			refetchOnWindowFocus: false,
			cacheTime: Number.POSITIVE_INFINITY,
		}
	);
};

export { useCartId };
