/* eslint-disable max-lines */
import { UseRefinementListProps } from 'react-instantsearch';
import { NormalizedCollection } from '@ts/product';
import { BASE_FRAME_NAMES, FRAME_COLORS, LOCALE_CODES, NEW_COLORS, PRODUCT_TYPE_NAMES, PRODUCT_TYPES } from '@constants';
import { translate } from './locales';
import type { CurrentRefinementsRenderState } from 'instantsearch.js/es/connectors/current-refinements/connectCurrentRefinements';

// --- Types --- //
export type RefinementItem = {
	value: string;
	label: string;
	highlighted?: string;
	count: number;
	isRefined: boolean;
};
export type RefinementOptions =
	| 'color'
	| 'design'
	| 'collections'
	| 'product_type'
	| 'frame_shape'
	| 'frame_size'
	| 'frame_material';
export type RefinementListProps = UseRefinementListProps & {
	type: RefinementOptions;
	isOpened?: boolean;
	label?: string;
	listType?: 'accordion' | 'dropdown';
	className?: string;
	setIsDisabled?: (isDisabled: boolean) => void;
	page?: 'all-tops' | 'buildflow' | 'search' | 'bf-all-tops' | 'eyeglasses' | 'sunglasses';
};
export type NormalizeRefinementItemsArgs = {
	type: RefinementOptions;
	collections: Record<string, Omit<NormalizedCollection, 'products'>>;
	items: Array<RefinementItem>;
	isSunglasses?: boolean;
	isHideNewProducts: boolean;
};

// --- Constants --- //
export const NON_STANDARDIZED_COLLECTIONS = [
	'TOP_FRAME__MARVEL2023',
	'TOP_FRAME__MARVEL2023_SHE_HULK',
	'TOP_FRAME__MARVEL_GUARDIANS',
	'TOP_FRAME__MARVEL_INFINITY',
	'TOP_FRAME__SPARKLEANDSUNTOP2023',
];

export const MARVEL_COLLECTION_LABEL = 'TOP_FRAME__MARVEL';
export const MARVEL_COLLECTIONS = [
	'TOP_FRAME__MARVEL',
	'TOP_FRAME__MARVEL2023',
	'TOP_FRAME__MARVEL2023_SHE_HULK',
	'TOP_FRAME__MARVEL_GUARDIANS',
	'TOP_FRAME__MARVEL_INFINITY',
];
export const SPARKLE_COLLECTION_LABEL = 'TOP_FRAME__SPARKLE';
export const SPARKLE_COLLECTIONS = ['TOP_FRAME__SPARKLE', 'TOP_FRAME__SPARKLEANDSUNTOP2023'];

export const ALGOLIA_DEFAULT_INDEX = process.env.NEXT_PUBLIC_ALGOLIA_DEFAULT_INDEX;

export const ALGOLIA_SEARCH_INDEXES = {
	US: LOCALE_CODES.US,
	CA: LOCALE_CODES.CA,
	AU: LOCALE_CODES.AU,
	GB: LOCALE_CODES.GB,
	MX: LOCALE_CODES.US,
};

export const ALGOLIA_SEARCH_PARAMS = {
	US: {
		ALGOLIA_SEARCH_PARAM: `${ALGOLIA_SEARCH_INDEXES.US}[query]`,
		ALGOLIA_SEARCH_URL: `search?${ALGOLIA_SEARCH_INDEXES.US}%5Bquery%5D=`,
		ALGOLIA_FILTER_COLOR_URL: `${ALGOLIA_SEARCH_INDEXES.US}[refinementList][meta.custom_fields.colors][0]`,
		ALGOLIA_FILTER_DESIGN_URL: `${ALGOLIA_SEARCH_INDEXES.US}[refinementList][meta.custom_fields.design][0]`,
	},
	CA: {
		ALGOLIA_SEARCH_PARAM: `${ALGOLIA_SEARCH_INDEXES.CA}[query]`,
		ALGOLIA_SEARCH_URL: `search?${ALGOLIA_SEARCH_INDEXES.CA}%5Bquery%5D=`,
		ALGOLIA_FILTER_COLOR_URL: `${ALGOLIA_SEARCH_INDEXES.CA}[refinementList][meta.custom_fields.colors][0]`,
		ALGOLIA_FILTER_DESIGN_URL: `${ALGOLIA_SEARCH_INDEXES.CA}[refinementList][meta.custom_fields.design][0]`,
	},
	AU: {
		ALGOLIA_SEARCH_PARAM: `${ALGOLIA_SEARCH_INDEXES.AU}[query]`,
		ALGOLIA_SEARCH_URL: `search?${ALGOLIA_SEARCH_INDEXES.AU}%5Bquery%5D=`,
		ALGOLIA_FILTER_COLOR_URL: `${ALGOLIA_SEARCH_INDEXES.AU}[refinementList][meta.custom_fields.colors][0]`,
		ALGOLIA_FILTER_DESIGN_URL: `${ALGOLIA_SEARCH_INDEXES.AU}[refinementList][meta.custom_fields.design][0]`,
	},
	GB: {
		ALGOLIA_SEARCH_PARAM: `${ALGOLIA_SEARCH_INDEXES.GB}[query]`,
		ALGOLIA_SEARCH_URL: `search?${ALGOLIA_SEARCH_INDEXES.GB}%5Bquery%5D=`,
		ALGOLIA_FILTER_COLOR_URL: `${ALGOLIA_SEARCH_INDEXES.GB}[refinementList][meta.custom_fields.colors][0]`,
		ALGOLIA_FILTER_DESIGN_URL: `${ALGOLIA_SEARCH_INDEXES.GB}[refinementList][meta.custom_fields.design][0]`,
	},
	MX: {
		// TODO: switch to MX index when available
		ALGOLIA_SEARCH_PARAM: `${ALGOLIA_SEARCH_INDEXES.US}[query]`,
		ALGOLIA_SEARCH_URL: `search?${ALGOLIA_SEARCH_INDEXES.US}%5Bquery%5D=`,
		ALGOLIA_FILTER_COLOR_URL: `${ALGOLIA_SEARCH_INDEXES.US}[refinementList][meta.custom_fields.colors][0]`,
		ALGOLIA_FILTER_DESIGN_URL: `${ALGOLIA_SEARCH_INDEXES.US}[refinementList][meta.custom_fields.design][0]`,
	},
};

export const ALGOLIA_REFINEMENTS_SEARCH: Array<RefinementListProps> = [
	{
		type: 'color',
		label: 'Color',
		attribute: 'meta.custom_fields.colors',
		operator: 'or',
		limit: 20,
	},
	{
		type: 'design',
		attribute: 'meta.custom_fields.design',
		label: 'Design',
		operator: 'or',
		limit: 4,
		showMore: true,
		showMoreLimit: 20,
	},
	{
		type: 'product_type',
		label: 'Product Type',
		attribute: 'product_type',
		operator: 'or',
		limit: 3,
		showMore: true,
		showMoreLimit: 30,
	},
	{
		type: 'collections',
		label: 'Collection',
		attribute: 'product_type',
		operator: 'or',
		limit: 4,
		showMore: true,
		showMoreLimit: 30,
	},
];

export const ALGOLIA_REFINEMENTS_ALLTOPS: Array<RefinementListProps> = [
	{
		type: 'color',
		label: 'Color',
		attribute: 'meta.custom_fields.colors',
		operator: 'or',
		limit: 20,
	},
	{
		type: 'design',
		attribute: 'meta.custom_fields.design',
		label: 'Design',
		operator: 'or',
		limit: 4,
		showMore: true,
		showMoreLimit: 20,
	},
	{
		type: 'product_type',
		label: 'Product Type',
		attribute: 'product_type',
		operator: 'or',
		limit: 30,
	},
	{
		type: 'collections',
		label: 'Collection',
		attribute: 'product_type',
		operator: 'or',
		limit: 4,
		showMore: true,
		showMoreLimit: 30,
	},
];

export const getAlgoliaRefinementsAllTops = (locale: string) => {
	const ALGOLIA_REFINEMENTS_ALLTOPS: Array<RefinementListProps> = [
		{
			type: 'color',
			label: translate('color', locale),
			attribute: 'meta.custom_fields.colors',
			operator: 'or',
			limit: 20,
		},
		{
			type: 'design',
			attribute: 'meta.custom_fields.design',
			label: translate('design', locale),
			operator: 'or',
			limit: 4,
			showMore: true,
			showMoreLimit: 20,
		},
		{
			type: 'product_type',
			label: translate('product-type', locale),
			attribute: 'product_type',
			operator: 'or',
			limit: 30,
		},
		{
			type: 'collections',
			label: translate('collection-uppercase', locale),
			attribute: 'product_type',
			operator: 'or',
			limit: 4,
			showMore: true,
			showMoreLimit: 30,
		},
	];
	return ALGOLIA_REFINEMENTS_ALLTOPS;
};

export const getAlgoliaRefinementsEyeglasses = (locale: string) => {
	const ALGOLIA_REFINEMENTS_EYEGLASSES: Array<RefinementListProps> = [
		{
			type: 'color',
			attribute: 'options.color',
			label: translate('color', locale),
			operator: 'or',
			limit: 20,
		},
		{
			type: 'frame_shape',
			attribute: 'meta.custom_fields.frame_shape',
			label: translate('frame-shape', locale),
			operator: 'or',
			limit: 100,
		},
		{
			type: 'frame_size',
			attribute: 'tags',
			label: translate('frame-size', locale),
			operator: 'or',
			limit: 100,
		},
		{
			type: 'frame_material',
			attribute: 'meta.custom_fields.frame_material',
			label: translate('material', locale),
			operator: 'or',
			limit: 100,
		},
	];
	return ALGOLIA_REFINEMENTS_EYEGLASSES;
};

export const getAlgoliaRefinementsSunglasses = (locale: string) => {
	const ALGOLIA_REFINEMENTS_SUNGLASSES: Array<RefinementListProps> = [
		{
			type: 'color',
			attribute: 'options.color',
			label: translate('color', locale),
			operator: 'or',
			limit: 20,
		},
		{
			type: 'color',
			attribute: 'options.lens',
			label: translate('lens-color', locale),
			operator: 'or',
			limit: 20,
		},
		{
			type: 'frame_shape',
			attribute: 'meta.custom_fields.frame_shape',
			label: translate('frame-shape', locale),
			operator: 'or',
			limit: 100,
		},
		{
			type: 'frame_size',
			attribute: 'tags',
			label: translate('frame-size', locale),
			operator: 'or',
			limit: 100,
		},
		{
			type: 'frame_material',
			attribute: 'meta.custom_fields.frame_material',
			label: translate('material', locale),
			operator: 'or',
			limit: 100,
		},
	];
	return ALGOLIA_REFINEMENTS_SUNGLASSES;
};

export const ALGOLIA_REFINEMENTS_BUILDFLOW: Array<RefinementListProps> = [
	{
		type: 'color',
		label: 'Color',
		attribute: 'meta.custom_fields.colors',
		operator: 'or',
		limit: 20,
	},
	{
		type: 'design',
		attribute: 'meta.custom_fields.design',
		label: 'Design',
		operator: 'or',
		limit: 25,
	},
	{
		type: 'collections',
		label: 'Collection',
		attribute: 'product_type',
		operator: 'or',
		limit: 25,
	},
];

export const getAngoliaRefinementBuildFlow = (locale: string): Array<RefinementListProps> => {
	const ALGOLIA_REFINEMENTS_BUILDFLOW: Array<RefinementListProps> = [
		{
			type: 'color',
			label: translate('color', locale),
			attribute: 'meta.custom_fields.colors',
			operator: 'or',
			limit: 20,
		},
		{
			type: 'design',
			attribute: 'meta.custom_fields.design',
			label: translate('design', locale),
			operator: 'or',
			limit: 25,
		},
		{
			type: 'collections',
			label: translate('collection-uppercase', locale),
			attribute: 'product_type',
			operator: 'or',
			limit: 25,
		},
		{
			type: 'product_type',
			label: translate('product-type', locale),
			attribute: 'product_type',
			operator: 'or',
			limit: 30,
		},
	];

	return ALGOLIA_REFINEMENTS_BUILDFLOW;
};

// --- Functions --- //
export const getMarvelRefinements = (items: Array<RefinementItem>) =>
	items.filter(item => MARVEL_COLLECTIONS.includes(item.value));
export const getSparkleRefinements = (items: Array<RefinementItem>) =>
	items.filter(item => SPARKLE_COLLECTIONS.includes(item.value));
export const filterRefinements = items => items.filter(item => !NON_STANDARDIZED_COLLECTIONS.includes(item.label));
export const normalizeCollectionRefinement = (
	activeCollections: Record<string, Omit<NormalizedCollection, 'products'>>,
	label: string
) => {
	if (PRODUCT_TYPE_NAMES[label]) {
		return PRODUCT_TYPE_NAMES[label];
	}
	if (activeCollections[label]) {
		return activeCollections[label].title;
	} else {
		return label.replace(/TOP_FRAME__/g, '');
	}
};

export function getAttributeRefinements(attribute: string, items: CurrentRefinementsRenderState['items']) {
	const item = items.find(item => item.attribute === attribute);
	return item?.refinements || [];
}

/**
 * transformItems
 *
 * @description Filters Algolia refinement items to remove non-standardized collections
 * @param items
 * @returns
 */
export const transformItems = (items: Array<RefinementItem>): Array<RefinementItem> => {
	return items.filter(item => !NON_STANDARDIZED_COLLECTIONS.includes(item.label));
};

/**
 * normalizeRefinementItems
 *
 * @description Normalizes Algolia refinement items for use in refinement option components: <RefinementSwatch /> and <RefinementOption />
 */
export const normalizeRefinementItems = ({
	type,
	collections,
	items,
	isSunglasses = false,
	isHideNewProducts,
}: NormalizeRefinementItemsArgs) => {
	const itemsMap = new Map();
	items.forEach(item => {
		const { label, value, isRefined } = item;
		let formattedLabel = '';
		if (type === 'color' || type === 'design') {
			formattedLabel =
				isHideNewProducts && NEW_COLORS.includes(label.replace(/\["|"\]+/g, '') as FRAME_COLORS)
					? ''
					: label.replace(/\["|"\]+/g, '');
		} else if (type === 'collections') {
			formattedLabel = collections && normalizeLabel({ collections, label, items });
			if (isSunglasses && label.includes('SUNTOP')) return;
		} else if (type === 'product_type') {
			formattedLabel = normalizeProductType(label, items);
		} else if (type === 'frame_shape') {
			formattedLabel = FRAME_SHAPES[label] || '';
		} else if (type === 'frame_size') {
			formattedLabel = FRAME_SIZES[label] || '';
		} else if (type === 'frame_material') {
			formattedLabel = FRAME_MATERIALS[label] || '';
		}
		itemsMap.set(formattedLabel, { label, value, isRefined });
	});
	return itemsMap;
};

export const normalizeLabel = ({ collections, label, items }) => {
	const hasProductType = items.some(
		item => (item.label.includes(PRODUCT_TYPES.ACCESSORY) || item.label.includes(PRODUCT_TYPES.BASE_FRAME)) && item.isRefined
	);
	if (collections[label] && !hasProductType) {
		return collections[label].title;
	}
};

const normalizeProductType = (label: string, items: Array<RefinementItem>) => {
	if (label === 'frame' || label === 'TOP_FRAME_BUNDLE' || label === 'TOP_FRAME__SUNTOP') {
		return PRODUCT_TYPE_NAMES[label];
	}
	const hasTopFrames = items.some(item => item.label.includes(PRODUCT_TYPES.TOP_FRAME) && item.isRefined);
	const keys = Object.values(PRODUCT_TYPES) as string[];
	if (keys.includes(label) && !hasTopFrames) {
		return PRODUCT_TYPE_NAMES[label];
	}
};

export const validateQueryBaseFrame = (query: string) => {
	return new RegExp([BASE_FRAME_NAMES, ...BASE_FRAME_NAMES.map(name => name.toLowerCase())].join('|')).test(query);
};

export const PRICE_RANGES = [
	{ min: 20, max: 25 },
	{ min: 25, max: 30 },
	{ min: 30, max: 35 },
	{ min: 35, max: undefined },
];

export const FRAME_SHAPES = {
	'Cat-Eye': 'Cat-eye',
	'Round': 'Round',
	'Rectangular': 'Rectangular',
};

const FRAME_SIZES = {
	extra_narrow: 'Extra Narrow',
	narrow: 'Narrow',
	medium: 'Medium',
	wide: 'Wide',
	extra_wide: 'Extra Wide',
};

export const FRAME_MATERIALS = {
	Metal: 'Metal',
	Acetate: 'Acetate',
};

export const FRAME_SIZE_ORDER = ['Extra Narrow', 'Narrow', 'Medium', 'Wide', 'Extra Wide'];
