import React, { useCallback } from 'react';
import { useRouter } from 'next/router';
import variables from '@styles/export.module.scss';
import { Flex, Lozenge, Paragraph, VipPromoModal } from '@components';
import { formatCurrency } from '@utils/shopify';
import { LOCALE_CODES } from '@constants';
import { capitalizeEachWord } from '@utils/strings';
import { BadgeTypes } from '@ts/index';
import { useTranslation } from '@utils/index';

type CompareAtPriceProps = {
	price: { amount: number; currencyCode: string };
	compareAtPrice: { amount: number; currencyCode: string } | null;
	useLabel?: boolean;
	tag?: BadgeTypes & { extraClasses: string };
};

const getTranslatedTexts = translator => {
	return {
		startingAt: translator('starting-at'),
	};
};

const CompareAtPrice = ({ price, compareAtPrice, useLabel = false, tag }: CompareAtPriceProps) => {
	const { locale } = useRouter();
	const showCurr = locale === LOCALE_CODES.AU || locale === LOCALE_CODES.CA;
	const { translator } = useTranslation();
	const translations = getTranslatedTexts(translator);

	const PriceTag = useCallback(() => {
		const tagComponent = (
			<Lozenge key={`${tag.name}`} {...tag} shape='square'>
				{capitalizeEachWord(tag.displayText)}
			</Lozenge>
		);
		return tag.name === 'vip-promo' ? <VipPromoModal>{tagComponent}</VipPromoModal> : tagComponent;
	}, [tag]);

	return (
		<Flex gap={2} align='center'>
			<Paragraph style={{ fontSize: '1.6rem' }}>
				{useLabel && <span style={{ color: variables.gray4, fontSize: '1.6rem' }}>{translations.startingAt}</span>}
				<span style={{ color: compareAtPrice ? variables.red1 : variables.gray4 }}>
					{formatCurrency(
						{
							...price,
							locale,
						},
						showCurr
					)}
				</span>
			</Paragraph>
			{compareAtPrice && (
				<Paragraph style={{ fontSize: '1.6rem', color: variables.gray3, textDecoration: 'line-through' }}>
					{`${formatCurrency({ ...compareAtPrice, locale }, showCurr)}`}
				</Paragraph>
			)}
			{tag && <PriceTag />}
		</Flex>
	);
};

export default CompareAtPrice;
