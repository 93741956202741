import Link from 'next/link';
import cn from 'classnames';
import { useRouter } from 'next/router';
import {
	Button,
	ContentfulRichTextRenderer,
	FacebookIcon,
	Heading,
	InstagramIcon,
	PerryLogo,
	TikTokIcon,
	YouTubeIcon,
} from '@components';
import { LOCALE_CODES } from '@constants';
import styles from './Footer.module.scss';
import { useTranslation } from '@utils/index';

const getTranslatedTexts = translator => {
	return {
		allBestThingsPairs: translator('all-best-things-pairs'),
		termsOfService: translator('terms-of-service'),
		privacyPolicy: translator('privacy-policy'),
		privacyWebForm: translator('privacy-web-form'),
		pairEyewear24: translator('pair-eyewear-24'),
	};
};

const Footer = ({ footer, page }) => {
	const { locale } = useRouter();
	const showReferAFriend = locale === LOCALE_CODES.US || locale === LOCALE_CODES.CA;
	const { translator } = useTranslation();
	const translations = getTranslatedTexts(translator);

	const menuGroups = footer?.menuGroups
		? footer.menuGroups.map(({ handle, text, subMenuGroup }) => (
				<li className={styles['footer__nav-item']} key={handle}>
					<Heading tag='h6'>{text}</Heading>
					<ul className={styles['footer__nav-submenu']}>
						{subMenuGroup.map(sub => {
							const isReferAFriend = sub.text === 'Refer a Friend' || sub.text === 'Rewards';
							return (
								((showReferAFriend && isReferAFriend) || !isReferAFriend) && (
									<li key={`help-section-sub-item-${sub.text}}`}>
										<Link href={sub.link}>{sub.text}</Link>
									</li>
								)
							);
						})}
					</ul>
				</li>
			))
		: null;

	return (
		<div className={styles.container} data-page={page}>
			<footer role='contentinfo' className={styles['footer']} data-page={page} data-testid={'footer'}>
				<ul className={styles['footer__nav']}>{menuGroups}</ul>

				<div className={styles['footer__social']}>
					<Heading tag='h6' style={{ textAlign: 'left' }}>
						{translations.allBestThingsPairs}
					</Heading>

					<ul className={styles['footer__social-list']}>
						<li>
							<a href='https://www.facebook.com/paireyewear/'>
								<FacebookIcon />
							</a>
						</li>
						<li>
							<a href='https://www.tiktok.com/@paireyewear/'>{<TikTokIcon />}</a>
						</li>
						<li>
							<a href='https://www.instagram.com/paireyewear/'>
								<InstagramIcon />
							</a>
						</li>
						<li>
							<a href='https://www.youtube.com/channel/UCsfEDry8WF9MRqYaCkQRipw'>
								<YouTubeIcon />
							</a>
						</li>
					</ul>
				</div>

				<ul className={styles['footer__service']}>
					<li className={styles['footer__service-item']}>
						<Link href='/terms-of-service'>{translations.termsOfService}</Link>
					</li>
					<li className={styles['footer__service-item']}>
						<Link href='/privacy-policy'>{translations.privacyPolicy}</Link>
					</li>
					<li className={styles['footer__service-item']}>
						<Link href='/privacy-webform'>{translations.privacyWebForm}</Link>
					</li>
					<li className={cn(styles['footer__service-item'], styles['footer__copyright'])}>
						<span>&copy; {translations.pairEyewear24}</span>
					</li>
				</ul>

				<div className={styles['footer__logo']}>
					<PerryLogo />
				</div>

				<div className={styles['footer__bottom-section']}>
					{footer?.hasOwnProperty('contacts') && (
						<div className={styles['footer__contact']}>
							{footer.contacts.map((sub, index) => {
								return (
									<>
										<Button key={sub.text} href={sub.link} linkStyle size='small'>
											{sub.text}
										</Button>
										{index < footer.contacts.length - 1 && <div className={styles.line} />}
									</>
								);
							})}
						</div>
					)}

					{footer?.hasOwnProperty('disclaimer') && (
						<div className={styles['footer__disclaimer']}>
							{ContentfulRichTextRenderer(false, { text: footer.disclaimer, maxWidth: '1024' })}
						</div>
					)}
				</div>
			</footer>
		</div>
	);
};

Footer.displayName = 'Footer';

export default Footer;
