import React, { ReactNode, useState } from 'react';
import cn from 'classnames';
import * as PopoverPrimitive from '@radix-ui/react-popover';
import { Paragraph, TooltipIcon } from '@components';
import styles from './Popover.module.scss';
import { useTranslation } from '@utils/index';

type PopoverProps = {
	message: string | ReactNode;
	children?: ReactNode;
	extraClasses?: string;
	shouldHover?: boolean;
};

const getTranslatedTexts = translator => {
	return {
		tooltipIcon: translator('tooltip-icon'),
	};
};

const Popover = ({ message, children, extraClasses = '', shouldHover }: PopoverProps) => {
	const [open, setOpen] = useState(false);
	const classes = cn(styles.message, extraClasses);
	const { translator } = useTranslation();
	const translations = getTranslatedTexts(translator);

	const handleMouseEnter = () => {
		shouldHover && setOpen(true);
	};

	const handleMouseLeave = () => {
		shouldHover && setOpen(false);
	};

	return (
		<PopoverPrimitive.Root open={open} onOpenChange={setOpen}>
			<PopoverPrimitive.Trigger asChild>
				<button
					onClick={e => {
						e.preventDefault();
						e.stopPropagation();
						setOpen(!open);
					}}
					onMouseEnter={handleMouseEnter}
				>
					{children ?? <TooltipIcon label={translations.tooltipIcon} />}
				</button>
			</PopoverPrimitive.Trigger>
			<PopoverPrimitive.Portal>
				<PopoverPrimitive.Content
					className={styles.content}
					side='bottom'
					sideOffset={8}
					collisionPadding={16}
					onClick={e => {
						e.stopPropagation();
					}}
					onMouseLeave={handleMouseLeave}
				>
					<div className={classes}>
						<Paragraph>{message}</Paragraph>
					</div>
				</PopoverPrimitive.Content>
			</PopoverPrimitive.Portal>
		</PopoverPrimitive.Root>
	);
};

export default Popover;
