import { MutableRefObject, useCallback, useEffect, useMemo, useRef } from 'react';
import { useRouter } from 'next/router';
import { Configure, useInstantSearch, useQueryRules, useSearchBox } from 'react-instantsearch';
import { AutocompleteApi, BaseItem } from '@algolia/autocomplete-core';
import { FRAME_COLORS, LOCALE_CODES, LOCALE_DICT } from '@constants';
import {
	AutoComplete,
	AutoCompleteDropdown,
	Body,
	Button,
	Drawer,
	Flex,
	Heading,
	Hits,
	MinicartTrigger,
	NoResults,
	PopularSearches,
	SearchInput,
} from '@components';
import { useActiveFrame } from '@utils/hooks';
import { ALGOLIA_SEARCH_PARAMS, validateQueryBaseFrame } from '@utils/algolia';
import useAutocomplete from '@services/algolia/hooks/useAutocomplete';
import { useFilterContext } from '@context';
import { getQueryParamValue } from '@utils/urls';
import { AutocompleteStateProps } from '@ts/algolia';
import styles from './SearchResults.module.scss';

type SearchResultsProps = {
	hitsPerPage: number;
	isSearchPage?: boolean;
	groupHits?: boolean;
	openMinicartRef?: MutableRefObject<HTMLDivElement>;
};

const SearchResults = ({
	hitsPerPage = 50,
	isSearchPage = false,
	groupHits = false,
	openMinicartRef = null,
}: SearchResultsProps) => {
	const { frameShape } = useFilterContext() || {};
	const router = useRouter();
	const { indexUiState, setIndexUiState, results, status, refresh } = useInstantSearch();
	const { query, isSearchStalled } = useSearchBox();
	const [activeFrame] = useActiveFrame();
	const {
		autocomplete,
		autocompleteState,
	}: {
		autocomplete: AutocompleteApi<BaseItem, Event, MouseEvent, KeyboardEvent>;
		autocompleteState: AutocompleteStateProps;
	} = useAutocomplete(query);
	const { items } = useQueryRules();
	const redirectOption = items.find(data => Boolean(data.redirect));
	const inputRef = useRef<HTMLInputElement>();
	const closeDrawerRef = useRef();
	const { locale } = useRouter();
	const countryCode = LOCALE_DICT[locale].countryCode ?? 'US';
	const { collections, isOpen: isAutocompleteOpen } = autocompleteState;
	// ! For some reason, `indexUiState.query` becomes an object when the Results are wrapped in an SSR Provider.
	const indexQuery = indexUiState.query as unknown as { q: string };
	const searchQuery = typeof indexQuery === 'string' ? indexQuery : (indexQuery?.q ?? null);
	const hasSearchQueryHits = results?.nbHits > 0;
	const showNoResults = !!searchQuery && !hasSearchQueryHits && status !== 'loading' && !isSearchStalled && !redirectOption;

	const queryHook = useCallback((query, search) => {
		search(query);
	}, []);

	// Ensure that only Top Frame variants matching the `activeFrame` get displayed.
	// If the query contains a Base Frame name (e.g, "Wanda Sparkle"), display variants matching that size, and ignore the `activeFrame`

	const resultFilters = useCallback(
		query => {
			const options = 'options.frame';
			if (locale !== LOCALE_CODES.US) {
				return `(${Object.values(FRAME_COLORS).reduce(
					(a, c) => a + `variant_title: '${c} / Single Vision / None' OR `,
					''
				)}product_type: 'ACCESSORY'  ${
					validateQueryBaseFrame(query) ? ` OR option_names: frame` : ` OR ${options}: ${frameShape ?? activeFrame}`
				}) AND inventory_available: true`;
			}
			return `(${Object.values(FRAME_COLORS).reduce(
				(a, c) => a + `variant_title: '${c} / Single Vision / None' OR `,
				''
			)}product_type: 'ACCESSORY' OR product_type: 'Gift Card' ${
				validateQueryBaseFrame(query) ? ` OR option_names: frame` : ` OR ${options}: ${frameShape ?? activeFrame}`
			}) AND inventory_available: true`;
		},
		[activeFrame, frameShape]
	);

	const handlePopularSearchClick = useCallback(
		query => {
			if (!inputRef.current) return;
			autocomplete.setQuery(query);
		},
		[autocomplete]
	);

	const handleSearch = useCallback(
		newQuery => {
			setIndexUiState(prevUiState => {
				return {
					...prevUiState,
					query: newQuery,
				};
			});
			handlePopularSearchClick(newQuery);
		},
		[handlePopularSearchClick, setIndexUiState]
	);

	const memorizedConfigure = useMemo(() => {
		return <Configure clickAnalytics hitsPerPage={hitsPerPage} filters={resultFilters(searchQuery)} />;
	}, [hitsPerPage, resultFilters, searchQuery]);

	// Note: Handles edge case where user navigates to search page using SearchDrawer while on the search page
	useEffect(() => {
		const handleRouteChange = (url: string) => {
			if (isSearchPage && url.includes(ALGOLIA_SEARCH_PARAMS[countryCode].ALGOLIA_SEARCH_URL)) {
				const newQuery = getQueryParamValue(ALGOLIA_SEARCH_PARAMS[countryCode].ALGOLIA_SEARCH_PARAM, url);
				handleSearch(decodeURI(newQuery));
			} else return;
		};

		router.events.on('routeChangeComplete', handleRouteChange);

		return () => {
			router.events.off('routeChangeComplete', handleRouteChange);
		};
	}, [handleSearch, isSearchPage, router]);

	useEffect(() => {
		if (searchQuery) {
			refresh();
		}
	}, [refresh, searchQuery]);

	return (
		<>
			{memorizedConfigure}
			{isSearchPage ? (
				<>
					{hasSearchQueryHits && isSearchPage && (
						<Heading tag='h5' className={styles['heading']}>
							Search results for: <Body>{` "${searchQuery}" (${results?.nbHits})`}</Body>
						</Heading>
					)}
					<SearchInput queryHook={queryHook} hidden />
					<Hits groupHits={groupHits} visible={hasSearchQueryHits} />
				</>
			) : (
				<>
					<Flex justify='between' align='center' gap={1} className={styles['input-container']}>
						<AutoComplete
							autocomplete={autocomplete}
							autocompleteState={autocompleteState}
							queryHook={queryHook}
							ref={inputRef}
						/>
						<Drawer.Close asChild>
							<div style={{ display: 'none' }} ref={closeDrawerRef} />
						</Drawer.Close>
						<MinicartTrigger closeSearchRef={closeDrawerRef} openMinicartRef={openMinicartRef} />
					</Flex>

					<Flex
						column
						className={styles['suggestions']}
						data-has-results={hasSearchQueryHits || isAutocompleteOpen || !!redirectOption}
					>
						<Flex column gap={3} className={styles['suggestions__results']}>
							{(isAutocompleteOpen || !!redirectOption) && (
								<AutoCompleteDropdown
									autocomplete={autocomplete}
									collections={collections}
									redirectOption={redirectOption}
								/>
							)}
							<Hits groupHits={groupHits} visible={hasSearchQueryHits} />
						</Flex>
						{hasSearchQueryHits && (
							<div className={styles['suggestions__button']}>
								<Button
									href={`/${ALGOLIA_SEARCH_PARAMS[countryCode]?.ALGOLIA_SEARCH_URL}${searchQuery}`}
									onClick={() => handleSearch(searchQuery)}
									data-see-all-results={searchQuery}
								>
									See all results
								</Button>
							</div>
						)}
					</Flex>
				</>
			)}

			{showNoResults && <NoResults query={searchQuery} handleSearch={handleSearch} />}
			{!searchQuery && <PopularSearches handleSearch={handleSearch} />}
		</>
	);
};

export default SearchResults;
