import React, { useCallback, useMemo, useRef } from 'react';
import { A11y, Navigation, Pagination, Zoom } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import cn from 'classnames';
import { useQueryClient } from '@tanstack/react-query';
import { slideUp } from '@utils/motions';
import { DEFAULT_SWIPER_PROPS, Loading, NavButtons, Paragraph, Slide, Swipe } from '@components';
import { NormalizedVariant } from '@ts/product';
import { MODEL_LIFESTYLE_IMAGE } from '@constants';
import { useIsMobile } from '@utils/hooks';
import { useTranslation } from '@utils/index';
import Flex from '../Flex';
import Img from '../Img';
import Modal from '../Modal';
import carouselStyles from '../Carousel/Carousel.module.scss';
import styles from './CarouselModal.module.scss';

type CarouselModalProps = {
	variant: NormalizedVariant;
	children?: React.ReactNode;
	type?: string;
	enableLifeStyle?: boolean;
	bulletVariant?: 'default' | 'gray';
};
const getTranslatedTexts = translator => {
	return {
		swipeToViewMore: translator('swipe-to-view-more'),
	};
};
const CarouselModal = ({ variant, children, type, enableLifeStyle, bulletVariant = 'default' }: CarouselModalProps) => {
	const isMobile = useIsMobile();
	const queryClient = useQueryClient();
	const queryKey = `choose-tops-${variant.handle}`;

	const isTopFrameBundle = !!type?.includes('BUNDLE');
	const images = useMemo(() => {
		const lifestyleData = queryClient.getQueryData([queryKey]);
		return [
			{
				image: variant.image,
				name: variant.name,
			},
			enableLifeStyle && !isTopFrameBundle && lifestyleData
				? {
						image: {
							altText: "Model's Lifestyle",
							url: lifestyleData,
						},
						name: "Model's Lifestyle",
					}
				: null,
		].filter(Boolean);
	}, [
		queryClient,
		queryKey,
		variant.image,
		variant.name,
		enableLifeStyle,
		isTopFrameBundle,
		queryClient.getQueryData([queryKey]),
	]);

	const refs = {
		loading: useRef(null),
		modalSlider: useRef(null),
		modalNextEl: useRef(null),
		modalPrevEl: useRef(null),
	};

	const { translator } = useTranslation();
	const translations = getTranslatedTexts(translator);
	//TODO: change the design of the modal to match the new design
	const SwiperSlides = useCallback(() => {
		const slides =
			images?.map(({ image: { url, width = 1200, height = 600, altText }, name }, index) => {
				const isLifeStyle = !!altText?.includes('Lifestyle');
				const slideContent = (
					<Slide
						index={index}
						type={isLifeStyle ? 'LIFESTYLE' : type}
						name={name}
						primary={isLifeStyle && url}
						url={!isLifeStyle && url}
						height={height}
						width={width}
						key={`image-slide-${index}`}
						secondary={isLifeStyle && MODEL_LIFESTYLE_IMAGE}
						isModalOpen
					/>
				);
				return (
					<SwiperSlide
						tag='li'
						key={`image-slide-${index}`}
						data-swiper-slide-index={index}
						className={cn(carouselStyles['swiper-slide'], carouselStyles['buildflow-images--with-5-imgs'])}
					>
						{slideContent}
					</SwiperSlide>
				);
			}) || [];
		return slides;
	}, [images, variant, enableLifeStyle, type, queryClient.getQueryData([queryKey])]);

	const slideActions: Record<string, () => void> = {
		next: () => {
			refs.modalSlider.current.swiper.slideNext();
		},
		prev: () => {
			refs.modalSlider.current.swiper.slidePrev();
		},
	};

	const SwiperContent = () => (
		<Swiper
			{...DEFAULT_SWIPER_PROPS}
			ref={refs.modalSlider}
			autoHeight
			navigation={{
				nextEl: refs.modalNextEl.current,
				prevEl: refs.modalPrevEl.current,
			}}
			modules={[A11y, Navigation, Pagination, Zoom]}
			loop
			className={carouselStyles['container']}
			pagination={{
				...DEFAULT_SWIPER_PROPS.pagination,
				bulletClass: cn(carouselStyles['swiper-pagination-bullet'], {
					[carouselStyles['swiper-pagination-bullet-gray']]: bulletVariant === 'gray',
				}),
			}}
		>
			{SwiperSlides()}
			<div className={cn('swiper-pagination', carouselStyles['swiper-pagination'])} />
			{!isTopFrameBundle && (
				<NavButtons
					images={images.map(({ image }) => image)}
					type={type}
					prevElRef={refs.modalPrevEl}
					nextElRef={refs.modalNextEl}
					handleNavigation={action => {
						if (!refs.modalSlider.current) return;
						slideActions[action]();
					}}
				/>
			)}
		</Swiper>
	);

	return (
		<Modal>
			{children}
			<Modal.Content
				className={!enableLifeStyle ? styles.modal : carouselStyles.modalContent}
				customAnimation={slideUp}
				includeCloseButton={isMobile}
				removePadding={enableLifeStyle}
			>
				{!enableLifeStyle && (
					<div className={styles.imageContainer}>
						{images.map(({ image }) => (
							<Img
								key={image.url}
								className={styles.image}
								src={image.url}
								alt={image.altText}
								width={image.width ?? 800}
								height={image.height ?? 350}
							/>
						))}
						<Flex align='center' justify='center' className={`${styles.bottomMessage}`}>
							<Swipe />
							<Paragraph>{translations.swipeToViewMore}</Paragraph>
						</Flex>
					</div>
				)}
				{enableLifeStyle && (!queryClient.isFetching([queryKey]) ? <SwiperContent /> : <Loading />)}
			</Modal.Content>
		</Modal>
	);
};

export default CarouselModal;
