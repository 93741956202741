import { NormalizedVariant } from '@ts/product';
import { LOCALE_DICT } from '@constants';
import fetchStorefrontApi from '../fetch-storefront-api';
import { productsPriceQuery } from '../queries';

export async function getProductVariantsPrice(productIds: string[], locale: string): Promise<NormalizedVariant[]> {
	const { countryCode: country, languageCode: language } = LOCALE_DICT[locale];
	const products = await fetchStorefrontApi(productsPriceQuery, {
		variables: {
			ids: productIds,
			country,
			language,
		},
	});
	return products.nodes;
}
