import { useCallback, useEffect, useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import { useProductQuery } from '@services/shopify';
import { useCartContext } from '@context';
import { formatCurrency, useIsVipMembership } from '@utils/index';

export default function useMembership(subtotalWithDiscount: number, subtotal: number) {
	const { locale } = useRouter();
	const { handleCartAdd, handleCartRemove } = useCartContext();
	const { data: vipMembership } = useProductQuery('pair-membership', {}, { queryRefreshVars: [locale], locale });
	const { hasVipMembershipInCart, membershipIdCartLine } = useIsVipMembership();
	const [option, setOption] = useState(hasVipMembershipInCart ? 'vip' : 'oneTime');

	const { formattedSubtotal, formattedSubtotalWithDiscount } = useMemo(() => {
		const formattedSubtotal = formatCurrency({
			amount: subtotal,
			minDigits: 2,
			maxDigits: 2,
			currencyCode: 'USD',
			locale,
		});
		const formattedSubtotalWithDiscount = formatCurrency({
			amount: subtotalWithDiscount,
			minDigits: 2,
			maxDigits: 2,
			currencyCode: 'USD',
			locale,
		});
		return { formattedSubtotal, formattedSubtotalWithDiscount };
	}, [subtotal, locale, subtotalWithDiscount]);

	useEffect(() => {
		setOption(hasVipMembershipInCart ? 'vip' : 'oneTime');
	}, [hasVipMembershipInCart]);

	const handleOnClick = useCallback(
		(value: string) => {
			switch (value) {
				case 'vip':
					if (membershipIdCartLine) return;
					handleCartAdd(
						[
							{
								variant: vipMembership?.variants[0],
								customAttributes: [],
								sellingPlanId: vipMembership?.sellingPlans[0].id,
							},
						],
						false
					);
					setOption(value);
					break;
				case 'oneTime':
					if (!membershipIdCartLine) return;
					handleCartRemove([membershipIdCartLine]);
					setOption(value);
					break;
			}
		},
		[membershipIdCartLine, vipMembership]
	);

	return {
		handleOnClick,
		option,
		formattedSubtotal,
		formattedSubtotalWithDiscount,
	};
}
