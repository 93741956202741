import { useState } from 'react';
import cn from 'classnames';
import { Button, Flex, Paragraph, PdToolModal, RadioGroup, Select, Tooltip } from '@components';
import { useCartContext } from '@context';
import { useLocalStorage, useTranslation } from '@utils/hooks';
import styles from './CartPD.module.scss';

type CartPdProps = {
	callback: (pd: number, method: string) => void;
	pdMeasurement?: string;
};

const getTranslatedTexts = translator => {
	return {
		measure: translator('measure'),
		pdQuestion: translator('pd-question'),
		pupilsDistanceMessage: translator('pupils-distance-message'),
		pdValue: translator('pd-value'),
		measurementToolMessage: translator('measurement-tool-message'),
		useWomenDefault: translator('use-women-default'),
		useMenDefault: translator('use-men-default'),
	};
};

const CartPD = ({ callback, pdMeasurement }: CartPdProps) => {
	const [hasUsedPDInCart, setHasUsedPDInCart] = useLocalStorage('hasUsedPDInCart', null);
	const [pDModalOpen, setPDModalOpen] = useState(false);
	const [pdToolInit, setPdToolInit] = useState(false);
	const [pdToolHasError, setPdToolHasError] = useState(false);
	const [hasScriptLoaded, setHasScriptLoaded] = useState(false);
	const [selectedDefault, setSelectedDefault] = useState<'men' | 'women'>();
	const { isCartMutating, isUploadingRx } = useCartContext();
	const { translator } = useTranslation();
	const translations = getTranslatedTexts(translator);
	const isCartDisabled = isCartMutating || isUploadingRx;
	const pdValues = Array.from({ length: (75 - 45) / 0.5 + 1 }, (_, i) => {
		const num = 45 + i * 0.5;
		return Number.isInteger(num) ? num.toString() : num.toFixed(1);
	});
	const shouldDisable = !(pdToolInit || pdToolHasError) || hasUsedPDInCart;
	const buttonClasses = cn(styles['measure-button'], {
		[styles['disabled']]: shouldDisable,
	});

	const measureButton = (
		<Button
			data-cart-pd-measure-btn
			extraClasses={buttonClasses}
			disabled={shouldDisable}
			color='blue'
			onClick={() => setPDModalOpen(true)}
		>
			{translations.measure}
		</Button>
	);

	return (
		<Flex className={styles.container} column gap={3}>
			<Flex gap={2}>
				<Paragraph className={styles.bundleTitle}>{translations.pdQuestion}</Paragraph>
				<Tooltip message={translations.pupilsDistanceMessage} />
			</Flex>
			<Flex className={styles.form} gap={3}>
				<Select
					data-cart-pd-select
					shouldUseRefresh
					className={styles.select}
					withChevron={!pdMeasurement}
					buttonClassName={cn(styles['select-button'], { [styles['placeholder']]: !pdMeasurement })}
					placeholder={pdMeasurement ?? translations.pdValue}
					prevSelection={pdMeasurement}
					values={pdValues}
					scrollTo={pdMeasurement ?? '61'}
					buttonProps={{
						withClose: !!pdMeasurement,
						disabled: isCartDisabled,
						onClose: () => {
							callback(null, null);
							setSelectedDefault(null);
						},
					}}
					handler={v => {
						callback(Number(v), 'picker');
					}}
				/>
				{hasUsedPDInCart ? (
					<Tooltip message={translations.measurementToolMessage}>{measureButton}</Tooltip>
				) : (
					measureButton
				)}
				<PdToolModal
					open={pDModalOpen}
					setOpen={setPDModalOpen}
					setPdToolInit={setPdToolInit}
					setPdToolHasError={setPdToolHasError}
					hasScriptLoaded={hasScriptLoaded}
					onScriptLoaded={() => setHasScriptLoaded(true)}
					pdValueHandler={(pd: number) => {
						setHasUsedPDInCart(true);
						setPDModalOpen(false);
						callback(pd, 'pdTool');
					}}
					strategy='onReady'
				/>
			</Flex>
			<RadioGroup className={styles.defaults} value={selectedDefault}>
				<RadioGroup.Option
					data-cart-pd-men-radio-btn
					className={styles['default-option']}
					option='men'
					callback={() => {
						setSelectedDefault('men');
						callback(64, 'menDefault');
					}}
				>
					{translations.useMenDefault}
				</RadioGroup.Option>
				<RadioGroup.Option
					data-cart-pd-women-radio-btn
					className={styles['default-option']}
					option='women'
					callback={() => {
						setSelectedDefault('women');
						callback(62, 'womenDefault');
					}}
				>
					{translations.useWomenDefault}
				</RadioGroup.Option>
			</RadioGroup>
		</Flex>
	);
};

export default CartPD;
