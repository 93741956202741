import { type FC, useEffect } from 'react';
import { useRouter } from 'next/router';
import Script from 'next/script';
import { SEO } from '@components';
import { LOCALE_CODES } from '@constants';

const Head: FC = () => {
	const { asPath, locale, locales, defaultLocale } = useRouter();
	const cleanPath = asPath.split('#')[0].split('?')[0];
	const baseUrl = (process.env.NEXT_PUBLIC_STORE_URL || process.env.NEXT_PUBLIC_VERCEL_URL).slice(0, -1);
	const canonicalUrl = `${baseUrl}${defaultLocale !== locale ? `/${locale}` : ''}${asPath === '/' ? '' : cleanPath}`;

	useEffect(() => {
		const found = document.querySelectorAll('.y-rich-snippet-script');
		found.forEach(el => el.remove());
	}, []);

	return (
		<>
			<SEO>
				{/* Note: This code is supplied as children to prevent duplication */}
				<meta key='viewport' name='viewport' content='width=device-width, initial-scale=1' />
				<meta charSet='utf-8' />
				<meta content='f182b2c8fc67b325571f7a9e299beb93' name='p:domain_verify' />
				<meta content='IE=edge,chrome=1' httpEquiv='X-UA-Compatible' />
				<meta name='theme-color' content='#080593' />
				<link rel='manifest' href='/site.webmanifest' key='site-manifest' />
				<link rel='apple-touch-icon' sizes='180x180' href='/apple-touch-icon.png' />
				<link rel='icon' type='image/png' sizes='32x32' href='/favicon-32x32.png' />
				<link rel='icon' type='image/png' sizes='16x16' href='/favicon-16x16.png' />
				<link rel='manifest' href='/site.webmanifest' />
				<link rel='mask-icon' href='/safari-pinned-tab.svg' color='#000000' />
				<meta name='msapplication-TileColor' content='#ffffff' />
				<meta name='prerender-status-code' content='200' />
				<meta name='prerender' content='true' />

				{/* Canonical + HREFLang Alternate URLs */}
				<link rel='canonical' href={canonicalUrl} />
				{locales.map(locale => (
					<link
						key={`hreflang-${locale}`}
						data-testid={`hreflang-${locale}`}
						rel='alternate'
						hrefLang={locale}
						href={`${baseUrl}/${locale}${cleanPath}`}
					/>
				))}
			</SEO>
			{locale !== LOCALE_CODES.CA && (
				<Script
					async
					data-environment='production'
					src='https://js.klarna.com/web-sdk/v1/klarna.js'
					data-client-id='8336baf9-c451-581b-9685-1ce36517764a'
				></Script>
			)}
		</>
	);
};

export default Head;
