import { getBadgeTypes } from '@constants';
import Lozenge from '../Lozenge';
import { useRouter } from 'next/router';

const Tags = ({ tags }: { tags: string[] }) => {
	const { locale } = useRouter();
	const BADGE_TYPES = getBadgeTypes(locale);

	return tags?.includes(BADGE_TYPES.BEST_SELLER.name) ? (
		<Lozenge
			backgroundColor={BADGE_TYPES.BEST_SELLER.backgroundColor}
			color={BADGE_TYPES.BEST_SELLER.color}
			shape='square'
			alignment='left'
		>
			{BADGE_TYPES.BEST_SELLER.displayText}
		</Lozenge>
	) : null;
};

export default Tags;
