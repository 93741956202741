import { useQuery, useQueryClient, UseQueryResult } from '@tanstack/react-query';
import { useRouter } from 'next/router';
import Cookies from 'js-cookie';
import { NormalizedCart } from '@ts/cart';
import { normalizeCart } from '@utils/normalizers/normalize-cart';
import { cartQuery, fetchStorefrontApi, setCartId, useCartId, useCustomer } from '@services/shopify/index';
import { QueryRoot } from '@ts/shopify-storefront-api';
import { LOCALE_DICT, REGEX_CART_ID } from '@constants';

const useCart = (): UseQueryResult<NormalizedCart> => {
	const queryClient = useQueryClient();
	const Customer = useCustomer();
	const { data: cartId } = useCartId();
	const { locale } = useRouter();
	const cart = useQuery<NormalizedCart>(
		['cart', { cartId }],
		async () => {
			if (!cartId) return null;
			const match = cartId.match(REGEX_CART_ID);
			const cartToken = match[1];
			const key = match[2];

			const cartCookie = Cookies.get('cart');

			if (!cartCookie?.includes(cartToken)) {
				const encondeCookie = encodeURIComponent(`${cartToken}?key=${key}`);
				document.cookie = `cart=${encondeCookie}; domain=.paireyewear.com; path=/;`;
			}

			const { countryCode: country, languageCode: language } = LOCALE_DICT[locale];
			const { cart }: QueryRoot = await fetchStorefrontApi(cartQuery, {
				variables: { id: cartId, country, language },
			});
			const normalizedCart = normalizeCart(cart, locale);
			queryClient.setQueryData(['cart', { cartId }], normalizedCart);
			return normalizedCart;
		},
		{
			onError(error) {
				console.error(`Error fetching cart ${cartId}: ${error}`);
				setCartId(null);
				queryClient.invalidateQueries(['cart', 'id']);
			},
			placeholderData: null,
			staleTime: Infinity,
			refetchOnWindowFocus: false,
			enabled: Boolean(cartId) && Customer.fetchStatus === 'idle',
			retry: false,
		}
	);

	return cart;
};

export { useCart };
