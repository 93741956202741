import React from 'react';
import { useRouter } from 'next/router';
import { getLensOptions, LOCALE_CODES } from '@constants';
import { Flex, Lozenge, Paragraph, Title, Tooltip, TypographyButton } from '@components';
import { formatCurrency } from '@utils/shopify';
import { BASE_FRAME_LENS_OPTIONS, RX_TYPE } from '@utils/constants/base-skus';

import variables from '@styles/export.module.scss';
import styles from './EditabilityLensBody.module.scss';
import { useTranslation } from '@utils/index';

const getTranslatedTexts = translator => {
	return {
		editLensSelection: translator('edit-lense-selection'),
		premiumUltraThinCopy: translator('premium-ultra-thin-copy'),
		orderedPreviously: translator('ordered-previously'),
		included: translator('included'),
	};
};

const EditabilityLensBody = ({
	name,
	price,
	copy,
	tag = null,
	tooltip = false,
	preSelectedTooltip = false,
	usePlusSign = false,
	hidePrice = false,
	selectedLenses = [],
	isPreviouslyOrdered = false,
}) => {
	const { locale } = useRouter();
	const showCurr = locale === LOCALE_CODES.AU || locale === LOCALE_CODES.CA;
	const isLightResponsiveSelected = selectedLenses?.includes(BASE_FRAME_LENS_OPTIONS.LIGHT_RESPONSIVE);
	const isBlueLightOption = name.includes('Blue-Light');
	const showIncludedTag = isLightResponsiveSelected && isBlueLightOption;
	const isPremiumPlusOption = name === BASE_FRAME_LENS_OPTIONS.PREMIUM_PLUS;
	const { translator } = useTranslation();
	const translations = getTranslatedTexts(translator);
	const LENS_OPTIONS = getLensOptions(locale);

	const testingTitle = locale === LOCALE_CODES.GB && name === RX_TYPE.PROGRESSIVE ? LENS_OPTIONS.varifocal : name;

	return (
		<Flex column fullWidth>
			<Flex style={{ width: '100%' }} justify='between' align='start'>
				<Flex column gap={2}>
					<Title>{isPremiumPlusOption ? LENS_OPTIONS.premium : testingTitle}</Title>
					<Flex align='center' gap={2} position={'relative'} fullWidth>
						{isPreviouslyOrdered && (
							<Lozenge
								text={translations.orderedPreviously}
								shape='square'
								color={variables.gray1}
								backgroundColor={variables.blue1}
							/>
						)}
						{tag && (
							<Lozenge
								text={showIncludedTag ? translations.included : tag}
								shape='square'
								backgroundColor={showIncludedTag ? variables.greenLight : variables.gray2}
								color={showIncludedTag ? variables.green2 : variables.gray4}
							/>
						)}
					</Flex>
				</Flex>
				<Flex style={{ height: 'fit-content', flexShrink: '0' }} align='center' gap={3}>
					{tooltip && name === 'Premium Plus' && <Tooltip message={translations.premiumUltraThinCopy} />}
					{preSelectedTooltip && <Tooltip message={translations.editLensSelection} />}
					{!hidePrice && (
						<TypographyButton small className={styles.price}>
							{usePlusSign ? '+' : ''}
							{!!price && formatCurrency(price, showCurr)}
						</TypographyButton>
					)}
				</Flex>
			</Flex>
			<Paragraph style={{ marginTop: '0.6rem' }}>{copy}</Paragraph>
		</Flex>
	);
};

export default EditabilityLensBody;
