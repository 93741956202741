import variables from '@styles/export.module.scss';
import { InformationIcon, Popover } from '@components';
import { Caption, StyledWordEmphasizer } from '@components/typography';
import { FAQ_URL } from '@constants';
import { useTranslation } from '@utils/hooks';
import styles from './PaireCareTooltip.module.scss';

const getTranslatedTexts = translator => {
	return {
		pairCareCoverage: (faq: string) => translator('pair-care-coverage-text', { faq }),
		faq: translator('pair-care-coverage-text-highlighted-word'),
	};
};

const PairCareToolTipContent = () => {
	const { translator } = useTranslation();
	const translations = getTranslatedTexts(translator);

	return (
		<Caption>
			<StyledWordEmphasizer
				text={translations.pairCareCoverage(translations.faq)}
				emphasizedWords={translations.faq}
				styledTexts={
					<a
						className='blue-text'
						target='_blank'
						style={{
							color: variables.blue2,
							textDecoration: 'underline',
						}}
						href={`${FAQ_URL}/articles/23239596609943-Warranty`}
					>
						{translations.faq}
					</a>
				}
			/>
		</Caption>
	);
};
export default function PairCareTooltip({ iconType = 'question' }: { iconType?: 'information' | 'question' }) {
	return (
		<Popover message={<PairCareToolTipContent />} extraClasses={styles.pairCarePopover} shouldHover={true}>
			{iconType === 'information' ? <InformationIcon width={12} color={variables.gray4} /> : null}
		</Popover>
	);
}
