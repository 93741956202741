import { productFieldsFragment, productMetafieldsFragment } from '../fragments/product';

const productRecommendationsQuery = /* GraphQL */ `
	query productRecommendations(
		$productId: ID!
		$includeDescription: Boolean = true
		$includeSpecificFrameVariant: Boolean = false
		$skipCollections: Boolean = false
		$skipImages: Boolean = false
		$skipVariants: Boolean = false
		$selectedOptions: [SelectedOptionInput!]!
		$country: CountryCode = US
		$maxVariants: Int = 25
	) @inContext(country: $country) {
		productRecommendations(productId: $productId) {
			...productFields
			...productMetafields
		}
	}

	${productFieldsFragment}
	${productMetafieldsFragment}
`;

export default productRecommendationsQuery;
