import { CART_LINE_ATTRIBUTE_KEYS } from '@utils/constants/cart';
import { Bundle, NormalizedCart, NormalizedCartLine, PurchaseChecklist } from '@ts/cart';
import { NormalizedProduct } from '@ts/index';
import { AttributeInput } from '@ts/shopify-storefront-api';
import { SUBMISSION_METHODS } from './constants';
import { CUSTOMER_JOURNEYS, FRAME_COLOR_HANDLES, LOCALE_CODES, LOCALE_DICT, PRODUCT_TYPES, translate } from '.';

function calculateCartQuantity(lines: Array<NormalizedCartLine>) {
	return lines.reduce((total, line) => total + line.quantity, 0);
}

type CustomAttributeKey = (typeof CART_LINE_ATTRIBUTE_KEYS)[keyof typeof CART_LINE_ATTRIBUTE_KEYS];

type CustomAttribute = {
	key: CustomAttributeKey;
	value: string;
};

const calculateOptimisticSubtotal = (oldData: NormalizedCart, newLines: NormalizedCartLine[]) => {
	if (!oldData.totalQuantity) {
		return newLines.reduce((res, cur) => res + cur.variant.price.amount, 0);
	}

	return newLines.reduce(
		(res, cur) => res + (cur.quantity > 1 ? cur.variant.price.amount * cur.quantity : cur.variant.price.amount),
		0
	);
};

const computeBundleSubtotal = (bundle: Bundle) => {
	const {
		base: { frame },
	} = bundle;
	const baseFramePrice = frame.variant.price.amount;

	return baseFramePrice;
};

const determinePairCarePlan = (bundle: Bundle, pairCare: NormalizedProduct) => {
	const bundleSubtotal = computeBundleSubtotal(bundle);
	return pairCare.variants.find(variant => {
		return (
			parseFloat(variant.metafields.minThreshold) <= bundleSubtotal &&
			parseFloat(variant.metafields.maxThreshold) >= bundleSubtotal
		);
	});
};

const getCollectionPathFromProperties = (properties: { [k: string]: string }) => {
	const customerJourney = properties._customerJourney;
	const customerType = properties._customerType;

	if (!customerJourney || !customerType) {
		return '';
	}

	return `/${customerJourney}/${customerType}/`;
};

const getCollectionPathFromCustomAttributes = (customAttributes: Array<CustomAttribute>) => {
	const customerJourney = customAttributes.find(attr => attr.key === '_customerJourney')?.value;
	const customerType = customAttributes.find(attr => attr.key === '_customerType')?.value;

	if (!customerJourney || !customerType) {
		return '';
	}

	return `/${customerJourney}/${customerType}/`;
};

/**
 * Returns the collection path from the cart line
 * In the cart `customAttributes` is transformed into properties
 * See utils/generate-bundle.ts
 * @param properties
 * @returns collection path
 */
const getCollectionPathFromCartLine = properties => {
	return properties?._collectionPath ?? getCollectionPathFromProperties(properties);
};

/**
 * Returns the collection path from the line item
 * Use this function when you have `customAttributes` previously to adding to cart
 * @param customAttributes
 * @returns collection path
 */
const getCollectionPathFromLineItem = customAttributes => {
	return (
		customAttributes?.find(attr => attr.key === '_collectionPath')?.value ??
		getCollectionPathFromCustomAttributes(customAttributes)
	);
};

function getEditPath(bundleKey, frameColor, properties, _customerJourney, handle, withSubscription) {
	let subRoute = 'eyeglasses';
	let queryParams = `?bundleKey=${bundleKey}&frameColor=${frameColor}&edit=true${
		withSubscription ? '&subscriptionPath=true' : ''
	}`;
	const demo = properties[CART_LINE_ATTRIBUTE_KEYS.CUSTOMER_TYPE];

	let parsedHandle = handle;

	if (Object.values(FRAME_COLOR_HANDLES).some(colorHandle => handle.includes(colorHandle))) {
		const colorHandle = Object.values(FRAME_COLOR_HANDLES).find(colorHandle => handle.includes(colorHandle));
		parsedHandle = parsedHandle.replace(`-${colorHandle}`, '');
	}

	switch (_customerJourney) {
		case CUSTOMER_JOURNEYS.SUNGLASSES:
			subRoute = 'sunglasses';
			queryParams += `&lensColor=${properties[CART_LINE_ATTRIBUTE_KEYS.LENS_COLOR]}`;
			break;
		case CUSTOMER_JOURNEYS.BLUELIGHT:
			subRoute = 'blue-light';
			break;
	}

	const basePath = `/${subRoute}/${demo}/${parsedHandle}`;
	return { basePath, queryParams };
}

function getReaderStrengthPayload(strength, frame, properties: { [k: string]: string }) {
	const cleanStr = strength.replace('+', '');

	return {
		...frame,
		customAttributes: [
			...(properties &&
				Object.entries(properties).map<AttributeInput>(([key, value]) => ({
					key,
					value,
				}))),
			{
				key: '_readerStrength',
				value: cleanStr,
			},
		],
	};
}

function getSubmissionMethodPayload(properties: { [k: string]: string }, submissionMethod, frame, extraProperties) {
	return {
		...frame,
		customAttributes: [
			...(properties &&
				Object.entries(properties).map<AttributeInput>(([key, value]) => ({
					key,
					value,
				}))),
			{
				key: CART_LINE_ATTRIBUTE_KEYS.SUBMISSION_METHOD,
				value: submissionMethod,
			},
			...(submissionMethod === SUBMISSION_METHODS.DOCTOR
				? Object.entries(extraProperties).map(([key, value]) => ({ key, value }))
				: []),
		],
	};
}

function getThreshold(locale, siteSettings) {
	if (!siteSettings) {
		return null;
	}
	if (locale === LOCALE_CODES.US) {
		return Number(siteSettings.usShippingThreshold);
	}
	if (locale === LOCALE_CODES.CA) {
		return Number(siteSettings.caShippingThreshold);
	}
	if (locale === LOCALE_CODES.GB) {
		return Number(siteSettings.ukShippingThreshold);
	}
	if (locale === LOCALE_CODES.AU) {
		return Number(siteSettings.auShippingThreshold);
	}
	if (locale === LOCALE_CODES.MX) {
		return Number(siteSettings.mxShippingThreshold);
	}
}

export function getPurchaseChecklist(locale: string, threshold: number): PurchaseChecklist {
	const currency = LOCALE_DICT[locale].currency;
	const countryCode = LOCALE_DICT[locale].countryCode;
	const checklist = [
		{
			description: !!threshold
				? translate('free-shipping-on-plus-orders', locale, { currency, threshold })
				: translate('free-shipping-on-code-orders', locale, { countryCode }),
			checkmark: true,
		},
		{
			description: translate('trial-period', locale),
			checkmark: true,
		},
		{
			description: translate('fsa-hsa-eligible', locale),
			checkmark: true,
			tooltip: translate('original-payment-refund', locale),
		},
	];

	if (locale === LOCALE_CODES.GB || locale === LOCALE_CODES.AU) {
		checklist.pop();
	}

	return checklist;
}

function getChecklist(cart, locale, siteSettings) {
	const threshold = getThreshold(locale, siteSettings);
	return getPurchaseChecklist(locale, threshold);
}

function getTopContainerClass(forceMobileStyle, styles) {
	return forceMobileStyle ? styles.topContainerInDrawer : styles.topContainer;
}

function hasDuplicatedKeys(cart: NormalizedCart): boolean {
	const { lines, bundles } = cart;
	const cleanBundles = { ...bundles };
	delete cleanBundles['no_bundle_key'];

	const bundleKeys = new Set(lines.map(line => line.properties[CART_LINE_ATTRIBUTE_KEYS.BUNDLE_KEY]));

	bundleKeys.delete('no_bundle_key');

	return bundleKeys.size !== Object.keys(bundles).length;
}

function getCartBreakdown(cart: NormalizedCart, isV2Subscription = false) {
	const topFrames = [];
	const bundles: Bundle[] = [];
	const others = [];
	const customBundles = {};
	const subscriptions = [];

	const processTop = (top: NormalizedCartLine, optimistic: boolean) => {
		if (top.properties._custom_bundle_key) {
			const key = top.properties._custom_bundle_key;
			customBundles[key] = customBundles[key] || [];
			customBundles[key].push({ optimistic, top });
			return;
		}

		if (top.sellingPlanAllocation) {
			const subOption = top.sellingPlanAllocation.sellingPlan.name.split(' - ')[0];
			top.variant.option = top.variant.option.includes('-') ? top.variant.option : `${top.variant.option} - ${subOption}`;
			top.variant.removedName = subOption;
			// eslint-disable-next-line max-lines
			top.variant.type = PRODUCT_TYPES.TOPS_SUBSCRIPTION;
			if (!isV2Subscription) {
				top.variant.compareAtPrice = null;
				subscriptions.push({ optimistic, top });
			} else {
				topFrames.push({ optimistic, top });
			}
		} else {
			topFrames.push({ optimistic, top });
		}
	};

	const processBundle = (bundle: Bundle) => {
		const { other, optimistic, tops } = bundle;
		bundles.push({ ...bundle, subscriptionProduct: tops?.find(top => !!top.sellingPlanAllocation) });

		tops?.forEach(top => processTop(top, optimistic));
		other?.forEach(item => others.push({ optimistic, item }));
	};

	if (cart?.lines.length > 0) {
		Object.values(cart?.bundles).forEach(processBundle);
	}

	return { topFrames, bundles, others, customBundles, subscriptions };
}

function addCustomAttributes(attribute: Array<CustomAttribute> | CustomAttribute, frame, properties: { [k: string]: string }) {
	return {
		...frame,
		customAttributes: [
			...(properties &&
				Object.entries(properties).map<AttributeInput>(([key, value]) => ({
					key,
					value,
				}))),
			...(Array.isArray(attribute) ? attribute : [attribute]),
		],
	};
}

function removeCustomAttributes(
	attribute: Array<CustomAttributeKey> | CustomAttributeKey,
	frame,
	properties: { [k: string]: string }
) {
	return {
		...frame,
		customAttributes: Object.entries(properties)
			.map<AttributeInput>(([key, value]) => ({
				key,
				value,
			}))
			.filter(attr => (Array.isArray(attribute) ? !attribute.includes(attr.key) : attr.key !== attribute)),
	};
}

export {
	calculateOptimisticSubtotal,
	computeBundleSubtotal,
	determinePairCarePlan,
	getCollectionPathFromCartLine,
	getCollectionPathFromCustomAttributes,
	getCollectionPathFromLineItem,
	getCollectionPathFromProperties,
	getEditPath,
	getReaderStrengthPayload,
	getSubmissionMethodPayload,
	getThreshold,
	getChecklist,
	getTopContainerClass,
	hasDuplicatedKeys,
	getCartBreakdown,
	addCustomAttributes,
	removeCustomAttributes,
	calculateCartQuantity,
};
