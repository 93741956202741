import { useForm } from 'react-hook-form';
import { useRouter } from 'next/router';
import { Button, Flex, Heading, Input, Modal, Paragraph, Select } from '@components';
import { STATES_CA, STATES_US } from '@utils/states';
import { DoctorInfo } from '@ts/poms';
import { LOCALE_CODES } from '@constants';
import { useTranslation } from '@utils/index';
import styles from './DoctorForm.module.scss';

const getTranslatedTexts = translator => {
	return {
		callDoctorQuestion: translator('call-doctor-question'),
		enterEyeDocInfo: translator('enter-eye-doc-info'),
		patientNameRequired: translator('patient-name-required'),
		patientFullName: translator('patient-full-name'),
		patientBirthdateRequired: translator('patient-birthdate-required'),
		patientBirthdate: translator('patient-birthdate'),
		doctorNameRequired: translator('doctor-name-required'),
		doctorName: translator('doctor-name'),
		doctorPhoneRequired: translator('doctor-phone-required'),
		doctorPhone: translator('doctor-phone'),
		doctorState: translator('doctor-state'),
		cancel: translator('cancel'),
		submit: translator('submit'),
	};
};

const DoctorForm = ({ callback }) => {
	const {
		register,
		handleSubmit,
		formState: { dirtyFields, errors },
		setValue,
		watch,
	} = useForm<DoctorInfo>();
	const doctor_state = watch('doctor_state');
	const { locale } = useRouter();
	const { translator } = useTranslation();
	const translations = getTranslatedTexts(translator);

	const onSubmit = async data => {
		const parts = data.patient_dob.split('-');
		const day = parseInt(parts[2], 10);
		const month = parseInt(parts[1], 10);
		const year = parseInt(parts[0], 10);
		const parsedData = { ...data, patient_dob: `${month}/${day}/${year}` };
		callback(parsedData);
	};

	const STATES = () => {
		switch (locale) {
			case LOCALE_CODES.US:
				return STATES_US;
			case LOCALE_CODES.CA:
				return STATES_CA;
			default:
				return STATES_US;
		}
	};

	return (
		<form data-testid='call-doctor-form' className={styles.container} onSubmit={handleSubmit(onSubmit)}>
			<Heading tag='h2'>{translations.callDoctorQuestion}</Heading>
			<Paragraph style={{ marginBottom: '2.4rem' }}>{translations.enterEyeDocInfo}</Paragraph>
			<Input
				{...register('patient_name', { required: translations.patientNameRequired, pattern: /\D/ })}
				errorMessage={errors?.patient_name?.message}
				placeholder={translations.patientFullName}
				type='text'
				required
			/>
			<Input
				{...register('patient_dob', {
					required: translations.patientBirthdateRequired,
					pattern: /^\d{4}-\d{1,2}-\d{1,2}$/,
					validate: dob => {
						const parts = dob.split('-');
						const day = parseInt(parts[2], 10);
						const month = parseInt(parts[1], 10);
						const year = parseInt(parts[0], 10);

						if (year < 1000 || year > 3000 || month === 0 || month > 12) return false;

						const monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

						if (year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0)) monthLength[1] = 29;

						return day > 0 && day <= monthLength[month - 1];
					},
				})}
				errorMessage={errors?.patient_dob?.message}
				placeholder={translations.patientBirthdate}
				type='date'
				required
			/>
			<Input
				{...register('doctor_clinic_name', { required: translations.doctorNameRequired, pattern: /\D/ })}
				errorMessage={errors?.doctor_clinic_name?.message}
				placeholder={translations.doctorName}
				type='text'
				required
			/>
			<Input
				{...register('doctor_phone_number', {
					required: true,
					pattern: /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
				})}
				errorMessage={errors?.doctor_phone_number ? translations.doctorPhoneRequired : null}
				placeholder={translations.doctorPhone}
				type='tel'
				mask='999-999-9999'
				required
			/>
			<Select
				{...register('doctor_state', { required: true })}
				placeholder={translations.doctorState}
				handler={e => {
					setValue('doctor_state', String(e));
				}}
				buttonProps={{ fullWidth: true, spaceBetween: true }}
				values={Object.entries(STATES()).map(([, val]) => val)}
				containerClassName='docForm'
				isListOnTop
			/>
			<Flex gap={3} justify='end'>
				<Modal.Close asChild>
					<Button label={translations.cancel} color='white' />
				</Modal.Close>
				<Button
					type='submit'
					label={translations.submit}
					disabled={
						!(
							dirtyFields.patient_name &&
							dirtyFields.patient_dob &&
							dirtyFields.doctor_clinic_name &&
							dirtyFields.doctor_phone_number &&
							doctor_state
						)
					}
				/>
			</Flex>
		</form>
	);
};

export default DoctorForm;
