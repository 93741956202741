const productImageQuery = /* GraphQL */ `
	query productImage($handle: String, $frame: String!) {
		product(handle: $handle) {
			variantBySelectedOptions(selectedOptions: { name: "Frame", value: $frame }) {
				image {
					url
				}
			}
		}
	}
`;
export default productImageQuery;
