/* eslint-disable max-len */
/* eslint-disable max-lines */
/* eslint-disable react/no-unescaped-entities */
// ^ Need this for the Klaviyo script to run properly
import Script from 'next/script';
import { useCustomer } from '@services/shopify';
import { NEXT_APP_ENV } from '@constants';
import { useLocale } from '@utils/index';

const GlobalScripts = () => {
	const { data, isLoading } = useCustomer();
	const { data: localeData } = useLocale();
	const country = localeData?.country;
	const countryRegion = localeData?.countryRegion;

	const isProd = process.env.NEXT_PUBLIC_APP_ENV === NEXT_APP_ENV.PROD;
	return (
		<>
			{/* GLOBAL-E */}
			<script
				async
				id='ge-storefront-js'
				data-mid='8u8z'
				data-env='prd'
				src='https://web.global-e.com/Scripts/Merchants/globale.merchant.storefront.js'
			></script>
			{!process.env.NEXT_PUBLIC_DISABLE_TRACKING && (
				<>
					{/* Google Tag Manager */}

					<Script id='google-tag-manager' strategy='afterInteractive'>
						{`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
					new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
					j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
					'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
					})(window,document,'script','dataLayer','GTM-N95C7QQ');`}
					</Script>

					{/* Elevar Script */}
					<Script id='elevar-script' type='module'>
						{`try {
							const settings = {};
							const config = (await import("https://shopify-gtm-suite.getelevar.com/configs/95b9566f7c24bf6157e711369648b9968feb54ef/config.js")).default;
							const scriptUrl = settings.proxyPath
							? settings.proxyPath + config.script_src_custom_pages_proxied
							: config.script_src_custom_pages;

							if (scriptUrl) {
							const { handler } = await import(scriptUrl);
							await handler(config, settings);
							}
						} catch (error) {
							console.error("Elevar Error:", error);
						}`}
					</Script>

					{/* Heap Analytics*/}

					<Script id='load-heap-analytics'>{`window.heap=window.heap||[],heap.load=function(e,t){window.heap.appid=e,window.heap.config=t=t||{};var r=document.createElement("script");r.type="text/javascript",r.async=!0,r.src="https://cdn.heapanalytics.com/js/heap-"+e+".js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(r,a)};
				for(var n=function(e){return function(){heap.push([e].concat(Array.prototype.slice.call(arguments,0)))}},p=["addEventProperties","addUserProperties","clearEventProperties","identify","resetIdentity","removeEventProperty","setEventProperties","track","unsetEventProperty"],o=0;o<p.length;o++)heap[p[o]]=n(p[o]);
				heap.load("${process.env.NEXT_PUBLIC_HEAP_ANALYTICS_MAIN_PROD}");`}</Script>

					{/* ADA Chatbot */}
					<Script
						src='https://static.ada.support/embed2.js'
						strategy='lazyOnload'
						id='__ada'
						data-handle='paireyewear'
					/>

					{/* Marketing Modal Scripts */}

					{!isLoading && !data && (
						<Script
							src='https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=Lhg4L3'
							strategy='afterInteractive'
						/>
					)}

					<Script id='kaviyo-modal' strategy='afterInteractive'>
						window._klOnsite = window._klOnsite || []; window._klOnsite.push(['openForm', 'ScpFKi']);
					</Script>

					{/* Attentive */}
					{!isLoading && (
						<Script id='attentive-modal' src='https://cdn.attn.tv/paireyewear/dtag.js' strategy='afterInteractive' />
					)}

					<Script id='kaviyo-modal' strategy='afterInteractive'>
						window._klOnsite = window._klOnsite || []; !window.localStorage.getItem('__kla_viewed') &&
						window._klOnsite.push(['openForm', 'ScpFKi']); window.localStorage.setItem('__kla_viewed', 'true');
					</Script>

					{/* Onetrust */}
					{!!isProd ? (
						<>
							<Script
								src='https://cdn.cookielaw.org/scripttemplates/otSDKStub.js'
								type='text/javascript'
								data-domain-script='57c1dcff-2fc9-4a01-8fc3-572fb9779c54'
							/>
							<Script id={'onetrust_consent'} type='text/javascript'>
								{`
									var OneTrust = {
										geolocationResponse: {
											stateCode: ${countryRegion},
											countryCode: ${country},
										}
									};
									function OptanonWrapper() {}
								`}
							</Script>
						</>
					) : (
						<>
							<Script
								src='https://cdn.cookielaw.org/scripttemplates/otSDKStub.js'
								type='text/javascript'
								data-domain-script='57c1dcff-2fc9-4a01-8fc3-572fb9779c54-test'
							/>
							<Script id={'onetrust_consent'} type='text/javascript'>
								{`
									var OneTrust = {
										geolocationResponse: {
											stateCode: ${countryRegion},
											countryCode: ${country},
										}
									};
									function OptanonWrapper() {}
								`}
							</Script>
						</>
					)}
				</>
			)}
		</>
	);
};

export default GlobalScripts;
