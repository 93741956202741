import { productFieldsFragment } from '../fragments';
import {
	collectionMetafieldIdentifiers,
	generateMetafieldQueries,
	productMetafieldIdentifiers,
} from '../generate-metafield-queries';

export const multipleCollectionsQuery = /* GraphQL */ `
	query getMultipleCollections(
		$cursor: String,
		$includeDescription: Boolean = false
		$includeSpecificFrameVariant: Boolean = false
		$maxProducts: Int = 50,
		$searchQuery: String!,
		$skipCollections: Boolean = false
		$skipImages: Boolean = false
		$skipVariants: Boolean = false,
		$withProducts: Boolean!,
		$selectedOptions: [SelectedOptionInput!]!
		$country: CountryCode = US
		$maxVariants: Int = 25
		$language: LanguageCode = EN
	) @inContext(country: $country, language: $language) {
		collections(first: 50, after: $cursor, query: $searchQuery) {
			edges {
				cursor
				node {
					id
					title
					handle
					image {
						url
						height
						width
						altText
					}
					# We can pass filters to only fetch available Products -- this happens in the BF Collections Query
					# We can also filter by variant option at this level, but prob fine to just keep the variantBySelectedOption call
					products(first: $maxProducts, after: $cursor) @include(if: $withProducts) {
						edges {
							cursor
							node {
								...productFields
								${generateMetafieldQueries(productMetafieldIdentifiers)} # make togglable
							}
						}
						pageInfo {
							hasNextPage
						}
					}
					${generateMetafieldQueries(collectionMetafieldIdentifiers)} # make different metafields togglable
				}
			}
			pageInfo {
				hasNextPage
			}
		}
	}
	${productFieldsFragment}
`;

export default multipleCollectionsQuery;
