import Head from 'next/head';
import cn from 'classnames';
import { Img, ProductImage, SaveTag, CarouselTags as Tags } from '@components';
import { NormalizedVariant } from '@ts/product';
import styles from './Carousel.module.scss';
import 'swiper/css';
import { useTranslation } from '@utils/index';

type SlideProps = {
	secondary?: string;
	type: string;
	name: string;
	imageLoadingHandler?: (x: boolean) => void;
	index: number;
	isModalOpen?: boolean;
	url?: string;
	width?: number;
	height?: number;
	primary?: string;
	variant?: NormalizedVariant;
	tags?: string[];
	imageLayoutType?: 'single' | 'double' | 'none';
};

const getTranslatedTexts = translator => {
	return {
		slideImage: (index: number) => translator('slide-image-enumarated', { index }),
	};
};

const Slide = ({
	imageLoadingHandler,
	isModalOpen = false,
	name,
	type,
	primary,
	secondary,
	index,
	url,
	width,
	height,
	variant,
	tags,
	imageLayoutType = 'none',
}: SlideProps) => {
	const isImagesLaidOut = imageLayoutType === 'single' || imageLayoutType === 'double';
	const sizes = `(min-width: 1024px) 1440px,
							(min-width: 769px) 1200px,
							(min-width: 376px) 769px, 
							551px`;
	const { translator } = useTranslation();
	const translations = getTranslatedTexts(translator);

	return secondary ? (
		<ProductImage
			primary={primary}
			secondary={secondary}
			name={name}
			type={secondary.includes('template-modal') ? 'LIFESTYLE' : type}
			imageLoadingHandler={imageLoadingHandler}
			isModalOpen={isModalOpen}
			imageLayoutType={imageLayoutType}
			loading={index === 0 ? 'eager' : 'lazy'}
			// 2nd and 3rd Image are Zoomed, thus higher quality images should be loaded
			{...(index !== 0 && {
				sizes,
			})}
		/>
	) : (
		<>
			<Head>
				{index === 0 && (
					<>
						<link rel='preload' as='image' href={url} />
					</>
				)}
			</Head>
			<div
				className={cn(styles['image-container'], {
					[styles['images-with-border']]: isImagesLaidOut,
				})}
				data-product-type={type}
				data-product-name={name}
			>
				<Img
					onLoad={() => imageLoadingHandler && imageLoadingHandler(false)}
					src={url}
					alt={translations.slideImage(index + 1)}
					width={width / 2}
					height={height / 2}
					sizes='(max-width: 55em) 24w, (min-width: 551px) 64w, (min-width: 76.8em) 1000w'
					loading={index === 0 ? 'eager' : 'lazy'}
				/>
				{isImagesLaidOut && index === 0 && variant?.metafields?.discountTag && (
					<div className={styles.saveTag}>
						<SaveTag label='logo-pair-eyewear-color' detail={variant.metafields?.discountTag} />
					</div>
				)}
				{isImagesLaidOut && index === 0 && (
					<Tags tags={variant?.tags && variant?.tags.length > 0 ? variant.tags : tags} />
				)}
			</div>
		</>
	);
};

export default Slide;
