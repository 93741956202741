/* eslint-disable max-lines */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { useRouter } from 'next/router';
import { ALLOWED_PRESCRIPTION_UPLOAD_EXTENSIONS, getToastMessage, LOCALE_CODES, SUBMISSION_METHODS } from '@constants';
import variables from '@styles/export.module.scss';
import { sendDoctorInfo } from '@services/poms';
import { sendPhoto } from '@services/poms/operations/send-photo';
import { Caption, Checkmark, Flex, Modal, Paragraph, PhotoPd, PrescriptionOption, TypographyButton } from '@components';
import { SubmissionMethod } from '@ts/poms';
import { useCartContext, useToastContext } from '@utils/context';
import { useFeatureInLocale, useTranslation } from '@utils/hooks';
import DoctorForm from '../DoctorForm';
import styles from './PrescriptionOptions.module.scss';

const { REMIND, PHOTO, DOCTOR } = SUBMISSION_METHODS;

const getTranslatedTexts = translator => {
	return {
		uploadedPdfEncrypted: translator('uploaded-pdf-encrypted'),
		invalidFileTypeReupload: (fileTypes: string) => translator('invalid-file-type-reupload', { fileTypes }),
		myPrescription: translator('my-prescription'),
		required: translator('required'),
		edit: translator('edit'),
		remove: translator('remove'),
		callDoctorConfirmation: translator('call-doctor-confirmation'),
		photoUploaded: translator('photo-uploaded'),
		getFramesFaster: translator('get-frames-faster'),
		completeUncroppedPicture: translator('complete-uncropped-picture'),
		uploadPhoto: translator('upload-photo'),
		placeOrderRemindLater: translator('place-order-remind-later'),
		remindMeLater: translator('remind-me-later'),
		enterEyeDocInfo2: translator('enter-eye-doc-info-2'),
		haveUsContactDoctor: translator('have-us-contact-doctor'),
	};
};

const PrescriptionOptions = ({
	updateBundleSubmissionMethod,
	updatePDMeasurement,
	submissionMethodState,
	bundleKey,
	hasMeasuredPd = false,
	checkoutToken,
}: {
	updatePDMeasurement?: (newPD: number, method?: string) => Promise<void>;
	updateBundleSubmissionMethod: (extraProperties?: { [k: string]: string }) => void;
	submissionMethodState: [SubmissionMethod, React.Dispatch<React.SetStateAction<SubmissionMethod>>];
	bundleKey: string;
	hasMeasuredPd?: boolean;
	checkoutToken: string;
}) => {
	const { locale } = useRouter();
	const [submissionMethod, setSubmissionMethod] = submissionMethodState;
	const [photoFile, setPhotoFile] = useState<File>(null);
	const [error, setError] = useState(null);
	const [extraProperties, setExtraProperties] = useState<{ [k: string]: string }>({});
	const [modalOpen, setModalOpen] = useState(false);
	const fileRef = useRef<HTMLInputElement>();
	const isCartPDTest = useFeatureIsOn('is-cart-pd-test');
	const isCartUsability = useFeatureInLocale('is-cart-usability', LOCALE_CODES.US);
	const { showToast } = useToastContext();
	const { setIsUploadingRx } = useCartContext();
	const { translator } = useTranslation();
	const translations = getTranslatedTexts(translator);
	const TOAST = getToastMessage(locale);

	const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>, key: string) => {
		if (!e.target?.files[0]?.name) return;
		setIsUploadingRx(true);
		const file = e.target.files[0];

		try {
			const extension = file.name?.match(/\.([0-9a-z]+)$/i)?.[1]?.toLowerCase();
			const isPDF = extension === 'pdf';
			const fileText = await file?.text();
			const fileSubstring = fileText?.substring(fileText?.lastIndexOf('<<'), fileText?.lastIndexOf('>>'));
			const isPDFEncrypted = isPDF ? fileSubstring?.includes('/Encrypt') : false;

			if (ALLOWED_PRESCRIPTION_UPLOAD_EXTENSIONS.includes(extension) && !isPDFEncrypted) {
				await sendPhoto({ file, key, checkout_token: checkoutToken });
				setPhotoFile(file);
				setSubmissionMethod(PHOTO);
			} else {
				setError(
					isPDFEncrypted
						? translations.uploadedPdfEncrypted
						: // eslint-disable-next-line max-len
							translations.invalidFileTypeReupload(ALLOWED_PRESCRIPTION_UPLOAD_EXTENSIONS.join(', '))
				);
				setSubmissionMethod(REMIND);
			}
		} catch (err) {
			console.error(err);
			showToast(TOAST.UPLOAD_RX_ERROR);
			setSubmissionMethod(REMIND);
		} finally {
			setIsUploadingRx(false);
		}
	};

	const handleFileDelete = () => {
		fileRef.current.value = null;
		setSubmissionMethod(REMIND);
		setPhotoFile(null);
	};

	useEffect(() => {
		updateBundleSubmissionMethod(extraProperties);

		if (submissionMethod !== PHOTO && fileRef.current) {
			setPhotoFile(null);
			fileRef.current.value = null;
		}

		if (submissionMethod !== DOCTOR) {
			setExtraProperties({});
		}
	}, [submissionMethod, fileRef.current]);

	const DoctorFormModal = useCallback(
		({ children }: { children: React.ReactNode }) => {
			return (
				<Modal open={modalOpen} onOpenChange={setModalOpen}>
					<Modal.Trigger tabIndex={-1} style={{ textAlign: 'left' }}>
						{children}
					</Modal.Trigger>
					<Modal.Content center>
						<DoctorForm
							callback={async newProperties => {
								try {
									await sendDoctorInfo({
										...newProperties,
										...{ key: bundleKey ?? 'unknown', checkout_token: checkoutToken },
									});
									setSubmissionMethod(DOCTOR);
									setExtraProperties(newProperties);
								} catch (err) {
									console.error(err);
									showToast(TOAST.UPLOAD_RX_ERROR);
									setSubmissionMethod(REMIND);
								}
								setModalOpen(false);
							}}
						/>
					</Modal.Content>
				</Modal>
			);
		},
		[modalOpen, setModalOpen, submissionMethod, bundleKey, checkoutToken, setSubmissionMethod, setExtraProperties]
	);

	return (
		<Flex data-testid='my-prescription' column style={{ gap: '2.4rem' }} className={styles.container} fullWidth>
			<Flex align='center' justify='between' fullWidth>
				<Paragraph className={styles.bundleTitle}>{translations.myPrescription}</Paragraph>
				<TypographyButton small>{translations.required}</TypographyButton>
			</Flex>
			{isCartUsability && submissionMethod === DOCTOR && (
				<Flex fullWidth justify='center' align='center' style={{ paddingTop: '3rem', paddingBottom: '1rem' }}>
					<Caption color={variables.green2} style={{ textDecorationLine: 'underline' }}>
						{translations.callDoctorConfirmation}
					</Caption>
				</Flex>
			)}
			<Flex column style={{ display: `${isCartUsability && submissionMethod === DOCTOR ? 'none' : 'flex'}` }} gap={3}>
				<PrescriptionOption
					checked={submissionMethod === PHOTO}
					clickCallback={() => {
						!photoFile && fileRef.current && fileRef.current.click();
					}}
					inputCallback={e => {
						setError(null);
						submissionMethod !== PHOTO && handleFileChange(e, bundleKey);
					}}
					clearInput={handleFileDelete}
					key={'photo-option'}
					lozenge={{
						label:
							submissionMethod === PHOTO ? (
								<Flex gap={2} align='center'>
									<Flex className={styles.checkmark} align='center' justify='center'>
										<Checkmark color={variables.greenLight} thick width={6} height={5} />
									</Flex>
									{translations.photoUploaded}
								</Flex>
							) : (
								translations.getFramesFaster
							),
						backgroundColor: submissionMethod === PHOTO ? variables.greenLight : variables.blue1,
						color: submissionMethod === PHOTO ? variables.greenShade : variables.gray0,
					}}
					ref={fileRef}
					subcopy={translations.completeUncroppedPicture}
					supplementalCopy={photoFile?.name}
					error={error}
					title={translations.uploadPhoto}
					method={PHOTO}
				/>
				{isCartPDTest && submissionMethod === PHOTO && (
					<PhotoPd submit={updatePDMeasurement} isPdNeeded={!hasMeasuredPd} />
				)}
				{submissionMethod !== PHOTO && (
					<PrescriptionOption
						checked={submissionMethod === REMIND}
						clickCallback={() => setSubmissionMethod(REMIND)}
						key={'remind-option'}
						subcopy={translations.placeOrderRemindLater}
						title={translations.remindMeLater}
						method={REMIND}
					/>
				)}

				{[LOCALE_CODES.US, LOCALE_CODES.CA].includes(locale) && (
					/*!isCartUsability && */ <DoctorFormModal>
						<PrescriptionOption
							checked={submissionMethod === DOCTOR}
							subcopy={translations.enterEyeDocInfo2}
							title={translations.haveUsContactDoctor}
							method={DOCTOR}
						/>
					</DoctorFormModal>
				)}
			</Flex>
		</Flex>
	);
};

export default PrescriptionOptions;
