import { useRouter } from 'next/router';
import { Button, Flex, Heading, Modal, Paragraph } from '@components';
import { buildUrl } from '@utils/urls';
import styles from './LoginPrompt.module.scss';
import { useTranslation } from '@utils/index';

const getTranslatedTexts = translator => {
	return {
		logInToFavorite: translator('log-in-to-favorite'),
		saveFavoriteItems: translator('save-favorite-items'),
		cancel: translator('cancel'),
		logInSentenceCase: translator('log-in-sentence-case'),
		accountPrompt: translator('account-prompt'),
		signUp: translator('sign-up'),
	};
};

const LoginPrompt = ({ returnUrl }: { returnUrl?: string }) => {
	const loginURL = buildUrl('account/login', { returnUrl });
	const router = useRouter();
	const { translator } = useTranslation();
	const translations = getTranslatedTexts(translator);

	return (
		<div className={styles.container}>
			<div>
				<Heading tag='h5'>{translations.logInToFavorite}</Heading>
				<Paragraph>{translations.saveFavoriteItems}</Paragraph>
			</div>

			<Flex gap={3} className={styles.buttonsContainer}>
				<Modal.Close asChild>
					<Button color='white'>{translations.cancel}</Button>
				</Modal.Close>
				<Modal.Close asChild>
					<Button
						fullWidth
						onClick={() => {
							router.push(loginURL);
						}}
						type='button'
					>
						{translations.logInSentenceCase}
					</Button>
				</Modal.Close>
			</Flex>

			<Flex justify='center' className={styles.footer}>
				<Paragraph>{translations.accountPrompt}</Paragraph>
				<Button href='/account/register' linkStyle size='small' label={translations.signUp} />
			</Flex>
		</div>
	);
};

export default LoginPrompt;
