import { useRouter } from 'next/router';
import cn from 'classnames';
import Link from 'next/link';
import { Button, Close, Drawer, PairLogo } from '@components';
import styles from './HeaderThin.module.scss';
import { useTranslation } from '@utils/index';

const getTranslatedTexts = translator => {
	return {
		closeUppercase: translator('close-uppercase'),
		back: translator('back'),
	};
};

const HeaderThin = ({ inDrawer = false, redirectTo = null }) => {
	const router = useRouter();
	const { translator } = useTranslation();
	const translations = getTranslatedTexts(translator);

	const classes = cn(styles.container, {
		[styles['container--respectAnnouncementBar']]: !inDrawer,
	});

	const gridClasses = cn(styles.grid, {
		[styles['grid--no-drawer']]: !inDrawer,
	});

	return (
		<div className={classes}>
			<div className={gridClasses}>
				{inDrawer ? (
					<Drawer.Close asChild>
						<Close wrapperClass={styles['close-button']} height={13} width={13} label={translations.closeUppercase} />
					</Drawer.Close>
				) : (
					<Button
						style={{ justifySelf: 'flex-start' }}
						data-cart-back-button
						linkStyle
						size='small'
						withChevron
						chevronDirection='left'
						chevronPosition='front'
						label={translations.back}
						onClick={() => {
							if (!!redirectTo) {
								router.push(redirectTo);
								return;
							}
							router.back();
						}}
					/>
				)}

				<Link href={'/'}>
					<PairLogo />
				</Link>
			</div>
		</div>
	);
};

export default HeaderThin;
