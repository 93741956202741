import { Flex, SubtotalHeader } from '@components';
import styles from './CartHeader.module.scss';

type CartHeaderProps = {
	subtotal: number;
	itemCount: number;
};

const CartHeader = ({ subtotal, itemCount }: CartHeaderProps) => (
	<Flex className={styles.defaultContainer} maxWidth justify='between' align='center'>
		<SubtotalHeader subtotal={subtotal} itemCount={itemCount} />
	</Flex>
);

export default CartHeader;
