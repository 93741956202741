import React, { useCallback } from 'react';
import cn from 'classnames';
import { Paragraph, Caption, ComponentHeader, Truck, Attention, CartIcon, Tag } from '@components';
import Tooltip from '../Tooltip';
import Flex from '../Flex';
import styles from './Checklist.module.scss';
import { useTranslation } from '@utils/index';

export type ListProps = {
	description: string;
	checkmark: boolean;
	tooltip?: string;
	icon?: string;
}[];

type ChecklistProps = {
	title?: string;
	backgroundColor?: string;
	withDivider?: boolean;
	className?: string;
	list: ListProps;
};

const getTranslatedTexts = translator => {
	return {
		attention: translator('attention-lowercase'),
		cartIcon: translator('cart-icon'),
		tagIcon: translator('tag-icon'),
	};
};

const Checklist = ({ title, backgroundColor = 'none', list, withDivider = false, className }: ChecklistProps) => {
	const { translator } = useTranslation();
	const translations = getTranslatedTexts(translator);

	const getIcon = useCallback((iconLabel: string) => {
		switch (iconLabel) {
			case 'truck':
				return <Truck />;
			case 'attention':
				return <Attention height={24} width={24} fill='black' label={translations.attention} />;
			case 'cart':
				return <CartIcon className={styles['cart-icon']} label={translations.cartIcon} />;
			case 'tag':
				return <Tag width={22} height={22} color='black' label={translations.tagIcon} />;
			default:
				return <Attention height={24} width={24} fill='black' label={'a'} />;
		}
	}, []);

	return (
		<Flex column gap={4} className={cn(styles.container, className)} style={{ backgroundColor }}>
			{!withDivider && title && <Caption>{title}</Caption>}
			{withDivider && title && <ComponentHeader title={title} tag='h6' className={styles.header} withDivider />}
			<ul>
				{list.map(({ description, checkmark, tooltip, icon }, index) => (
					<li
						key={`panel-description-${index}`}
						className={`${styles['content-list-item']} ${icon && styles['with-icon']} ${!icon && (checkmark ? styles['checkmark'] : styles['crossmark'])}`}
					>
						{icon && getIcon(icon)}
						<Paragraph>{description}</Paragraph>
						{tooltip && <Tooltip message={tooltip} />}
					</li>
				))}
			</ul>
		</Flex>
	);
};

export default Checklist;
