import { Dispatch } from 'react';
import { useRouter } from 'next/router';
import { BaseFrameStateDispatch } from '@utils/hooks/useBaseFrame';
import { LOCALE_CODES, PRODUCT_TYPES, formatCurrency, useDiscountedPrice } from '@utils/index';
import { CardProps } from '@ts/components';
import { useTailoredExperience } from '@services/contentful';
import MicroButton from './MicroButton';
import StackedButton from './StackedButton';
import SideBySideButton from './SideBySideButton';

export type ButtonGroupProps = {
	dispatch: Dispatch<BaseFrameStateDispatch>;
	linkToProduct: string;
	productClickEvent: () => void;
	alreadyInCart: boolean;
	openMinicartOnPurchase: boolean;
	bundleKey: string;
	label?: string;
	dataTags?: Record<
		'button' | 'zoom' | 'favorite',
		{
			[key: string]: string | boolean;
		}
	>;
	isMinicart: boolean;
	applyAutomaticDiscount: boolean;
} & Required<
	Pick<
		CardProps,
		| 'primaryAction'
		| 'primaryActionData'
		| 'buttonGroupType'
		| 'compact'
		| 'product'
		| 'variant'
		| 'secondaryAction'
		| 'secondaryActionData'
		| 'productRemoved'
		| 'isMobile'
		| 'parentCollectionHandle'
	>
>;
const ButtonGroup = ({
	alreadyInCart,
	buttonGroupType: type,
	compact,
	product,
	variant,
	primaryAction,
	primaryActionData,
	secondaryAction,
	secondaryActionData,
	dispatch,
	productRemoved,
	linkToProduct,
	isMobile,
	openMinicartOnPurchase,
	parentCollectionHandle,
	productClickEvent,
	bundleKey,
	label,
	dataTags,
	isMinicart,
	applyAutomaticDiscount,
}: ButtonGroupProps) => {
	const { compareAtPrice, price } = variant;
	const collectionPath = linkToProduct?.split(product.handle)[0];
	const { locale, pathname } = useRouter();
	const { data: tailoredExperience } = useTailoredExperience();
	const utmParam = tailoredExperience ? `&utm_term=${tailoredExperience?.utmTerm}` : '';
	const isReceipt = pathname.includes('/account/orders');
	const showCurr = locale === LOCALE_CODES.AU || locale === LOCALE_CODES.CA;
	const formatPrice = (money, showDigits) =>
		formatCurrency(
			{
				...money,
				minDigits: showDigits ? 2 : 0,
				maxDigits: showDigits ? 2 : 0,
				locale,
			},
			showCurr
		);
	const [newPrice, newCompareAtPrice] = useDiscountedPrice({
		price: price.amount,
		compareAtPrice: compareAtPrice?.amount,
		type: applyAutomaticDiscount ? product.type : PRODUCT_TYPES.BUNDLE,
	});
	const formattedPrice = formatPrice({ ...price, amount: newPrice }, isReceipt);
	const formattedCompareAtPrice = formatPrice({ ...price, amount: newCompareAtPrice }, isReceipt);

	switch (type) {
		case 'micro':
			return (
				<MicroButton
					{...{
						secondaryAction,
						secondaryActionData,
						primaryAction,
						primaryActionData,
						variant,
						formattedPrice,
						formattedCompareAtPrice,
						compareAtPrice,
						productRemoved,
						isMobile,
						dataTags,
					}}
				/>
			);
		case 'stacked':
			return (
				<StackedButton
					{...{
						secondaryAction,
						secondaryActionData,
						primaryAction,
						variant,
						isMobile,
						dataTags,
						product,
						dispatch,
						openMinicartOnPurchase,
						parentCollectionHandle,
						collectionPath,
						alreadyInCart,
						price,
						linkToProduct,
						utmParam,
						label,
					}}
				/>
			);
		case 'side-by-side':
			return (
				<SideBySideButton
					{...{
						secondaryAction,
						compact,
						product,
						collectionPath,
						variant,
						isMobile,
						dataTags,
						primaryAction,
						primaryActionData,
						parentCollectionHandle,
						bundleKey,
						openMinicartOnPurchase,
						linkToProduct,
						alreadyInCart,
						productClickEvent,
						price,
						label,
						isMinicart,
					}}
				/>
			);
		default:
			return null;
	}
};

export default ButtonGroup;
