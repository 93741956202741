import { NormalizedVariant } from '@ts/product';
import fetchStorefrontApi from '../fetch-storefront-api';
import { productVariantQuery } from '../queries';

export async function getProductVariant(variantId: string, country = 'US', language = 'EN'): Promise<NormalizedVariant> {
	const variant = await fetchStorefrontApi(productVariantQuery, {
		variables: { id: variantId, country, language },
	});

	return variant;
}
