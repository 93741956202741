import Cookies from 'js-cookie';
import { LOCALE_CODES, LOCALE_DICT, PRODUCT_TYPES, SHOPIFY_CART_ID_COOKIE } from '@constants';
import { cartQuery, fetchStorefrontApi } from '@services/shopify';
import { normalizeCart } from '@utils/normalizers/normalize-cart';
import { QueryRoot } from '@ts/shopify-storefront-api';
import { getProducts, getUserProperties } from './helpers/elevar';

export type DataLayerUserProperties = {
	// The following fields aren't required if unavailable
	customer_address_1?: string;
	customer_address_2?: string;
	customer_city?: string;
	customer_country?: string;
	customer_email?: string;
	customer_first_name?: string;
	customer_id?: string;
	customer_last_name?: string;
	customer_order_count?: string;
	customer_phone?: string;
	customer_province?: string;
	customer_province_code?: string;
	customer_country_code?: string;
	customer_tags?: string;
	customer_total_spent?: string;
	customer_zip?: string;

	// The following fields are required
	user_consent: boolean | '';
	visitor_type: 'logged_in' | 'guest';
};

export type DataLayerProduct = {
	id: string; // SKU
	name: string; // Product title
	brand: string;
	category: (typeof PRODUCT_TYPES)[keyof typeof PRODUCT_TYPES];
	variant: string;
	price: string;
	quantity?: string; // Not required for dl_select_item & dl_view_item
	position?: number; // Only required for dl_select_item; position in the list of search results, collection views and position in cart indexed starting at 1
	list: string; // The list the product was discovered from
	product_id: string; // The product_id
	variant_id: string; // id or variant_id
	compare_at_price?: string; // If available on dl_view_item & dl_add_to_cart otherwise use "0.0"
	image?: string; // If available, otherwise use an empty string
	inventory?: string; // If available, only required on dl_view_item
	image_url?: string;
};

export type DataLayerImpression = {
	id: string; // SKU
	name: string; // Product title
	brand: string;
	category: string;
	variant: string;
	price: string;
	quantity: string; // Only required for dl_view_cart
	list?: string; // Not required for dl_view_cart; The list the product is displayed in
	product_id: string;
	variant_id: string; // id or variant_id
	compare_at_price?: string; // If available
	position: number; // position in the list of search results, collection views and position in cart indexed starting at 1
};

export type ActionField = {
	list?: string;
	step?: string;
	action?: string;
};

export type GtmEcomEvent = {
	currencyCode?: string;
	locale?: string;
	actionField?: ActionField;
	cart_contents?: {
		products: DataLayerProduct[];
	};
	impressions?: DataLayerImpression[];
	add?: {
		actionField?: ActionField;
		products: DataLayerProduct[];
	};
	remove?: {
		actionField?: ActionField;
		products: DataLayerProduct[];
	};
	detail?: {
		actionField?: ActionField;
		products: DataLayerProduct[];
	};
	click?: {
		actionField?: ActionField;
		products: DataLayerProduct[];
	};
	checkout?: {
		actionField?: ActionField;
		products: DataLayerProduct[];
	};
	view?: {
		products: GTMDataLayerProduct[];
	};
};

export type DataLayerPayload = {
	event?:
		| 'dl_user_data'
		| 'dl_sign_up'
		| 'dl_login'
		| 'dl_view_item_list'
		| 'dl_view_search_results'
		| 'dl_select_item'
		| 'dl_view_item'
		| 'dl_add_to_cart'
		| 'dl_remove_from_cart'
		| 'dl_view_cart'
		| 'dl_begin_checkout'
		| 'dl_newsletter_signup'
		| 'enhanceEcom addToCart'
		| 'enhanceEcom productView'
		| 'user_details'
		| 'initiateCheckout'
		| 'search'
		| 'viewCart';
	cart_total?: string;
	user_properties?: DataLayerUserProperties;
	ecommerce?: GtmEcomEvent;
};

export type GTMDataLayerProduct = {
	id: string; // SKU
	name: string;
	category: (typeof PRODUCT_TYPES)[keyof typeof PRODUCT_TYPES];
	variant: string;
	price: number;
	quantity?: number;
	image_url?: string;
};

export const pushUserData = async () => {
	const cartId = Cookies.get(SHOPIFY_CART_ID_COOKIE);
	if (!cartId) return;
	let cartTotal = '0.00';
	let products = [];

	const locale = typeof window === 'undefined' ? LOCALE_CODES.US : window.locale;

	if (cartId && locale) {
		try {
			const locale = typeof window === 'undefined' ? LOCALE_CODES.US : window.locale;
			const { countryCode: country, languageCode: language } = LOCALE_DICT[locale];
			const { cart }: QueryRoot = await fetchStorefrontApi(cartQuery, {
				variables: { id: cartId, country, language },
			});
			const normalizedCart = normalizeCart(cart);
			const subtotal = normalizedCart.subtotal;
			cartTotal = `${subtotal.toFixed(2)}`;
			products = getProducts(normalizedCart);
		} catch (error) {
			console.error('Error getting cart total', error);
		}
	}

	window.ElevarDataLayer = window.ElevarDataLayer ?? [];

	window?.ElevarDataLayer.push({
		event: 'dl_user_data',
		cart_total: cartTotal,
		user_properties: await getUserProperties(),
		ecommerce: {
			locale,
			currencyCode: LOCALE_DICT[locale]?.currencyCode || 'USD',
			cart_contents: {
				products: products,
			},
		},
	});
};

export const pushToDataLayer = (payload: DataLayerPayload) => {
	if (typeof window === 'undefined') return null;
	window.dataLayer = window.dataLayer || [];
	window.dataLayer.push(payload);
};
