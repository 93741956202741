const baseFrameVariantQuery = /* GraphQL */ `
	query baseFrameVariant(
		$handle: String
		$selectedOptions: [SelectedOptionInput!]!
		$country: CountryCode = US
		$language: LanguageCode = EN
	) @inContext(country: $country, language: $language) {
		product(handle: $handle) {
			variantBySelectedOptions(selectedOptions: $selectedOptions) {
				id
				price {
					amount
					currencyCode
				}
				compareAtPrice {
					amount
					currencyCode
				}
				selectedOptions {
					name
					value
				}
				title
			}
		}
	}
`;
export default baseFrameVariantQuery;
