const cartCreateMutation = /* GraphQL */ `
	mutation cartCreate($country: CountryCode = US, $language: LanguageCode = EN)
	@inContext(country: $country, language: $language) {
		cartCreate {
			cart {
				id
			}
			userErrors {
				code
				field
				message
			}
		}
	}
`;

export { cartCreateMutation };
