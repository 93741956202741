import { useState } from 'react';
import { m } from 'framer-motion';
import { Box, Button, Caption, Close, EyeIcon, Flex, LabelText, PdToolModal } from '@components';
import variables from '@styles/export.module.scss';
import { accordianHeightExpansion } from '@utils/motions';
import { useTranslation } from '@utils/hooks';
import styles from './PhotoPd.module.scss';

const { green4 } = variables;

const getTranslatedTexts = translator => {
	return {
		successfulMeasurePd: translator('successful-measure-pd'),
		measureYourPd: translator('measure-your-pd'),
		measureMyPd: translator('measure-my-pd'),
		expediteOrderProcessing: translator('expedite-order-processing'),
		checkPdInPrescription: translator('check-pd-in-prescription'),
		closeCapitalized: translator('close-uppercase'),
		learnHow: translator('learn-how'),
		close: translator('close'),
	};
};

const PhotoPd = ({ isAccount = false, isPdNeeded = false, isMeasuredPd = false, submit }) => {
	const isHeapAvailable = typeof window !== 'undefined' && window.heap;
	const [hasMeasuredPd, setHasMeasuredPd] = useState(false);
	const [isPDMeasureContainer, setIsPDMeasureContainer] = useState(false);
	const [pDModalOpen, setPDModalOpen] = useState(false);
	const [pdToolInit, setPdToolInit] = useState(false);
	const [pdToolHasError, setPdToolHasError] = useState(false);
	const [hasScriptLoaded, setHasScriptLoaded] = useState(false);
	const { translator } = useTranslation();
	const translations = getTranslatedTexts(translator);

	const handlePDMeasure = (pd: number) => {
		setHasMeasuredPd(true);
		setPDModalOpen(false);
		submit(pd);
		if (isHeapAvailable) {
			window.heap.track('PD Submission', {
				'Submission Location': isAccount ? 'Account' : 'Cart',
			});
		}
	};

	if (hasMeasuredPd || !isPdNeeded) {
		return (
			<Flex gap={3} className={styles['success']} fullWidth>
				<EyeIcon color={green4} />
				<LabelText>{translations.successfulMeasurePd}</LabelText>
			</Flex>
		);
	}

	if (isAccount && isPdNeeded && !isMeasuredPd) {
		return (
			<Flex style={{ padding: 0 }} justify='between' fullWidth column>
				<Caption small style={{ marginBottom: '1.6rem' }}>
					{translations.measureYourPd}
				</Caption>
				<Button
					disabled={!pdToolInit && !pdToolHasError}
					color='blue'
					onClick={() => setPDModalOpen(true)}
					data-cart-pd-measure
				>
					{translations.measureMyPd}
				</Button>
				<PdToolModal
					open={pDModalOpen}
					setOpen={setPDModalOpen}
					setPdToolInit={setPdToolInit}
					setPdToolHasError={setPdToolHasError}
					hasScriptLoaded={hasScriptLoaded}
					onScriptLoaded={() => setHasScriptLoaded(true)}
					pdValueHandler={handlePDMeasure}
					strategy='onReady'
				/>
			</Flex>
		);
	}

	const animation = {
		...accordianHeightExpansion,
		animate: {
			...accordianHeightExpansion.animate,
			height: '100%',
		},
	};

	return isPDMeasureContainer ? (
		<m.div className={isAccount ? '' : styles['pd-container']} {...animation}>
			<Flex gap={3} justify='start' align='start' fullWidth>
				<Box width={20} height={20} />
				<Flex column fullWidth>
					<LabelText style={{ color: 'black' }}>{translations.expediteOrderProcessing}</LabelText>
					<Caption small color='black'>
						{translations.checkPdInPrescription}
					</Caption>
					<Button
						disabled={!pdToolInit && !pdToolHasError}
						color='blue'
						onClick={() => setPDModalOpen(true)}
						data-cart-pd-measure
						style={{
							margin: '1.6rem 0 0.8rem',
						}}
					>
						{translations.measureMyPd}
					</Button>
					<PdToolModal
						open={pDModalOpen}
						setOpen={setPDModalOpen}
						setPdToolInit={setPdToolInit}
						setPdToolHasError={setPdToolHasError}
						hasScriptLoaded={hasScriptLoaded}
						onScriptLoaded={() => setHasScriptLoaded(true)}
						pdValueHandler={handlePDMeasure}
						strategy='onReady'
					/>
				</Flex>
				{isAccount ? (
					<Button size='xsmall' linkStyle onClick={() => setIsPDMeasureContainer(false)}>
						{translations.closeCapitalized}
					</Button>
				) : (
					<Close
						wrapperClass={styles['close-icon']}
						label={translations.close}
						onClick={() => setIsPDMeasureContainer(false)}
					/>
				)}
			</Flex>
		</m.div>
	) : (
		<Flex style={{ padding: '1.2rem' }} justify='between' fullWidth>
			<Flex gap={3} justify='start' align='center'>
				<Box width={19} height={20} />
				<LabelText style={{ color: 'black' }}>{translations.expediteOrderProcessing}</LabelText>
			</Flex>
			<Button size='small' linkStyle onClick={() => setIsPDMeasureContainer(true)} data-cart-pd-learn-how>
				{translations.learnHow}
			</Button>
		</Flex>
	);
};

export default PhotoPd;
