type StyledWordEmphasizerProps = {
	text: string;
	emphasizedWords: string | string[];
	styledTexts: React.ReactNode | React.ReactNode[];
	conditionalText?: string;
};

export const StyledWordEmphasizer: React.FC<StyledWordEmphasizerProps> = ({
	text,
	emphasizedWords,
	styledTexts,
	conditionalText = '',
}) => {
	const emphasizedWordsArray = Array.isArray(emphasizedWords) ? emphasizedWords : [emphasizedWords];
	const styledTextsArray = Array.isArray(styledTexts) ? styledTexts : [styledTexts];

	const wordToStyledText = new Map(emphasizedWordsArray.map((word, i) => [word, styledTextsArray[i]]));
	const escapedWords = emphasizedWordsArray.map(word => word.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&'));
	const regexPattern = new RegExp(`(${escapedWords.join('|')})`, 'g');
	const splitText = text.split(regexPattern).filter(Boolean);

	return (
		<>
			{splitText.map((segment, index) =>
				wordToStyledText.has(segment) ? (
					<span key={index}>{wordToStyledText.get(segment)}</span>
				) : (
					<span key={index}>{segment}</span>
				)
			)}
			{conditionalText && conditionalText}
		</>
	);
};
