import React from 'react';
import { Button, Flex, Title } from '@components';
import { useTranslation } from '@utils/index';
import styles from './EmptyCart.module.scss';

const getTranslatedTexts = translator => {
	return {
		emptyCart: translator('empty-cart'),
		exploreEyewear: translator('Explore Eyewear'),
		exploreSunglasses: translator('Explore Sunglasses'),
		exploreTopFrames: translator('Explore Top Frames'),
	};
};

const EmptyCart = () => {
	const { translator } = useTranslation();
	const translations = getTranslatedTexts(translator);

	return (
		<Flex column className={styles.container} maxWidth>
			<Title style={{ margin: '1.6rem auto 2.4rem' }}>{translations.emptyCart}</Title>

			<Flex column gap={4}>
				<Button color='white' label={translations.exploreEyewear} href={'/eyeglasses/all'} />
				<Button color='white' label={translations.exploreSunglasses} href={'/sunglasses/all'} />
				<Button color='white' label={translations.exploreTopFrames} href={'/top-frames'} />
			</Flex>
		</Flex>
	);
};

export default EmptyCart;
