import dynamic from 'next/dynamic';
import cn from 'classnames';
import { Flex, StyledWordEmphasizer } from '@components';
import { LOCALE_DICT } from '@constants';
import styles from './FreeShippingTracker.module.scss';
import { useTranslation } from '@utils/index';

const Detail = dynamic(() => import('@components').then(mod => mod.Detail), { ssr: false });
const Paragraph = dynamic(() => import('@components').then(mod => mod.Paragraph), { ssr: false });
const TypographyButton = dynamic(() => import('@components').then(mod => mod.TypographyButton), { ssr: false });

type FreeShippingTrackerProps = {
	freeShippingThreshold: number;
	cartTotal: number;
	showMarginPadding?: boolean;
	isMicrocartStyle?: boolean;
	locale?: string;
};

const getTranslatedTexts = translator => {
	return {
		freeShipping: translator('free-shipping-highlighted-text'),
		freeShippingReward: (highlightedText: string) => translator('free-shipping-reward', { highlightedText }),
		freeShippingCountdown: (currency: string, remaining: number, highlightedText: string) =>
			translator('free-shipping-countdown', { currency, remaining, highlightedText }),
	};
};

const FreeShippingTracker = ({
	freeShippingThreshold,
	cartTotal,
	showMarginPadding = true,
	isMicrocartStyle = false,
	locale,
}: FreeShippingTrackerProps) => {
	const remaining = freeShippingThreshold - cartTotal;
	const isThresholdReached = remaining <= 0;
	const currency = LOCALE_DICT[locale].currency;
	const { translator } = useTranslation();
	const translations = getTranslatedTexts(translator);

	const description = isThresholdReached ? (
		<StyledWordEmphasizer
			text={translations.freeShippingReward(translations.freeShipping)}
			emphasizedWords={translations.freeShipping}
			styledTexts={<strong key='free-shipping'>{translations.freeShipping}</strong>}
		/>
	) : (
		<StyledWordEmphasizer
			text={translations.freeShippingCountdown(currency, remaining, translations.freeShipping)}
			emphasizedWords={[`${currency}${remaining}`, translations.freeShipping]}
			styledTexts={[
				<span key='currency-remaining'>
					{currency}
					{remaining}
				</span>,
				<strong key='free-shipping'>{translations.freeShipping}</strong>,
			]}
		/>
	);

	const barStyle = {
		width: isThresholdReached ? '100%' : `${(cartTotal / freeShippingThreshold) * 100}%`,
	};

	return (
		<div
			className={cn(styles['container'], { [styles['is-microcart']]: isMicrocartStyle })}
			data-show-border={showMarginPadding}
		>
			{isMicrocartStyle ? (
				<Detail className={styles['detail']}>{description}</Detail>
			) : (
				<Paragraph className={styles['description']}>{description}</Paragraph>
			)}
			<Flex className={styles['tracker']} align='center' justify='center'>
				{isMicrocartStyle ? <Detail>{currency}0</Detail> : <TypographyButton small>{currency}0</TypographyButton>}
				<div className={styles['progress']}>
					<div className={styles['progress-dot']} />
					<span className={styles['progress-bar']} style={barStyle} />
				</div>
				{isMicrocartStyle ? (
					<Detail>
						{currency}
						{freeShippingThreshold} {LOCALE_DICT[locale].currencyCode}
					</Detail>
				) : (
					<TypographyButton small>
						{currency}
						{freeShippingThreshold} {LOCALE_DICT[locale].currencyCode}
					</TypographyButton>
				)}
			</Flex>
		</div>
	);
};

export default FreeShippingTracker;
