import { useRouter } from 'next/router';
import { CartIcon, Flex, Heading, Title, TypographyButton } from '@components';
import { formatCurrency } from '@utils/shopify';
import { LOCALE_CODES, LOCALE_DICT } from '@constants';
import variables from '@styles/export.module.scss';
import { useFeatureInLocale, useTranslation } from '@utils/hooks';
import styles from './SubtotalHeader.module.scss';

type SubtotalHeaderProps = {
	subtotal: number;
	itemCount: number;
	buyBox?: boolean;
	disableSubtotalBold?: boolean;
	disableItemsCount?: boolean;
};

const getTranslatedTexts = translator => {
	return {
		itemsCounted: (itemCount: number) => translator('items-counted', { itemCount }),
		itemCounted: (itemCount: number) => translator('item-counted', { itemCount }),
		myCartWithColon: translator('my-cart-with-colon'),
	};
};

const SubtotalHeader = ({ subtotal, itemCount, buyBox, disableSubtotalBold, disableItemsCount }: SubtotalHeaderProps) => {
	const { locale } = useRouter();
	const { translator } = useTranslation();
	const translations = getTranslatedTexts(translator);
	const isCartUsability = useFeatureInLocale('is-cart-usability', LOCALE_CODES.US);
	const currencyCode = LOCALE_DICT[locale].currencyCode;
	const showCurr = locale === LOCALE_CODES.AU || locale === LOCALE_CODES.CA;
	const total = formatCurrency(
		{
			amount: subtotal ?? 0,
			currencyCode,
			minDigits: 2,
			maxDigits: 2,
			locale,
		},
		showCurr
	);
	const dataTag = { 'data-test-subtotal': subtotal };
	const title = translations.myCartWithColon;
	const BuyBoxSubTotal = () => (
		<Flex gap={3} align='center'>
			<Heading className={styles.buyBox} tag='h5' {...dataTag}>
				{title.slice(0, -1)}
			</Heading>
			{!disableSubtotalBold ? (
				<Heading className={styles.buyBox} tag='h5'>
					{total}
				</Heading>
			) : (
				<TypographyButton style={{ color: '#4B4849', lineHeight: 1 }}>{total}</TypographyButton>
			)}
		</Flex>
	);
	const DefaultSubtotal = () => (
		<>
			<Title {...dataTag}>{title} </Title>
			<TypographyButton>{total}</TypographyButton>
		</>
	);

	return (
		<>
			<Flex gap={3} align='center'>
				<CartIcon label='Cart' {...(buyBox && { color: variables.gray4 })} />
				<Flex gap={2} align='baseline'>
					{buyBox ? <BuyBoxSubTotal /> : <DefaultSubtotal />}
				</Flex>
			</Flex>
			{!disableItemsCount && (
				<TypographyButton
					small
					style={{
						color: isCartUsability ? variables.gray4 : '',
					}}
				>
					{`(${itemCount === 1 ? translations.itemCounted(itemCount) : translations.itemsCounted(itemCount)})`}
				</TypographyButton>
			)}
		</>
	);
};

export default SubtotalHeader;
