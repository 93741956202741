import { useQuery } from '@tanstack/react-query';
import { productImageQuery } from '@services/shopify/queries';
import { fetchStorefrontApi } from '@services/shopify';

/* eslint-disable @typescript-eslint/no-explicit-any */

export type UseProductQueryOptions = {
	/**
	 * @optional
	 * Allows you to set a query key for the React Query cache that differs from the default query key (handle).
	 * This can be useful when the slug is dynamic and you want to fetch the same cache data in multiple places.
	 */
	queryKey?: string;
	/**
	 * @optional
	 * Will refresh the query when the value changes.
	 */
	queryRefreshVars?: string[];

	/**
	 * @optional
	 * Passes the enabled attribute to the React Query client.
	 */
	enabled?: boolean;
};

const useProductImageQuery = (
	handle: string,
	frame = 'Kirby',
	{ queryKey = undefined, queryRefreshVars = [], enabled = true }: UseProductQueryOptions = {}
) => {
	return useQuery([queryKey ?? handle, ...queryRefreshVars], async () => await getProductImage(handle, frame), {
		retry: 3,
		staleTime: Infinity,
		cacheTime: Infinity,
		notifyOnChangeProps: ['data', 'error', 'status'],
		refetchOnWindowFocus: false,
		enabled,
	});
};

/**
 * General fetching utility
 *
 * If you require adding options for the Shopify GraphQL API, you can pass in an object as the second argument:
 * @example useProductQuery('product-handle', { frame: 'Larkin' })
 *
 * @param {string} handle
 * @return {object} Normalized product info from Shopify's GraphQL Storefront API
 * */
export async function getProductImage(handle: string, frame: string): Promise<string> {
	const { product } = await fetchStorefrontApi(productImageQuery, { variables: { handle, frame } });
	const url = product?.variantBySelectedOptions?.image?.url;
	if (!url) {
		return null;
	}
	return url;
}

export default useProductImageQuery;
