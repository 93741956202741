import { useEffect } from 'react';

export default function useOnClickOutside(ref, handler, escapeTrigger = false, buttonRef = null) {
	useEffect(() => {
		const listener = event => {
			if (
				!ref.current ||
				ref.current.contains(event.target) ||
				(buttonRef?.current && buttonRef.current.contains(event.target))
			) {
				return;
			}

			handler(event);
		};

		const handleKeyDown = e => {
			if (e.key !== 'Escape' && e.key !== 'Esc') return;
			handler(e);
		};

		document.addEventListener('mousedown', listener);
		document.addEventListener('touchstart', listener);
		escapeTrigger && document.addEventListener('keydown', handleKeyDown);

		return () => {
			document.removeEventListener('mousedown', listener);
			document.removeEventListener('touchstart', listener);
			escapeTrigger && document.removeEventListener('keydown', handleKeyDown);
		};
	}, [ref, handler, escapeTrigger]);
}
