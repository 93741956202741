import { useMemo } from 'react';
import { useRouter } from 'next/router';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { useCart, useCustomer } from '@services/shopify';
import { LOCALE_CODES, PRODUCT_TYPES } from '..';

export default function useIsVipMembership(type?: string) {
	const { locale } = useRouter();
	const { data: customer } = useCustomer();
	const { data: cart } = useCart();
	const isMembershipActivated = useFeatureIsOn('is-membership');
	const membershipIdCartLine = useMemo(
		() =>
			cart?.lines.find(line => {
				return line?.variant?.handle === 'pair-membership';
			})?.id,
		[cart]
	);
	const isVip = customer?.isMembershipVip;
	const hasVipMembershipInCart = !!membershipIdCartLine;
	const applyDiscountMembership =
		(isVip || hasVipMembershipInCart) &&
		locale === LOCALE_CODES.US &&
		isMembershipActivated &&
		!type?.includes(PRODUCT_TYPES.BUNDLE) &&
		!type?.includes(PRODUCT_TYPES.GIFT_CARD);
	return { isVip, hasVipMembershipInCart, applyDiscountMembership, membershipIdCartLine, isMembershipActivated };
}
