import { useMemo } from 'react';
import { useRouter } from 'next/router';
import { Flex, Loading, Lozenge, TypographyButton } from '@components';
import { LOCALE_CODES, LOCALE_DICT, formatCurrency, useTranslation } from '@utils/index';
import { ComponentProps } from '@ts/index';
import { useHasMounted } from '@utils/hooks';
import KlarnaPlacement from '../KlarnaPlacement';
import { getBadgeTypes } from '../../../utils/constants';
import styles from './Subtotal.module.scss';

type SubtotalProps = ComponentProps<HTMLDivElement> & {
	subtotal: number | string;
	isLoading?: boolean;
	topSelectedNumber?: number;
	applyDiscountMembership?: boolean;
};

const getTranslatedTexts = translator => {
	return {
		itemsAdded: (itemsNumber: number) => translator('items-added', { itemsNumber }),
		itemAdded: (itemsNumber: number) => translator('item-added', { itemsNumber }),
	};
};

const Subtotal = ({ subtotal, isLoading = false, topSelectedNumber, applyDiscountMembership }: SubtotalProps) => {
	const { locale } = useRouter();
	const isMounted = useHasMounted();
	const currencyCode = LOCALE_DICT[locale].currencyCode;
	const formattedSubtotal = useMemo(() => {
		const showCurr = locale === LOCALE_CODES.AU || locale === LOCALE_CODES.CA;
		if (typeof subtotal === 'string') return subtotal;
		return formatCurrency(
			{
				amount: subtotal,
				minDigits: 2,
				maxDigits: 2,
				currencyCode,
				locale,
			},
			showCurr
		);
	}, [subtotal, currencyCode, locale]);
	const { translator } = useTranslation();
	const translations = getTranslatedTexts(translator);
	const BADGE_TYPES = getBadgeTypes(locale);

	if (!isMounted) return;

	return (
		<div className={styles['subtotal-container']}>
			<Flex align='baseline' gap={3}>
				{!topSelectedNumber ? (
					<TypographyButton
						data-test-subtotal={subtotal}
						className={styles['subtotal']}
						style={{ marginLeft: '0.5ch' }}
						small
					>
						{!isLoading && formattedSubtotal}
						{isLoading && <Loading removeDefaultStyling className={styles.loading} small />}
					</TypographyButton>
				) : (
					<TypographyButton className={styles['subtotal']} style={{ marginLeft: '0.5ch' }} small>
						{!isLoading &&
							`${
								topSelectedNumber > 1
									? translations.itemsAdded(topSelectedNumber)
									: translations.itemAdded(topSelectedNumber)
							}`}
						{isLoading && <Loading removeDefaultStyling className={styles.loading} small />}
					</TypographyButton>
				)}
				{applyDiscountMembership && (
					<Lozenge {...BADGE_TYPES.VIP_PRICE} shape='square'>
						{BADGE_TYPES.VIP_PRICE.displayText}
					</Lozenge>
				)}
			</Flex>
			<KlarnaPlacement subtotal={typeof subtotal === 'number' && subtotal} />
		</div>
	);
};

export default Subtotal;
