import Link from 'next/link';
import cn from 'classnames';
import styles from './ConditionalLink.module.scss';

type ConditionalLinkProps = {
	children: React.ReactNode;
	href: string;
	condition: boolean;
	title?: string;
	className?: string;
	style?: React.CSSProperties;
	reduceOpacityOnHover?: boolean;
} & React.AnchorHTMLAttributes<HTMLAnchorElement>;
const isValidDivProp = key =>
	key.startsWith('data-') ||
	key.startsWith('aria-') ||
	['className', 'style', 'id', 'role'].includes(key) ||
	key.startsWith('on');

const filterDivProps = props => Object.fromEntries(Object.entries(props).filter(([key]) => isValidDivProp(key)));
const ConditionalLink = ({
	children,
	className,
	style,
	href,
	condition,
	reduceOpacityOnHover = false,
	...props
}: ConditionalLinkProps) => {
	const classes = cn(className, {
		[styles.reduceOpacityOnHover]: reduceOpacityOnHover,
	});

	return !!condition && href ? (
		<Link href={href} className={classes} style={style} {...props}>
			{children}
		</Link>
	) : (
		<div className={className} style={style} data-condition-link={condition} {...filterDivProps(props)}>
			{children}
		</div>
	);
};

export default ConditionalLink;
