type PairMembershipProps = {
	className?: string;
	width?: number;
	height?: number;
};

const PairMembership = ({ className, width = 73, height = 28 }: PairMembershipProps) => {
	return (
		<div className={className}>
			<svg width={width} height={height} viewBox='0 0 73 28' fill='none' xmlns='http://www.w3.org/2000/svg'>
				<mask id='mask0_3_5962' maskUnits='userSpaceOnUse' x='0' y='0' width='55' height='28'>
					<path d='M54.1167 0H0.116699V28H54.1167V0Z' fill='white' />
				</mask>
				<g mask='url(#mask0_3_5962)'>
					<path
						d='M9.3205 7.17725C7.4426 7.17725 5.89115 7.9788 5.10168 9.31609H4.8302V7.17725C3.34037 7.64018 1.64318 7.71625 0.0517578 7.72034V28.1771H5.10168V19.8483H5.374C6.19095 20.784 7.66079 21.3451 9.23889 21.3451C12.9955 21.3451 15.717 18.3777 15.717 14.2612C15.717 10.1446 12.9955 7.17725 9.3205 7.17725ZM7.38847 20.2761C5.75541 20.2761 5.10168 18.6992 5.10168 16.2683V12.6573C5.10168 10.0105 5.78289 8.38039 7.4426 8.38039C9.5112 8.38039 10.274 10.7859 10.274 14.2612C10.274 17.7365 9.48456 20.2761 7.38847 20.2761Z'
						fill='black'
					/>
					<path
						d='M39.881 7.17725C38.3262 7.66063 36.4383 7.72361 34.7095 7.72197V21.0785H39.881V7.17725Z'
						fill='black'
					/>
					<path
						d='M47.8363 12.7914C47.8363 12.1232 48.2444 11.7216 48.9248 11.7216H53.5517L54.0963 7.17725L51.9186 7.31056C49.8775 7.4447 48.326 9.02164 47.838 11.4541H47.5657V7.17725C46.075 7.64673 44.3712 7.7187 42.6665 7.72116V21.0785H47.8413L47.8363 12.7914Z'
						fill='black'
					/>
					<path
						d='M24.9284 7.17725C20.3557 7.17725 18.0697 8.70102 17.7974 12.1763L17.7283 13.085L23.0505 12.0594V11.4549C23.0505 8.70183 23.541 7.71298 24.9284 7.71298C26.0718 7.71298 26.8613 8.59469 26.8613 10.9208V13.5938L22.8057 14.3176C18.7251 15.0398 17.0596 16.3763 17.0596 18.489C17.0596 20.227 18.8009 21.3517 21.5507 21.3517C24.2447 21.3517 26.1234 20.3898 26.858 18.6787H27.1303V21.085H31.922V11.9555C31.9254 8.5816 29.2913 7.17725 24.9284 7.17725ZM26.8613 14.9834C26.8613 18.2174 25.9361 20.0087 24.2481 20.0087C23.1863 20.0087 22.5059 19.1269 22.5059 17.7635C22.5059 16.2912 23.7859 14.6358 26.8613 14.1279V14.9834Z'
						fill='black'
					/>
					<path
						d='M39.859 0.194092C39.859 0.194092 38.0136 0.44928 34.6226 0.814887V5.28151C37.7663 5.32976 39.859 4.66071 39.859 4.66071V0.194092Z'
						fill='black'
					/>
				</g>
				<path
					d='M67.8308 14.009H63.9644V17.9711H61.9354V14.009H58.069V12.1715H61.9354V8.20937H63.9644V12.1715H67.8308V14.009Z'
					fill='black'
				/>
				<path
					d='M55.8686 9.61399C56.6326 7.9786 57.9272 6.64964 59.542 5.84308C61.1568 5.03652 62.9969 4.79977 64.7633 5.17129C66.5297 5.54282 68.1185 6.50079 69.2716 7.88953C70.4247 9.27826 71.0742 11.0161 71.1147 12.8207C71.1551 14.6253 70.5841 16.3905 69.4944 17.8295C68.4047 19.2686 66.8604 20.2968 65.1125 20.7471C63.3645 21.1974 61.5156 21.0433 59.8663 20.3099C58.2169 19.5765 56.8641 18.3069 56.0276 16.7074'
					stroke='black'
					stroke-width='2'
				/>
			</svg>
		</div>
	);
};

export default PairMembership;
