import { productFieldsFragment, productMetafieldsFragment } from '../fragments';
import { collectionMetafieldIdentifiers, generateMetafieldQueries } from '../generate-metafield-queries';

const collectionMetafieldsFragment = /* GraphQL */ `
	fragment collectionMetafields on Collection {
		${generateMetafieldQueries(collectionMetafieldIdentifiers)}
	}
`;

const singleCollectionQuery = /* GraphQL */ `
	query getCollectionByHandle(
		$cursor: String
		$handle: String!
		$maxProducts: Int = 50
		$includeDescription: Boolean = true
		$includeSpecificFrameVariant: Boolean = false
		$skipCollections: Boolean = false
		$skipImages: Boolean = false
		$skipVariants: Boolean!
		$withProducts: Boolean!
		$selectedOptions: [SelectedOptionInput!]!
		$country: CountryCode = US
		$maxVariants: Int = 25
		$language: LanguageCode = EN
	) @inContext(country: $country, language: $language) {
		collection(handle: $handle) {
			id
			title
			handle
			image {
				url
				height
				width
				altText
			}
			seo {
				title
				description
			}
			products(first: $maxProducts, after: $cursor) @include(if: $withProducts) {
				edges {
					cursor
					node {
						...productFields
						...productMetafields
					}
				}
				pageInfo {
					hasNextPage
					endCursor
				}
			}

			...collectionMetafields
		}
	}

	${collectionMetafieldsFragment}
	${productFieldsFragment}
	${productMetafieldsFragment}
`;

export default singleCollectionQuery;
