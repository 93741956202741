const getProductVariantQuery = /* GraphQL */ `
	query getVariantById($id: ID!, $country: CountryCode = US, $language: LanguageCode = EN)
	@inContext(country: $country, language: $language) {
		node(id: $id) {
			... on ProductVariant {
				title
				product {
					handle
				}
			}
		}
	}
`;

export default getProductVariantQuery;
