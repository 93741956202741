import { Caption, Flex, PairCare } from '@components';
import { Bundle } from '@ts/cart';
import { NormalizedProduct } from '@ts/index';
import { useTranslation } from '@utils/hooks';
import styles from './ProtectYourLenses.module.scss';
interface ProtectYourLensesProps {
	showTitle?: boolean;
	enableDefaultStyles?: boolean;
	bundle: Bundle;
	pairCare?: NormalizedProduct;
}

function ProtectYourLenses({ showTitle = true, enableDefaultStyles = true, pairCare, bundle }: ProtectYourLensesProps) {
	const { translator } = useTranslation();
	return (
		<Flex column gap={2} fullWidth className={enableDefaultStyles && styles.container}>
			{showTitle && <Caption>{translator('protect-your-lenses')}</Caption>}
			<PairCare bundle={bundle} pairCare={pairCare} />
		</Flex>
	);
}
export default ProtectYourLenses;
