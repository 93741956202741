import lensHeight from '@public/lens-height.png';
import bridgeWidth from '@public/bridge-width.png';
import lensWidth from '@public/lens-width.png';
import templeLength from '@public/temple-length.png';
import variables from '@styles/export.module.scss';
import { Divider, Flex, Heading, Img, Paragraph, Title } from '@components';
import { Image } from '@ts/shopify-storefront-api';
import { FrameSizingData } from '@ts/product';
import styles from './FrameSizing.module.scss';
import { useTranslation } from '@utils/index';

export type FrameSizingProps = {
	frameSizings: FrameSizingData;
	primaryImage: Image;
	name: string;
};

const getTranslatedTexts = translator => {
	return {
		sizingForProduct: (productName: string) => translator('sizing-for-product', { productName }),
		frameWidth: (frameWidth: string) => translator('frame-width', { frameWidth }),
		frameNameSizing: (frameName: string) => translator('frame-name-sizing-heading', { frameName }),
		frameWidthDescription: translator('frame-width-description'),
		lensHeight: (lensHeight: string) => translator('lens-height', { lensHeight }),
		lensHeightDescription: translator('lens-height-description'),
		bridgeWidthSize: (bridgeWidth: string) => translator('bridge-width-size', { bridgeWidth }),
		bridgeWidthDescription: translator('bridge-width-description'),
		lensWidth: (lensWidth: string) => translator('lens-width', { lensWidth }),
		lensWidthDescription: translator('lens-width-description'),
		templeLength: (templeLength: string) => translator('temple-length', { templeLength }),
		templeLengthDescription: translator('temple-length-description'),
	};
};

const FrameSizing = ({ frameSizings, primaryImage, name }: FrameSizingProps) => {
	const { units, lens, frame } = frameSizings;
	const { translator } = useTranslation();
	const translations = getTranslatedTexts(translator);

	return (
		<>
			<Flex column align='center' justify='center' className={styles['main-image']}>
				<Img
					src={primaryImage.url}
					alt={translations.sizingForProduct(name)}
					width={primaryImage.width}
					height={primaryImage.height}
					style={{ maxWidth: '100%', height: 'auto' }}
				/>
				<svg width='640' height='12' viewBox='0 0 640 12' fill='none'>
					<line x1='3' y1='1' x2='3' y2='11' stroke={variables.blue1} strokeWidth='3' />
					<line
						x1='14.5'
						y1='6.5'
						x2='625.5'
						y2='6.5'
						stroke={variables.blue1}
						strokeWidth='3'
						strokeDasharray='12 12'
					/>
					<line x1='637' y1='11' x2='637' y2='1' stroke={variables.blue1} strokeWidth='3' />
				</svg>

				<Paragraph style={{ paddingTop: '1rem' }}>{translations.frameWidth(frame.width + units)}</Paragraph>
			</Flex>
			<Flex column pad={4}>
				<Heading tag='h5' style={{ textAlign: 'left' }}>
					{translations.frameNameSizing(name)}
				</Heading>
				<Paragraph>{translations.frameWidthDescription}</Paragraph>

				<div className={styles['glasses-row']}>
					<Img
						src={lensHeight.src}
						noSrcset
						alt={translations.sizingForProduct(name)}
						width={250}
						height={125}
						style={{ width: '100%', height: 'auto' }}
					/>
					<Flex column align='start' justify='center'>
						<Title>{translations.lensHeight(lens.height + units)}</Title>
						<Paragraph>{translations.lensHeightDescription}</Paragraph>
					</Flex>
				</div>
				<Divider alignment='horizontal' color={variables.gray2} style={{ margin: '0 auto' }} />
				<div className={styles['glasses-row']}>
					<Img
						src={bridgeWidth.src}
						noSrcset
						alt={translations.sizingForProduct(name)}
						width={250}
						height={125}
						style={{ width: '100%', height: 'auto' }}
					/>
					<Flex column align='start' justify='center'>
						<Title>{translations.bridgeWidthSize(frame.bridge + units)}</Title>
						<Paragraph>{translations.bridgeWidthDescription}</Paragraph>
					</Flex>
				</div>
				<Divider alignment='horizontal' color={variables.gray2} style={{ margin: '0 auto' }} />
				<div className={styles['glasses-row']}>
					<Img
						src={lensWidth.src}
						noSrcset
						alt={translations.sizingForProduct(name)}
						width={250}
						height={125}
						style={{ width: '100%', height: 'auto' }}
					/>
					<Flex column align='start' justify='center'>
						<Title>{translations.lensWidth(lens.width + units)}</Title>
						<Paragraph>{translations.lensWidthDescription}</Paragraph>
					</Flex>
				</div>
				<Divider alignment='horizontal' color={variables.gray2} style={{ margin: '0 auto' }} />
				<div className={styles['glasses-row']}>
					<Img
						src={templeLength.src}
						noSrcset
						alt={translations.sizingForProduct(name)}
						width={250}
						height={125}
						style={{ width: '100%', height: 'auto' }}
					/>
					<Flex column align='start' justify='center'>
						<Title>{translations.templeLength(frame.temple + units)}</Title>
						<Paragraph>{translations.templeLengthDescription}</Paragraph>
					</Flex>
				</div>
			</Flex>
		</>
	);
};

export default FrameSizing;
