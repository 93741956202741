import React, { useCallback, useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
import { useRouter } from 'next/router';
import cn from 'classnames';
import { Paragraph, QuestionMarkCircle, StyledWordEmphasizer } from '@components';
import { DISCOUNT_CODE_COOKIE, UTM_MEDIUM, UTM_MEDIUM_INFLUENCER, UTM_MEDIUM_AUDIO, ALL_ANNOUNCEMENTS } from '@constants';
import { useCart } from '@services/shopify';
import { useIsWKWebView } from '@utils/check-for-wkwebview';
import { useCartContext } from '@context';
import styles from './AnnouncementBar.module.scss';
import { useTranslation } from '@utils/index';

const getTranslatedTexts = translator => {
	return {
		discountCodeApplied: (discountCodeName: string) => translator('discount-code-applied', { discountCodeName }),
	};
};

const AnnouncementBar = ({ slides, iso }) => {
	const [influencerDiscountCode, setInfluencerDiscountCode] = useState(null);
	const [discountApplied, setDiscountApplied] = useState(false);
	const router = useRouter();
	const { data: cart, isLoading: isCartLoading } = useCart();
	const isWKWebView = useIsWKWebView();
	const { handleCartDiscountUpdate } = useCartContext();
	const { translator } = useTranslation();
	const translations = getTranslatedTexts(translator);

	const isURLParamPresent = useCallback(
		(param: string, value: string) => {
			const queryString = router.query;
			const targetParam = queryString[param] as string;

			return targetParam?.toLowerCase() === value;
		},
		[router.query]
	);

	const getCodeFromCookie = (key: string) => {
		const regexp = new RegExp(`${key}=(?<code_group>[^;]*)`, 'gm');
		const code = regexp.exec(document.cookie);

		return code?.groups?.code_group;
	};

	useEffect(() => {
		if (isURLParamPresent(UTM_MEDIUM, UTM_MEDIUM_INFLUENCER) || isURLParamPresent(UTM_MEDIUM, UTM_MEDIUM_AUDIO)) {
			setInfluencerDiscountCode(getCodeFromCookie(DISCOUNT_CODE_COOKIE) || null);
		}
	}, [isURLParamPresent]);

	useEffect(() => {
		if (influencerDiscountCode) {
			setTimeout(() => {
				setInfluencerDiscountCode(null);
			}, 10000);
		}
	}, [influencerDiscountCode]);

	useEffect(() => {
		if (router.isReady && !!cart && !discountApplied && !isCartLoading) {
			const { discount } = router.query;
			if (discount) {
				handleCartDiscountUpdate(cart.id, [String(discount)]);
				setInfluencerDiscountCode(discount);
				setDiscountApplied(true);
			}
		}
	}, [router.isReady, cart]);

	const renderSlideContent = (slide: {
		message_country: string;
		message_emoji: string;
		message_text: string;
		message_link: string;
		message_link_target: boolean;
		message_link_icon: boolean;
	}) => {
		return (
			<>
				{slide.message_emoji && <span className={styles.emoji}>{slide.message_emoji}</span>}
				<span className={styles.text} data-testid='announcement-bar'>
					{slide.message_text}
				</span>
				{slide.message_link && slide.message_link_icon && <QuestionMarkCircle label='question-mark-circle' />}
			</>
		);
	};

	const renderInfluencerDiscountSlide = () => {
		return (
			<Paragraph>
				<StyledWordEmphasizer
					text={translations.discountCodeApplied(influencerDiscountCode.toUpperCase())}
					emphasizedWords={influencerDiscountCode.toUpperCase()}
					styledTexts={<span className={styles.influencerDiscountCode}>{influencerDiscountCode.toUpperCase()}</span>}
				/>
			</Paragraph>
		);
	};

	return (
		<Swiper
			className={cn(styles['announcementBar'], { [styles['rainbow-animation']]: influencerDiscountCode })}
			direction='horizontal'
			spaceBetween={0}
			slidesPerView={1}
			autoplay={influencerDiscountCode || isWKWebView ? false : { delay: 5000 }}
			speed={800}
			modules={[Autoplay]}
			loop
			key={influencerDiscountCode} // This forces Swiper to use updated params on prop change
		>
			{influencerDiscountCode ? (
				<SwiperSlide>{renderInfluencerDiscountSlide()}</SwiperSlide>
			) : (
				<>
					{slides.map((slide, index) => {
						if (slide.message_country === iso || slide.message_country === ALL_ANNOUNCEMENTS) {
							return (
								<SwiperSlide key={`announcement-${index}`}>
									<Paragraph>
										{slide.message_link ? (
											<a href={slide.message_link} target={slide.message_link_target ? '_new' : '_self'}>
												{renderSlideContent(slide)}
											</a>
										) : (
											renderSlideContent(slide)
										)}
									</Paragraph>
								</SwiperSlide>
							);
						}

						return null;
					})}
				</>
			)}
		</Swiper>
	);
};

export default AnnouncementBar;
