import { CleanedCollection, NormalizedBuildFlowCollection, NormalizedCollection } from '@ts/product';
import { cleanGraphqlResponse, fetchStorefrontApi, GetProductOptions, paginateGraphql } from '@services/shopify';
import { multipleCollectionsQuery, singleCollectionQuery } from '@services/shopify/queries';
import { normalizeSingleCollection } from '@utils/normalizers/normalize-collection';
import { DEFAULT_BASE_FRAME_SHAPE } from '@constants';
import buildFlowCollectionsQuery from '../queries/build-flow-collections-query';

export type GetShopifyCollectionOptions = {
	maxProducts?: number;
	withProducts?: boolean;
} & GetProductOptions;

// this is hacky, but works because checking for the presence of a colon `:` is enough to determine that the arg _isn't_ a handle
export type StringWithColon = `${string}:${string}`;

// can we put this somewhere else?

/** getProductOption defaults */
const defaults = {
	withProducts: true,
	maxProducts: 75,
	includeDescription: false,
	includeSpecificFrameVariant: false,
	skipCollections: true,
	skipImages: true,
	skipVariants: false,
	selectedOptions: [{ name: 'Frame', value: DEFAULT_BASE_FRAME_SHAPE }],
};

const inferIdentifier = (unknownArg: StringWithColon | StringWithColon[] | string | string[]) => {
	const result = {
		handles: null as string[],
		handle: null as string,
		searchQuery: null as StringWithColon,
		searchQueries: null as StringWithColon[],
	};

	if (Array.isArray(unknownArg)) {
		if (unknownArg.some(arg => arg.includes(':'))) {
			result['searchQueries'] = unknownArg as StringWithColon[];
		} else {
			result['handles'] = unknownArg;
		}
	} else if (!unknownArg.includes(':')) {
		result['handle'] = unknownArg;
	} else {
		result['searchQuery'] = unknownArg as StringWithColon;
	}
	return result;
};

/**
 * Get multiple collections - general purpose collection fetching
 *
 *
 * If fetching by handle, you can pass an array:
 *
 * ```
 * getMultipleCollections(['all-base-frames', 'classic-colors'], options)
 * ```
 *
 * Or you can pass a single collection handle:
 *
 * ```
 * getMultipleCollections('all-base-frames')
 * ```
 *
 * Otherwise, pass a string that matches the [Storefront API Search Syntax](https://shopify.dev/docs/api/usage/search-syntax):
 *
 * ```
 * getMultipleCollections('(id:123456789) OR (id:987654321)')
 * ```
 * Note that as of July 2023, Shopify doesn't let you search collections by handle, only by `id`, `collection_type`, `title`, and `updated_at`
 *
 */

// Stacking the function declaration like this creates a Function Overload, which means we get correct return type inference depending on the args passed.
export async function getMultipleCollections(
	searchQuery: StringWithColon,
	options?: GetShopifyCollectionOptions
): Promise<CleanedCollection[]>;
export async function getMultipleCollections(
	handle: string,
	options?: GetShopifyCollectionOptions
): Promise<NormalizedCollection>;
export async function getMultipleCollections(
	handles: string[],
	options?: GetShopifyCollectionOptions
): Promise<NormalizedCollection[]>;
export async function getMultipleCollections(
	searchQueries: StringWithColon[],
	options?: GetShopifyCollectionOptions
): Promise<NormalizedCollection[]>;
export async function getMultipleCollections(
	collectionIdentifier: StringWithColon | StringWithColon[] | string | string[],
	options = {} as GetShopifyCollectionOptions
) {
	// Since this function can be called with multiple different identifiers, we need to be able to handle each identifier appropriately

	// One of these will equal the value of `collectionIdentifier`, the other Two will be `null`
	const { handles, handle, searchQuery, searchQueries } = inferIdentifier(collectionIdentifier);

	const variables = {
		...defaults,
		...options,
	};

	// Handle handles
	if (handles) {
		const promises = handles.map(handle =>
			paginateGraphql({
				fetcher: fetchStorefrontApi,
				resourceName: 'collection',
				nestedResourceName: 'products',
				query: singleCollectionQuery,
				variables: {
					...variables,
					handle,
				},
			})
		);

		const settledPromises = await Promise.allSettled(promises);
		return settledPromises.reduce(
			(result, current, i) => {
				if (current.status === 'fulfilled') {
					const dataToClean = current.value;
					const { collection } = cleanGraphqlResponse<{ collection: CleanedCollection }>(dataToClean);
					result.push(normalizeSingleCollection(collection));
					return result;
				} else {
					console.error(`Error fetching collection with handle: ${handles[i]}`);
					return result;
				}
			},
			[] as Array<NormalizedCollection | NormalizedBuildFlowCollection>
		);
	}

	// Handle (one) handle
	if (handle && variables.withProducts) {
		try {
			const dataToClean = await paginateGraphql({
				fetcher: fetchStorefrontApi,
				resourceName: 'collection',
				nestedResourceName: 'products',
				query: singleCollectionQuery,
				variables: {
					...variables,
					handle,
				},
			});
			const { collection } = cleanGraphqlResponse<{ collection: CleanedCollection }>(dataToClean);
			return normalizeSingleCollection(collection);
		} catch (error) {
			console.error(`Error fetching collection with handle: ${handle}`);
			return null;
		}
	} else if (handle) {
		try {
			const dataToClean = await fetchStorefrontApi(singleCollectionQuery, {
				variables: {
					handle,
					...variables,
				},
			});
			const { collection } = cleanGraphqlResponse<{ collection: CleanedCollection }>(dataToClean);
			return normalizeSingleCollection(collection);
		} catch (error) {
			console.error(`Error fetching collection with handle: ${handle}`);
			return null;
		}
	}

	if (searchQueries) {
		const promises = searchQueries.map(searchQuery =>
			paginateGraphql({
				fetcher: fetchStorefrontApi,
				resourceName: 'collections',
				nestedResourceName: 'products',
				query: multipleCollectionsQuery,
				variables: {
					searchQuery,
					...variables,
				},
			})
		);

		const settledPromises = await Promise.allSettled(promises);
		return settledPromises.reduce((result, current, i) => {
			if (current.status === 'fulfilled') {
				const dataToClean = current.value;

				const { collections } = cleanGraphqlResponse<{ collections: CleanedCollection[] }>(dataToClean);
				result.push(...collections.map(collection => normalizeSingleCollection(collection)));
				return result;
			} else {
				console.error(`Error fetching collection with search query: ${searchQueries[i]}`);
				return result;
			}
		}, [] as NormalizedCollection[]);
	}

	// Handle searchQuery
	const dataToClean = await paginateGraphql({
		fetcher: fetchStorefrontApi,
		resourceName: 'collections',
		nestedResourceName: 'products',
		query: variables.includeSpecificFrameVariant ? buildFlowCollectionsQuery : multipleCollectionsQuery,
		variables: {
			searchQuery,
			...variables,
		},
	});

	const { collections } = cleanGraphqlResponse<{ collections: CleanedCollection[] }>(dataToClean);

	return collections;
}
