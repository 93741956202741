import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { LOCALE_CODES } from '@constants';

type LocaleData = {
	country: string;
	countryRegion: string;
};

const useLocale = (): UseQueryResult<LocaleData> => {
	return useQuery<LocaleData>(['locale'], async () => {
		const localeResponse = await fetch('/api/locale', {
			method: 'GET',
			headers: { 'accept': 'application/json', 'content-type': 'application/json' },
		});
		const localeData = await localeResponse.json();

		if (!LOCALE_CODES[localeData?.country]) {
			localeData.country = LOCALE_CODES.US;
		}

		console.warn('l10n', { localeData, error: 'invalid detected locale, using fallback' });
		return localeData;
	});
};

export default useLocale;
